import React, { Component } from 'react';

export class PriceHeader extends Component {
     constructor(props){
         super(props);
     }
     priceValueFont(str){
      str=str.replace("$","<sup>$</sup>")
      str=str.replace("%","<sup>%</sup>")
      let st= str;
      let res = str.split(".");
      if(res.length!==1){
       res[1]=res[1].replace(res[1],"<sup>"+res[1]+"</sup>");
       st= res[0]+res[1];
      }
      return st;
     }
     getCouponMarkup(markup) {
      return {__html: markup};
    }
    render(){
      let purlPriceTemplate=(
        <tr style={{fontWeight: 'bold'}} valign="middle">
            <td style={{paddingRight: '5px', textAlign: 'right'}} align="right">
              <font face="Arial" color={this.props.template==="HTML6"?"#000":"#0067B1"} dangerouslySetInnerHTML={this.getCouponMarkup(this.props.priceHeader)}></font>
            </td>
            <td style={{fontFamily: 'Arial, sans-serif',fontSize:'28px' , fontWeight: 'bold'}} align="left">
              <font face="Arial" color="#000" style={{fontSize:'28px'}} dangerouslySetInnerHTML={this.getCouponMarkup(this.props.priceOverrideValue!==""? this.priceValueFont(this.props.priceOverrideValue):"<sup>$</sup>XX")}></font>
              <font face="Arial" color="#000" style={{fontSize:'15px'}}>{this.props.postPrice}</font>
            </td>
          </tr>
      );
      let html3PriceTemplate=(
         <tr style={{fontWeight: 'bold'}} valign="middle">
            <td style={{paddingRight: '5px', textAlign: 'right'}} align="right">
              <font face="Arial" color="#0067B1" dangerouslySetInnerHTML={this.getCouponMarkup(this.props.priceHeader)}></font>
            </td>
            <td style={{fontFamily: 'Arial, sans-serif',fontSize:'28px' , fontWeight: 'bold'}} align="left">
              <font face="Arial" color="#0067B1" style={{fontSize:'28px'}}>{this.props.priceOverrideValue!==""? this.props.priceOverrideValue:"$XX"} </font>
              <font face="Arial" color="#0067B1" style={{fontSize:'15px'}}>{this.props.postPrice}</font>
            </td>
          </tr>
      );
      return ( (this.props.template==="HTML6")? purlPriceTemplate:html3PriceTemplate);
    }
}

export default PriceHeader;