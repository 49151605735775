import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ErrorModal = props=> {
    const [show, setShow] = useState(false);
    const {msgarr}=props;


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

let errmessages= <ol>
{msgarr.map(item=>{
    return <li>{item}</li>;
})}</ol>;

    return (
      <div>
        <Button variant="danger" onClick={handleShow} style={{height:"35px",width:"auto"}}>
         {msgarr.length}
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Errors</Modal.Title>
          </Modal.Header>
          <Modal.Body className="error-scroll-style">{errmessages}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} style={{height:"35px"}}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

export default ErrorModal;