import XLSX from 'xlsx';

const venderExportXLSX = async (data) => {
    //Convert the data to XLSX format
    let excelData= await prepareExcelData(data)
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MCL_Vender_Coupons");
    // generate XLSX file and send to client
    XLSX.writeFile(wb, "MCL_Vender_Coupons.xlsx")
return ws
};

const addPriceDetails = (element,row) => {
    let priceDetails ={
        "Price Header 1" : element.priceHeader1,
        "Price 1 Post Price" : element.postPrice1,
        "Price Header 2" : element.priceHeader2,
        "Price 2 Post Price" : element.postPrice2,
        "Price Header 3" : element.priceHeader3,
        "Price 3 Post Price" : element.postPrice3,
        "Price Header 4" : element.priceHeader4,
        "Price 4 Post Price" : element.postPrice4,
    }
    return Object.assign(row,priceDetails);
};

//Convert the data to XLSX format
const prepareExcelData = async (data)=>{
    let excelData = []
    data.forEach((element,index) => {
           let row = {
               "S.No." : index+1,
               "Coupon Id" : element.id,
               "Coupon Header": element.header,
               "Active": element.active !== undefined && element.active ? "Y":"N",
               "Environmental Indicator":element.environmental!==null?element.environmental.id===0?" ":element.environmental.indicator:" ",
           }
           row = addPriceDetails(element,row);
           row = Object.assign(row,{
            "Includes" : element.includes})
           excelData.push(row)
       });
    return excelData;
};

export default venderExportXLSX;
