import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import TemplateCouponsForPDF from "./../previewCoupon/templateCouponsForPDF";
import rasterizeHTML from "rasterizehtml";
import React from "react";

let pdf;
const exportPDF = async (coupondata) => {
  let date = new Date();
  let month = new Date().getMonth() + 1;
  pdf = new jsPDF("p", "pt", "a4");
  var nationalofferspage = true;
  var dealerofferspage = true;
  pdf.internal.scaleFactor = 1.7;
  pdf.setFontSize(8);
  pdf.text(
    520,
    8,
    "Date: " + month + "/" + date.getDate() + "/" + date.getFullYear()
  );
  pdf.setFontSize(12);
  pdf.text(220, 20, "Total Number of Coupons :" + coupondata.length);

  var nationalOffersCoupon = couponsFilter(coupondata, "true");
  var DealerOffersCoupon = couponsFilter(coupondata, "false");

  if (nationalOffersCoupon.length !== 0) {
    pdf.text(220, 50, "National Offers Coupons");
  }
  await offersGenerator(
    nationalOffersCoupon,
    nationalofferspage,
    95,
    20,
    50,
    "true"
  );
  if (DealerOffersCoupon.length !== 0) {
    if (nationalOffersCoupon.length !== 0) {
      pdf.addPage();
    }
    pdf.text(220, 50, "Dealer Offer Coupons");
  }
  await offersGenerator(
    DealerOffersCoupon,
    dealerofferspage,
    40,
    20,
    50,
    "false"
  );
  return pdf;
};

export const couponsFilter = (coupondata, isnationaloffers) => {
  let coupons;
  if (isnationaloffers === "true") {
    coupons = coupondata.filter((x) => {
      return x.category.name.toUpperCase() === "Featured Service".toUpperCase();
    });
  } else {
    coupons = coupondata.filter((x) => {
      return x.category.name.toUpperCase() !== "Featured Service".toUpperCase();
    });
  }
  return coupons;
};

const imageGeneation = async (tempcoupondata, isNationalOfferValue) => {
  let renderhtml = renderToString(
    <TemplateCouponsForPDF
      couponsjson={tempcoupondata}
      isNationalOffer={isNationalOfferValue}
    ></TemplateCouponsForPDF>
  );
  const renderResult = await rasterizeHTML.drawHTML(renderhtml);
  var canvas = document.createElement("canvas");
  var context = canvas.getContext("2d");
  canvas.setAttribute("width", renderResult.image.width * 1.2);
  canvas.setAttribute("height", renderResult.image.height * 1.2);
  context.scale(1.2, 1.2);
  context.drawImage(renderResult.image, 0, 0);
  let dataurl = canvas.toDataURL("image/png");
  return dataurl;
};
const offersGenerator = async (
  offersCoupon,
  offerspage,
  pdfformat_x,
  pdfformat_y_default,
  pdfformat_y_once,
  isNationalOffer
) => {
  for (
    let x = 0, y = 6;
    offersCoupon.slice(x, y).length !== 0;
    x = x + 6, y = y + 6
  ) {
    let tempcoupondata = offersCoupon.slice(x, y);
    const dataurl = await imageGeneation(tempcoupondata, isNationalOffer);
    if (offerspage) {
      pdf.addImage(dataurl, "JPEG", pdfformat_x, pdfformat_y_once);
      offerspage = false;
    } else {
      pdf.addImage(dataurl, "JPEG", pdfformat_x, pdfformat_y_default);
    }
    if (offersCoupon.slice(x + 6, y + 6).length !== 0) {
      pdf.addPage();
    }
  }
};
export default exportPDF;
