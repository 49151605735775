import Cookies from 'js-cookie';
import React, { Component } from 'react';


export default class NationalCouponTemplateCore extends Component {
     // eslint-disable-next-line no-useless-constructor
     constructor(props){
         super(props);
     }
     getCouponMarkup(markup) {
      return {__html: markup};
    }
 
    render() {
      return (
          <div>
        <table cellPadding={0} cellSpacing={0}>
        <tbody><tr><td bgcolor="#FFFFFF" style={{borderTop: '#000000 dashed 4px', borderLeft: '#000000 dashed 4px', borderRight: '#000000 dashed 4px',
        borderBottom: '#000000 dashed 4px', backgroundColor: '#ffffff'}}>
              {/* coupon Template HTML_3-14BC */}
              <style type="text/css" dangerouslySetInnerHTML={{__html: "  \n        .auto-style1  \n        {  \n            width: 86px;  \n        }  \n        .tbdlogo  \n        {  \n            height: 165px;  \n        }  \n    " }} />
              <table height={300} width={271} style={{width: '271px', verticalAlign: 'top', height: '300px', backgroundColor: '#FFFFFF'}} cellPadding={0}
              cellSpacing={1} align="center">
                <tbody><tr height={270}>
                    <td valign="middle" height={270}>
                      <table border={0} align="center" valign="middle" height="100%" width="100%" style={{fontFamily: 'Arial,Helvetica,sans-serif', fontSize: '8pt'}}>
                        <tbody><tr valign="top"><td align="center">
                        {(this.props.imageUrl===null||this.props.imageUrl===''||this.props.imageUrl===' ')?
                        <img className="tbdlogo" id="offerimage" src={"https://"+Cookies.get("blobUrl")+"/coupon-images/"+this.props.imageUrl}
                        width="100%" alt="not uploaded yet"/>
                        :
                        <img className="tbdlogo" id="offerimage" src={"https://"+Cookies.get("blobUrl")+"/coupon-images/"+this.props.imageUrl}
                        width="100%" alt={this.props.imageAltText}/>
                        }
                        </td>
                        </tr>
                          <tr>
                            <td>
                              <table valign="top">
                                <tbody><tr>
                                    <td style={{fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '18px'}} valign="bottom" align="center">
                                      <center>
                                        <font face="Arial" color="#0067B1">
                                        </font>
                                      </center>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top" style={{fontFamily: 'Arial,Helvetica,sans-serif', fontSize: '9pt'}} align="center">
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{fontFamily: 'Arial,Helvetica,sans-serif', fontSize: '11pt'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}} valign="middle">
                                            <td style={{paddingRight: '5px', textAlign: 'right'}} align="right">
                                              <font face="Arial" color="#0067B1">
                                              </font>
                                            </td>
                                            <td style={{fontFamily: 'Arial, sans-serif', fontSize: '28px', fontWeight: 'bold'}} align="left">
                                              <font face="Arial" color="#0067B1">
                                                <font size={-1}>
                                                </font>
                                              </font>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{color: '#000000', fontFamily: 'Arial, sans-serif', fontSize: '9px'}} valign="top" align="center">
                                      <font face="Arial" dangerouslySetInnerHTML={this.getCouponMarkup(this.props.includes)}>
                                      </font>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                  <tr height={30} style={{height: '10%'}}>
                    <td>
                      <table width="100%" height={30}>
                        <tbody><tr>
                            <td style={{fontFamily: 'Arial, sans-serif', fontSize: '9px', fontWeight: 'bold', textAlign: 'left'}}>
                              <font face="Arial">
                                <b>
                                {"OFFER EXPIRES "+ this.props.expires}
                                </b>
                              </font><br />
                              <sup className="includes">{"dealerCode-"+this.props.id+"OL0-h3-t2bc--us-en"}</sup>
                            </td>
                            <td align="right" className="auto-style1">
                              <img width={100} src={"https://"+Cookies.get("blobUrl")+"/coupon-images/mopar-icon.jpg"} alt="Mopar Logo"/>
                            </td>
                          </tr>
                        </tbody></table>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
        </tbody></table>
          </div>
        );
    }
}

