import React ,{Component} from 'react';
import Modal from 'react-modal';
import Spinner from '../common/spinner';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      border:'none',
      width: 'auto',
      height:'auto',
      'backgroundColor':'#DADFE6'
    }
  };
  export default class ExportPdfModal extends Component {
    constructor() {
      super();
    }

    componentDidMount(){
        if (process.env.NODE_ENV !== 'test'){
            Modal.setAppElement('#root');
        }
    }
    render() {
      return (
        <div>
          <Modal
            isOpen={this.props.modalIsOpen}
             style={customStyles}
            contentLabel="Export Coupons as PDF"
          >
            <div style={{textAlign:'center'}}>
            <p style={{fontSize:'20px'}}><strong>Generating PDF</strong></p>
            <Spinner value=""></Spinner>
            <p style={{fontSize:'20px'}}><strong>Do not close the window or browser till downloading is finished.</strong></p>
            <p style={{fontSize:'15px'}}><span> Download will be available in few seconds</span></p>

            </div>
          </Modal>
        </div>
      );
    }
  }