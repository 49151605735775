import React, { Component } from 'react';
import { connect } from 'react-redux';
import NationalCouponTemplateCore from './nationalCouponTemplateCore';

export class NationalCouponTemplate extends Component {
     constructor(props){
         super(props);
     }
    render()
    {
      const {isAnyRowSelected,header,includes,imageUrl,id,expires,imageAltText}= this.props;
      if(isAnyRowSelected===true)
      {
      return (
        <NationalCouponTemplateCore header={header}
              includes={includes}
              imageUrl={imageUrl}
              imageAltText={imageAltText}
              id={id}
              expires={expires}
        ></NationalCouponTemplateCore>
        );}
        else
        {
          return(<div></div>);
        }
    }
}

const mapStateToProps = (states) => {
  const state= {...states.reducer, ...states.filterReducer, ...states.resetFilterReducer};
  return {
    isAnyRowSelected:state.isAnyRowSelected
  };
};

export default connect(mapStateToProps)(NationalCouponTemplate);