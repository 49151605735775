import React, { Component } from "react";
import { connect } from "react-redux";
import exportPDF, { couponsFilter } from "./../export/pdfExporter";
import CouponsPreviewContainer from "./couponsPreviewContainer";
import Spinner from "./../common/spinner";
import "./couponsPreviewComponent.css";
import { FaSearch } from "react-icons/fa";
import ExportPdfModal from "../export/exportPdfModal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TableColumnFilter from "../common/tableColumnFilter";
import DateFilter from "../common/dateFilter";
export class CouponPreviewTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      textValue: "",
      searchResult: false,
      errorMessage: false,
      searchEntries: [],
      isExportPdfModalopen: false,
      resetExportValue: "Export as",
      isExportDisabled: true,
      isFilterContainerDisplayed: false,
      defaultFiltersData: [],
      filterArray: [],
    };
    this.handleFind = this.handleFind.bind(this);
    this.handleExportChange = this.handleExportChange.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.entries.length === 0) {
      this.setState({
        entries: nextprops.entries,
        searchEntries: nextprops.entries,
        isExportDisabled: true,
      });
    } else {
      let filterArray = [];
      filterArray.push({
        name: "category",
        dataEntries: nextprops.entries,
        checkboxData: this.setCheckBoxFilterData(
          this.getUniqueFilterValue(nextprops.entries, "category")
        ),
      });
      filterArray.push({
        name: "active",
        dataEntries: nextprops.entries,
        checkboxData: this.setCheckBoxFilterData(
          this.getUniqueFilterValue(nextprops.entries, "active")
        ),
      });
      filterArray.push({
        name: "publish",
        dataEntries: nextprops.entries,
        checkboxData: this.setCheckBoxFilterData(
          this.getUniqueFilterValue(nextprops.entries, "publish")
        ),
      });
      filterArray.push({
        name: "program",
        dataEntries: nextprops.entries,
        checkboxData: this.setCheckBoxFilterData(
          this.getUniqueFilterValue(nextprops.entries, "program")
        ),
      });
      filterArray.push({
        name: "environmental",
        dataEntries: nextprops.entries,
        checkboxData: this.setCheckBoxFilterData(
          this.getUniqueFilterValue(nextprops.entries, "environmental")
        ),
      });
      filterArray.push({
        name: "date",
        dataEntries: nextprops.entries,
        startRange: this.getStartRange(nextprops.entries),
        endRange: this.getEndRange(nextprops.entries),
        data: {
          startDate: null,
          endDate: null,
        },
      });

      this.setState({
        entries: nextprops.entries,
        searchEntries: nextprops.entries,
        isExportDisabled: false,
        defaultFiltersData: filterArray,
      });
    }
    if(nextprops.dashboardFilter.type!="" && (nextprops.dashboardFilter.type =="Programs"||nextprops.dashboardFilter.type =="Environmental")){
      let dashboardFiltertype= nextprops.dashboardFilter.type;
      let dashboardFiltervalue= nextprops.dashboardFilter.value;
      this.handledashboardFilter(dashboardFiltertype,dashboardFiltervalue);
      // this.props.applyDashboardFilter({type:"",value:""});
    }
  }
  handledashboardFilter(type, value){

    if(type==="Programs"){
      let elementsIndex = this.state.defaultFiltersData.findIndex(
        (element) => element.name === "program"
      );
      let newArray = [...this.state.defaultFiltersData];
      newArray[elementsIndex].checkboxData[value] = true;
      this.handleSelectedCheckboxesForPrograms(newArray[elementsIndex].checkboxData);
    }
    if(type==="Environmental"){
      let elementsIndex = this.state.defaultFiltersData.findIndex(
        (element) => element.name === "environmental"
      );
      let newArray = [...this.state.defaultFiltersData];
      newArray[elementsIndex].checkboxData[value] = true;
      this.handleSelectedCheckboxesForEnvironmental(newArray[elementsIndex].checkboxData);
    }
    // this.setState({});
  }
  getStartRange(data) {
    let minDate = 0;
    if (data.length > 0) {
      minDate = data.reduce(
        (min, p) => (Date.parse(p.expires) < min ? Date.parse(p.expires) : min),
        Date.parse(data[0].expires)
      );
    }
    return minDate;
  }
  getEndRange(data) {
    let maxDate = 0;
    if (data.length > 0) {
      maxDate = data.reduce(
        (min, p) => (Date.parse(p.expires) > min ? Date.parse(p.expires) : min),
        Date.parse(data[0].expires)
      );
    }
    return maxDate;
  }
  handleFind() {
    this.setState({ errorMessage: false, searchEntries: [] }, () => {
      this.state.searchResult = true;
      this.setState({});
      let response = this.state.entries;
      response.forEach((element) => {
        let obj = element;
        if (
          obj.id
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase()) ||
          obj.header
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase()) ||
          obj.couponName
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase())
        ) {
          this.state.searchEntries.push(obj);
        }
      });
      this.setState({});
      this.resetFilterData(
        [],
        this.state.defaultFiltersData,
        this.state.searchEntries
      );
      if (this.state.searchEntries.length === 0) {
        this.setState({ errorMessage: true, isExportDisabled: true });
      } else {
        this.setState({ isExportDisabled: false });
      }
    });
  }
  async handleExportChange(event) {
    if (event.target.value === "pdf") {
      this.setState({ isExportPdfModalopen: true });

      const pdf = await exportPDF(this.state.searchEntries);
      pdf.save("MCL_Coupons");

      this.setState({ isExportPdfModalopen: false });
    }
    this.setState({
      resetExportValue: "Export as",
    });
  }
  handleSelectedCheckboxesForCategory = (arr) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "category"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], checkboxData: arr };

    let selectedcheckboxes = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes.length > 0) {
      this.handleFilterpositive(
        "category",
        selectedcheckboxes,
        newArray,
        elementsIndex
      );
    } else {
      this.handleFilternegative("category", newArray, elementsIndex);
    }
  };
  handleSelectedCheckboxesForActive = (arr) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "active"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], checkboxData: arr };

    let selectedcheckboxes = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes.length > 0) {
      this.handleFilterpositive(
        "active",
        selectedcheckboxes,
        newArray,
        elementsIndex
      );
    } else {
      this.handleFilternegative("active", newArray, elementsIndex);
    }
  };
  handleSelectedCheckboxesForPublish = (arr) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "publish"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], checkboxData: arr };

    let selectedcheckboxes = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes.length > 0) {
      this.handleFilterpositive(
        "publish",
        selectedcheckboxes,
        newArray,
        elementsIndex
      );
    } else {
      this.handleFilternegative("publish", newArray, elementsIndex);
    }
  };
  handleSelectedCheckboxesForEnvironmental = (arr) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "environmental"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], checkboxData: arr };
    let selectedcheckboxes = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes.length > 0) {
      this.handleFilterpositive(
        "environmental",
        selectedcheckboxes,
        newArray,
        elementsIndex
      );
    } else {
      this.handleFilternegative("environmental", newArray, elementsIndex);
    }
  };
  handleSelectedCheckboxesForPrograms = (arr) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "program"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], checkboxData: arr };
    let selectedcheckboxes = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes.length > 0) {
      this.handleFilterpositive(
        "program",
        selectedcheckboxes,
        newArray,
        elementsIndex
      );
    } else {
      this.handleFilternegative("program", newArray, elementsIndex);
    }
  };
  handleDateFilter = (recievedData) => {
    let elementsIndex = this.state.defaultFiltersData.findIndex(
      (element) => element.name === "date"
    );
    let newArray = [...this.state.defaultFiltersData];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      data: recievedData,
    };
    if (recievedData.startDate === null && recievedData.endDate === null) {
      this.handleFilternegative("date", newArray, elementsIndex);
    } else {
      this.handleFilterpositive("date", recievedData, newArray, elementsIndex);
    }
  };
  handleFilterpositive(type, selectedcheckboxes, newArray, elementsIndex) {
    let _filterArray = this.state.filterArray;
    let restFilterArray = _filterArray.slice(
      0,
      _filterArray.indexOf(type) === -1
        ? _filterArray.length
        : _filterArray.indexOf(type)
    );
    restFilterArray.push(type);
    if (type === "category") {
      const resultCoupons = newArray[elementsIndex].dataEntries.filter((item) =>
        selectedcheckboxes.includes(item.category.name)
      );
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
    if (type === "active") {
      let abc = selectedcheckboxes.map((value) =>
        value === "Active" ? true : false
      );
      const resultCoupons = newArray[elementsIndex].dataEntries.filter((item) =>
        abc.includes(item.active)
      );
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
    if (type === "publish") {
      let abc = selectedcheckboxes.map((value) =>
        value === "Publish" ? true : false
      );
      const resultCoupons = newArray[elementsIndex].dataEntries.filter((item) =>
        abc.includes(item.statusPublished)
      );
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
    if (type === "program") {
      let tempArr = [];
      const resultCoupons = newArray[elementsIndex].dataEntries
        .filter((item) => item.programs.length !== 0)
        .reduce((acc, i) => {
          i.programs.map((e) => {
            if (selectedcheckboxes.includes(e.program)) {
              if (!tempArr.includes(i.id)) {
                acc.push(i);
                tempArr.push(i.id);
              }
            }
          });
          return acc;
        }, []);
      let mymap = new Map();

      const unique = resultCoupons.filter((el) => {
        const val = mymap.get(el.id);
        if (val) {
          if (el.id < val) {
            mymap.delete(el.id);
            mymap.set(el.name, el.id);
            return true;
          } else {
            return false;
          }
        }
        mymap.set(el.name, el.id);
        return true;
      });
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
    if (type === "environmental") {
      const resultCoupons = newArray[elementsIndex].dataEntries.filter((item) =>
        selectedcheckboxes.includes(
          item.environmental !== null ? item.environmental.indicator : "0"
        )
      );
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
    if (type === "date") {
      let resultCoupons;
      if (
        selectedcheckboxes.startDate !== null &&
        selectedcheckboxes.endDate !== null
      ) {
        resultCoupons = newArray[elementsIndex].dataEntries.filter(
          (item) =>
            Date.parse(item.expires) >=
              Date.parse(selectedcheckboxes.startDate) &&
            Date.parse(item.expires) <= Date.parse(selectedcheckboxes.endDate)
        );
      }
      if (
        selectedcheckboxes.startDate !== null &&
        selectedcheckboxes.endDate === null
      ) {
        resultCoupons = newArray[elementsIndex].dataEntries.filter(
          (item) =>
            Date.parse(item.expires) >= Date.parse(selectedcheckboxes.startDate)
        );
      }
      if (
        selectedcheckboxes.startDate === null &&
        selectedcheckboxes.endDate !== null
      ) {
        resultCoupons = newArray[elementsIndex].dataEntries.filter(
          (item) =>
            Date.parse(item.expires) <= Date.parse(selectedcheckboxes.endDate)
        );
      }
      this.setState({
        searchEntries: resultCoupons,
        filterArray: restFilterArray,
      });
      this.resetFilterData(restFilterArray, newArray, resultCoupons);
    }
  }
  handleFilternegative(type, newArray, elementsIndex) {
    let _filterArray = this.state.filterArray;
    let restFilterArray = _filterArray.slice(
      0,
      _filterArray.indexOf(type) === -1
        ? _filterArray.length
        : _filterArray.indexOf(type)
    );
    const resultCoupons = newArray[elementsIndex].dataEntries;
    this.setState({
      searchEntries: resultCoupons,
      filterArray: restFilterArray,
    });
    this.resetFilterData(restFilterArray, newArray, resultCoupons);
  }
  resetFilterData(restFilterArray, newArray, resultCoupons) {
    newArray.map((item) => {
      if (!restFilterArray.includes(item.name)) {
        if (item.name === "date") {
          item.dataEntries = resultCoupons;
          item.startRange = this.getStartRange(resultCoupons);
          item.endRange = this.getEndRange(resultCoupons);
          item.data = {
            startDate: null,
            endDate: null,
          };
        } else {
          item.dataEntries = resultCoupons;
          item.checkboxData = this.setCheckBoxFilterData(
            this.getUniqueFilterValue(resultCoupons, item.name)
          );
        }
      }
    });
    this.setState({ defaultFiltersData: newArray });
  }
  fetchSelectedCheckbox(arr) {
    let selectedcheckboxes = new Array();
    for (let [key, value] of Object.entries(arr)) {
      if (value) {
        selectedcheckboxes.push(key);
      }
    }
    return selectedcheckboxes;
  }
  getUniqueFilterValue(data, type) {
    if (type === "category") {
      return Array.from(new Set(data.map((item) => item.category["name"])));
    }
    if (type === "active") {
      return Array.from(
        new Set(data.map((item) => (item.active ? "Active" : "Inactive")))
      );
    }
    if (type === "publish") {
      return Array.from(
        new Set(data.map((item) => (item.statusPublished ? "Publish" : "UnPublish")))
      );
    }
    if (type === "program") {
      let fil = data.filter((item) => item.programs.length !== 0);
      let mp = fil.reduce((acc, i) => {
        i.programs.map((p) => acc.push(p.program));
        return acc;
      }, []);
      return Array.from(new Set(mp));
    }
    if (type === "environmental") {
      return Array.from(
        new Set(
          data.map((item) =>
            item.environmental === null ? "0" : item.environmental["indicator"]
          )
        )
      );
    }
  }
  setCheckBoxFilterData(item) {
    let checkboxFilterData = item.reduce(
      (options, option) => ({
        ...options,
        [option]: false,
      }),
      {}
    );
    return checkboxFilterData;
  }
  getCheckBoxFilterData(data, type) {
    if (type === "category") {
      return data.filter((item) => item.name === "category")[0].checkboxData;
    }
    if (type === "active") {
      return data.filter((item) => item.name === "active")[0].checkboxData;
    }
    if (type === "publish") {
      return data.filter((item) => item.name === "publish")[0].checkboxData;
    }
    if (type === "date") {
      return data.filter((item) => item.name === "date")[0];
    }
    if (type === "program") {
      return data.filter((item) => item.name === "program")[0].checkboxData;
    }
    if (type === "environmental") {
      let ab = data.filter((item) => item.name === "environmental")[0]
        .checkboxData;
      return ab;
    }
  }
  handleResetFilters = () => {
    this.resetFilterData([], this.state.defaultFiltersData, this.state.entries);
    this.setState({
      searchEntries: this.state.entries,
      filterArray: [],
      textValue: "",
    });
  };
  render() {
    const nationalOfferEntries = couponsFilter(
      this.state.searchEntries,
      "true"
    );
    const dealerOfferEntries = couponsFilter(this.state.searchEntries, "false");
    return (
      <div className="rowss">
        {this.state.entries.length !== 0 ? (
          <div>
            <div className="row input-group-container input-field-font-coupon-style mx-0 py-1 content-space-between">
              <div>
                <p>Coupons Found: {this.state.searchEntries.length}</p>
              </div>

              <div>
                <label for="find">Find: </label>
                <input
                  id="find-input"
                  className="input-field-coupon-style input-field-bottom-border-coupon-style px-2"
                  type="text"
                  size="38"
                  maxlength="100"
                  value={this.state.textValue}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      this.handleFind(event);
                    }
                  }}
                  onChange={(event) => {
                    this.setState({ textValue: event.target.value });
                  }}
                />
                <FaSearch onClick={this.handleFind}></FaSearch>
              </div>
              <div>
                <button
                  className="reset-filter-button"
                  onClick={() => {
                    this.setState({
                      isFilterContainerDisplayed: !this.state
                        .isFilterContainerDisplayed,
                    });
                  }}
                  title="filters for preview coupons"
                >
                  Filters
                  {this.state.isFilterContainerDisplayed ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </button>
              </div>
              <div>
                <div>
                  <select
                    id="export"
                    className="input-field-coupon-style"
                    style={{ width: "auto" }}
                    onSelected={this.handleExportChange}
                    onChange={this.handleExportChange}
                    disabled={this.state.isExportDisabled}
                    value={this.state.resetExportValue}
                  >
                    <option value="Export as" selected disabled>
                      Export as
                    </option>
                    ;
                    <option
                      value="pdf"
                      title="Download all preview template coupons"
                    >
                      PDF
                    </option>
                    ; ;
                  </select>
                </div>
              </div>
            </div>
            {this.state.isFilterContainerDisplayed && (
              <div
                className="row input-group-container input-field-font-coupon-style mx-0 py-1 content-space-between"
                style={{
                  marginTop: "10px",
                  color: "black",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <span style={{ marginRight: "-10px" }}>Category</span>
                  <TableColumnFilter
                    optionData={this.getCheckBoxFilterData(
                      this.state.defaultFiltersData,
                      "category"
                    )}
                    selectedCheckBoxes={
                      this.handleSelectedCheckboxesForCategory
                    }
                    filter="category_filter_previewCoupon"
                  />
                </div>
                <div>
                  <span style={{ marginRight: "-10px" }}>Active/Inactive</span>
                  <TableColumnFilter
                    optionData={this.getCheckBoxFilterData(
                      this.state.defaultFiltersData,
                      "active"
                    )}
                    selectedCheckBoxes={this.handleSelectedCheckboxesForActive}
                    filter="active_filter_previewCoupon"
                  />
                </div>
                <div>
                  <span style={{ marginRight: "-10px" }}>Env Indicator</span>
                  <TableColumnFilter
                    optionData={this.getCheckBoxFilterData(
                      this.state.defaultFiltersData,
                      "environmental"
                    )}
                    selectedCheckBoxes={
                      this.handleSelectedCheckboxesForEnvironmental
                    }
                    filter="environmental_filter_previewCoupon"
                  />
                </div>
                <div>
                  <span style={{ marginRight: "-10px" }}>Programs</span>
                  <TableColumnFilter
                    optionData={this.getCheckBoxFilterData(
                      this.state.defaultFiltersData,
                      "program"
                    )}
                    selectedCheckBoxes={
                      this.handleSelectedCheckboxesForPrograms
                    }
                    filter="programs_filter_previewCoupon"
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span>Date</span>
                  <DateFilter
                    data={
                      this.getCheckBoxFilterData(
                        this.state.defaultFiltersData,
                        "date"
                      ).data
                    }
                    startRange={
                      this.getCheckBoxFilterData(
                        this.state.defaultFiltersData,
                        "date"
                      ).startRange
                    }
                    endRange={
                      this.getCheckBoxFilterData(
                        this.state.defaultFiltersData,
                        "date"
                      ).endRange
                    }
                    onDateFilterselected={this.handleDateFilter}
                  />
                </div>
                <div>
                  <span style={{ marginRight: "-10px" }}>Published</span>
                  <TableColumnFilter
                    optionData={this.getCheckBoxFilterData(
                      this.state.defaultFiltersData,
                      "publish"
                    )}
                    selectedCheckBoxes={this.handleSelectedCheckboxesForPublish}
                    filter="publish_filter_previewCoupon"
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <button
                    className="reset-filter-previewCoupon-button"
                    disabled={this.state.filterArray.length > 0 ? false : true}
                    onClick={this.handleResetFilters}
                  >
                    Reset Filter
                  </button>
                </div>
              </div>
            )}
            {this.state.searchEntries.length > 0 ? (
              <div>
                {nationalOfferEntries.length > 0 && (
                  <div className="national-offer-contianer">
                    <CouponsPreviewContainer
                      label="National Offer Coupons"
                      entries={nationalOfferEntries}
                    />
                  </div>
                )}
                {dealerOfferEntries.length > 0 && (
                  <div className="dealer-offer-contianer">
                    <CouponsPreviewContainer
                      label="Dealer Offer Coupons"
                      entries={dealerOfferEntries}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="alert alert-danger" role="alert">
                Unable to find coupons, please try again with a different search
                criteria
              </div>
            )}
          </div>
        ) : (
          <div
            style={{ textAlign: "center", width: "100%", fontSize: "1.5rem" }}
          >
            <Spinner value="Loading" />
          </div>
        )}
        <ExportPdfModal
          modalIsOpen={this.state.isExportPdfModalopen}
        ></ExportPdfModal>
      </div>
    );
  }
}
const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.previewCouponTabReducer,
    ...states.dashboardReducer
  };
  return {
    entries: state.previewTab_entries,
    dashboardFilter:state.dashboardFilter
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    applyDashboardFilter: (val) =>
          dispatch({
            type: "APPLY_DASHBOARD_FILTER",
            data: val,
          })
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(CouponPreviewTab);
