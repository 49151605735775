import React, {Component} from 'react';
import UpdateCouponForm from './updateCouponForm';
import PreviewCoupon from './previewCoupon/previewCoupon';
import Specialcodes from "./Specialcodes";
import "./updateCoupon.css";
export class UpdateCoupon extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    render() {
        return(
            <div>
                <div className="edit-coupon-container" >
                <div  style={{padding:"5px", margin:"10px",boxShadow:"0px 8px 16px 0px rgba(0, 0, 0, 0.5)"}} >
                <UpdateCouponForm></UpdateCouponForm>
              </div>
              <div className="right-section">
              <Specialcodes></Specialcodes>
              <PreviewCoupon></PreviewCoupon>

              </div>
            </div>
            </div>
        );
    }

}

export default UpdateCoupon;
