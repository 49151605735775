import React, { Component } from "react";
import Modal from "react-modal";
import Select from "react-select";
import Axios from "../../service";
import XLSX from "xlsx";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import "./BulkUpdate.css";
import { expireDateValidator, getUsername } from "../ScheduleTab/util";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { make_cols } from "../common/makeColumns";
import Cookies from "js-cookie";
export default class BulkUpdateModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      couponSelected: null,
      couponStatus: null,
      validatorStatus: true,
      validatorText: "",
      expireDate: "",
      couponIsDisabled: false,
      couponsList: [],
      submitDisabled: false,
      selectAllStatus: false,
      radioValue: "expiresRadio",
      couponState: "active",
      file: {},
      data: [],
      cols: [],
      fileUploadStatus: false,
      publishedCoupons: [],
      unpublishedCoupon: [],
      programs_list: [],
      environmental_indicators_list: [],
      categories_list: [],
      fileUploadMessage: "Updating data from uploaded excel........",
      successMessage: "",
      unsuccessfulMessage: "",
      displayFailedCoupons: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "test") {
      Modal.setAppElement("#root");
    }
    Axios.get("/manageCoupons/api/programs", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState({ programs_list: arr });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/environmentals", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState({ environmental_indicators_list: arr });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/getCategoryList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((CategoryResponse) => {
        let arr = [];
        CategoryResponse.data.map((res) => {
          arr.push(res);
        });
        this.setState({ categories_list: arr });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
      couponSelected: null,
      validatorText: "",
      validatorStatus: false,
      submitDisabled: false,
      expireDate: "",
      radioValue: "expiresRadio",
      couponState: "active",
      file: {},
      data: [],
      fileUploadStatus: false,
      publishedCoupons: [],
      unpublishedCoupon: [],
      fileUploadMessage: "Updating data from uploaded excel........",
      successMessage: "",
      unsuccessfulMessage: "",
      displayFailedCoupons: false,
    });
    this.props.modalIsOpenHandle(false);
  }
  selectedCouponArray(couponsObjectArray) {
    let result = couponsObjectArray.reduce((acc, item) => {
      acc.push(item.label);
      return acc;
    }, []);

    return result;
  }
  bulkUpdateActiveApiCall() {
    // axios({
    //   method: "put",
    //   url: window.env.ENV_URL + "/manageCoupons/api/bulk-action",

    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allowed-Origin": "*",
    //     username: getUsername(),
    //   },
    //   data: {
    //     couponIds: this.selectedCouponArray(this.state.couponSelected),
    //     actionType:
    //       this.state.couponState === "active"
    //         ? "ACTIVE_STATUS_UPDATE"
    //         : "INACTIVE_STATUS_UPDATE",
    //   },
    // })
    Axios.put(
      "/manageCoupons/api/bulk-action",
      {
        couponIds: this.selectedCouponArray(this.state.couponSelected),
        actionType:
          this.state.couponState === "active"
            ? "ACTIVE_STATUS_UPDATE"
            : "INACTIVE_STATUS_UPDATE",
      },
      {
        headers: {
          username: getUsername(),
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          this.props.onSuccessRefresh(true);
          this.closeModal();
        } else {
          this.setState({
            validatorText: "Unable to create, Try again later.",
            validatorStatus: true,
            submitDisabled: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          validatorText: "Unable to create, Try again later.",
          validatorStatus: true,
          submitDisabled: false,
        });
        console.log(error);
      });
  }
  expiresSubmit() {
    if (this.state.expireDate && this.state.couponSelected) {
      if (expireDateValidator(this.state.expireDate)) {
        this.setState({
          validatorStatus: false,
          validatorText: "",
        });
        this.bulkUpdateApiCall();
      } else {
        this.setState({
          validatorStatus: true,
          validatorText:
            "Please make sure expire date is today's date or beyond today's date.",
          submitDisabled: false,
        });
      }
    } else {
      this.setState({
        validatorStatus: true,
        validatorText: "Please make sure all fields are filled.",
        submitDisabled: false,
      });
    }
  }
  activeSubmit() {
    if (this.state.couponSelected) {
      this.bulkUpdateActiveApiCall();
    } else {
      this.setState({
        validatorStatus: true,
        validatorText: "Please make sure coupons are selected.",
        submitDisabled: false,
      });
    }
  }
  submit = () => {
    this.setState({ submitDisabled: true });
    if (this.state.radioValue === "expiresRadio") {
      this.expiresSubmit();
    } else {
      this.activeSubmit();
    }
  };
  bulkUpdateApiCall() {
    Axios.put(
      "/manageCoupons/api/bulk-action",
      {
        couponIds: this.selectedCouponArray(this.state.couponSelected),
        expiryDate: this.convertExpiryDateToDBFormat(this.state.expireDate),
        actionType: "EXPIRY_DATE_UPDATE",
      },
      {
        headers: {
          username: getUsername(),
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          this.props.onSuccessRefresh(true);
          this.closeModal();
        } else {
          this.setState({
            validatorText: "Unable to create, Try again later.",
            validatorStatus: true,
            submitDisabled: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          validatorText: "Unable to create, Try again later.",
          validatorStatus: true,
          submitDisabled: false,
        });
        console.log(error);
      });
  }

  handleCouponChange = (couponSelected) => {
    this.setState({
      couponSelected: couponSelected,
      validatorText: "",
      validatorStatus: false,
    });
  };

  handleRadio = (event) => {
    this.setState({
      radioValue: event.target.value,
      couponSelected: null,
      validatorText: "",
      validatorStatus: false,
      submitDisabled: false,
      expireDate: "",
      selectAllStatus: false,
      couponState: "active",
      file: {},
      data: [],
      fileUploadStatus: false,
      publishedCoupons: [],
      unpublishedCoupon: [],
      fileUploadMessage: "Updating data from uploaded excel........",
      successMessage: "",
      unsuccessfulMessage: "",
      displayFailedCoupons: false,
    });
  };

  handleFromDate = (event) => {
    this.setState({
      expireDate: event.target.value,
      validatorText: "",
      validatorStatus: false,
    });
  };
  convertExpiryDateToDBFormat(date) {
    var dateArray = date.split("-");
    return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
  }
  handleCouponStateChange = (event) => {
    this.setState({ couponState: event.target.value });
  };
  handlefileChange = (e) => {
    const files = e.target.files;
    if (files && files[0])
      this.setState({
        file: files[0],
        fileUploadMessage: "",
        fileUploadStatus: false,
        displayFailedCoupons: false,
        unsuccessfulMessage: "",
        successMessage: "",
        publishedCoupons: [],
        unpublishedCoupon: [],
      });
  };
  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this.setState(
        {
          data: data,
          cols: make_cols(ws["!ref"]),
          fileUploadStatus: true,
          fileUploadMessage: "Updating data from uploaded excel........",
          displayFailedCoupons: false,
          unsuccessfulMessage: "",
          successMessage: "",
          publishedCoupons: [],
          unpublishedCoupon: [],
        },
        this.validateExcel
      );
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  };
  validateExcel() {
    let validation_columns = [
      "S.No.",
      "Coupon Id",
      "Coupon Name",
      "Coupon Header",
      "Expiry Date",
      "Active",
      "Published",
      "Category",
      "Sort Order",
      "Country",
      "Language",
      "Environmental Indicator",
      "Programs",
      "Price Header 1",
      "Price 1 Required",
      "Price 1 Default Free",
      "Price 1 Post Price",
      "Price 1 Override",
      "Price 1",
      "Price Header 2",
      "Price 2 Required",
      "Price 2 Default Free",
      "Price 2 Post Price",
      "Price 2 Override",
      "Price 2",
      "Price Header 3",
      "Price 3 Required",
      "Price 3 Default Free",
      "Price 3 Post Price",
      "Price 3 Override",
      "Price 3",
      "Price Header 4",
      "Price 4 Required",
      "Price 4 Default Free",
      "Price 4 Post Price",
      "Price 4 Override",
      "Price 4",
      "Image",
      "Image Alt Text",
      "Includes",
    ];
    if (this.state.cols.length === 40) {
      if (
        JSON.stringify(Object.keys(this.state.data[0])) ==
        JSON.stringify(validation_columns)
      ) {
        this.executeUpdateCoupons();
      } else {
        this.setState({
          fileUploadStatus: true,
          fileUploadMessage: "Wrong file type or template.",
        });
      }
    } else {
      this.setState({
        fileUploadStatus: true,
        fileUploadMessage: "Wrong file type or template.",
      });
    }
  }
  executeUpdateCoupons() {
    const promises = [];
    this.state.data.map((d) => {
      promises.push(this.editCouponCall(d));
    });
    Promise.all(promises).then((re) => {
      if (this.state.unpublishedCoupon.length === 0) {
        this.setState({
          fileUploadMessage: "",
          successMessage: "Successfully Updated.",
        });
      } else {
        this.setState({
          fileUploadMessage: "",
          unsuccessfulMessage:
            "Failed to update the listed " +
            this.state.unpublishedCoupon.length +
            " coupons.",
          displayFailedCoupons: true,
        });
      }
      this.props.onSuccessRefresh(true);
    });
  }
  getData = (d) => {
    return JSON.stringify({
      id: d["Coupon Id"],
      couponName: d["Coupon Name"],
      header: d["Coupon Header"],
      category: this.getCategoryData(this.state.categories_list, d["Category"]),
      country: d["Country"] === "United States" ? "us" : d["Country"] === "Canada" ? "ca" : d["Country"] === "Mexico" ? "mx" : "pr",
      language: d["Language"] === "English" ? "en" : d["Language"] === "Spanish" ? "es" : "fr",
      includes: d["Includes"],
      imageUrl: d["Image"],
      imageAltText: d["Image Alt Text"],
      approved: "on",
      couponType: "standard",
      forcePrice1: d["Price 1 Override"] === "Y" ? "on" : " ",
      forcePrice2: d["Price 2 Override"] === "Y" ? "on" : " ",
      forcePrice3: d["Price 3 Override"] === "Y" ? "on" : " ",
      forcePrice4: d["Price 4 Override"] === "Y" ? "on" : " ",
      parentCoupon: " ",
      postPrice1: d["Price 1 Post Price"],
      postPrice2: d["Price 2 Post Price"],
      postPrice3: d["Price 3 Post Price"],
      postPrice4: d["Price 4 Post Price"],
      price1Free: d["Price 1 Default Free"] === "Y" ? "on" : " ",
      price2Free: d["Price 2 Default Free"] === "Y" ? "on" : " ",
      price3Free: d["Price 3 Default Free"] === "Y" ? "on" : " ",
      price4Free: d["Price 4 Default Free"] === "Y" ? "on" : " ",
      priceHeader1: d["Price Header 1"],
      priceHeader2: d["Price Header 2"],
      priceHeader3: d["Price Header 3"],
      priceHeader4: d["Price Header 4"],
      price1Required: d["Price 1 Required"] === "Y" ? "on" : " ",
      price2Required: d["Price 2 Required"] === "Y" ? "on" : " ",
      price3Required: d["Price 3 Required"] === "Y" ? "on" : " ",
      price4Required: d["Price 4 Required"] === "Y" ? "on" : " ",
      priceOverride1: d["Price 1"],
      priceOverride2: d["Price 2"],
      priceOverride3: d["Price 3"],
      priceOverride4: d["Price 4"],
      specialPriceParent: " ",
      sortOrder: d["Sort Order"],
      active: d["Active"] === "Y" ? true : false,
      isCouponPublished: d["Published"] === "Y" ? true : false,
      expires: d["Expiry Date"],
      environmental: this.getEnvironmental(
        this.state.environmental_indicators_list,
        d["Environmental Indicator"]
      ),
      programs: this.getSelectedPrograms(
        this.state.programs_list,
        d["Programs"]
      ),
    });
  };
  editCouponCall = async (d) => {
    // await Axios({
    //   method: "PUT",
    //   url: "/manageCoupons/api/" + d["Coupon Id"],
    //   data: this.getData(d),
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8",
    //     username: getUsername(),
    //   },
    // })
    await Axios.put(`/manageCoupons/api/${d["Coupon Id"]}`, this.getData(d), {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        username: getUsername(),
        Authorization: Cookies.get("token"),
      },
    })
      .then(async (res) => {
        // this.state.publishedCoupons.push(d["Coupon Id"]);
        if (d["Published"] === "Y") {
          await Axios({
            method: "PUT",
            url: "/manageCoupons/api/publishCoupon/" + d["Coupon Id"],
            data: this.getData(d),
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              username: getUsername(),
              Authorization: Cookies.get("token"),
            },
          })
            .then((ress) => {
              // this.state.publishedCoupons.push(d["Coupon Id"]);
              let arr = this.state.publishedCoupons;
              arr.push(d["Coupon Id"]);
              this.setState({ publishedCoupons: arr });
            })
            .catch((error) => {
              console.log(error);
              let arr = this.state.unpublishedCoupon;
              arr.push(d["Coupon Id"]);
              this.setState({ unpublishedCoupon: arr });
            });
        } else {
          let arr = this.state.publishedCoupons;
          arr.push(d["Coupon Id"]);
          this.setState({ publishedCoupons: arr });
        }
      })
      .catch((error) => {
        console.log(error);
        let arr = this.state.unpublishedCoupon;
        arr.push(d["Coupon Id"]);
        this.setState({ unpublishedCoupon: arr });
      });
  };
  getSelectedPrograms(programs_list, data) {
    let data_arr = data.split(",");
    return programs_list.filter((item) => data_arr.includes(item.program));
  }
  getEnvironmental(environmental_indicators_list, data) {
    let id = 0;
    let indicator = "BLANK";
    if (data !== " " && data !== "") {
      id = data;
      indicator = data.toString();
      environmental_indicators_list.map((item) => {
        if (item.indicator == data) {
          id = item.id;
          indicator = item.indicator;
        }
      });
    } else {
      id = 0;
      indicator = "BLANK";
    }
    return {
      id: id,
      indicator: indicator,
    };
  }
  getCategoryData(categories_list, data) {
    let id;
    let name;
    let categoryOrder;
    categories_list.map((item) => {
      if (item.name === data) {
        id = item.id;
        name = item.name;
        categoryOrder = item.categoryOrder;
      }
    });

    return {
      id: id,
      name: name,
      categoryOrder: categoryOrder,
    };
  }
  render() {
    const { couponSelected, validatorStatus, validatorText } = this.state;
    return (
      <div className="bulkUpdate_modal_container">
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          // style={customStyles}
          className="bulkUpdate_modal"
          // overlayClassName="bulkUpdate_modal_overlay"
          contentLabel="Bulk Update Modal"
        >
          <div className="bulkUpdate__headerSection">
            <h3>Bulk Update</h3>
          </div>
          <form className="bulkUpdate__form" onSubmit={this.submit}>
            <div className="bulkUpdate__row">
              <div className="bulkupdate_radiogroup">
                <input
                  type="radio"
                  name="bulkupdate_radio"
                  id="bulkupdate_expireRadio"
                  value="expiresRadio"
                  onChange={this.handleRadio}
                  defaultChecked
                />
                <label for="bulkupdate_expireRadio">Expires</label>
                <input
                  type="radio"
                  name="bulkupdate_radio"
                  id="bulkupdate_activeRadio"
                  value="activeRadio"
                  onChange={this.handleRadio}
                />
                <label for="bulkupdate_activeRadio">Active</label>
                <input
                  type="radio"
                  name="bulkupdate_radio"
                  id="bulkupdate_importRadio"
                  value="importRadio"
                  onChange={this.handleRadio}
                />
                <label for="bulkupdate_importRadio">Import</label>
              </div>
            </div>
            {this.state.radioValue !== "importRadio" && (
              <div className="bulkUpdate__row">
                <div className="bulkUpdate__fields_select">
                  <label>Coupons</label>
                  <Select
                    id="bulkUpdate__Coupon_selector"
                    value={couponSelected}
                    onChange={this.handleCouponChange}
                    options={this.props.couponsList}
                    required
                    isMulti
                    maxMenuHeight={130}
                    menuShouldScrollIntoView={false}
                  />
                </div>
              </div>
            )}
            {this.state.radioValue === "expiresRadio" && (
              <div className="bulkUpdate__row">
                <div className="bulkUpdate__fields__date">
                  <label>Expire Date</label>
                  <input
                    type="date"
                    name="expire_date"
                    id="bulkUpdate__expireDate"
                    min={new Date().toISOString().split("T")[0]}
                    required
                    onChange={this.handleFromDate}
                    value={this.state.expireDate}
                  />
                </div>
              </div>
            )}
            {this.state.radioValue === "activeRadio" && (
              <div className="bulkUpdate__row">
                <div className="bulkUpdate__fields__select">
                  <label>Active/Inactive</label>
                  <br />
                  <select
                    className="rectangle-copy"
                    name="active"
                    id="activeState"
                    onChange={this.handleCouponStateChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.radioValue === "importRadio" && (
              <div>
                <input
                  type="file"
                  id="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={this.handlefileChange}
                  onClick={(e) => {
                    e.target.value = null;
                    this.setState({
                      file: {},
                      fileUploadStatus: false,
                      fileUploadMessage:
                        "Updating data from uploaded excel........",
                      displayFailedCoupons: false,
                      unsuccessfulMessage: "",
                      successMessage: "",
                      publishedCoupons: [],
                      unpublishedCoupon: [],
                    });
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    typeof this.state.file.name == "string" ? false : true
                  }
                  onClick={this.handleFile}
                >
                  Upload
                </Button>
                <br />
                {this.state.fileUploadStatus && (
                  <span>{this.state.fileUploadMessage}</span>
                )}
                {this.state.unpublishedCoupon.length === 0 ? (
                  <span style={{ color: "green" }}>
                    {this.state.successMessage}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {this.state.unsuccessfulMessage}
                  </span>
                )}
                {this.state.displayFailedCoupons && (
                  <div
                    style={{
                      height: "150px",
                      overflowY: "scroll",
                      backgroundColor: "white",
                    }}
                  >
                    <List>
                      {this.state.unpublishedCoupon.map((item) => (
                        <ListItem key={item}>{item}</ListItem>
                      ))}
                    </List>
                  </div>
                )}
              </div>
            )}

            <div className="bulkUpdate__row">
              {validatorStatus && (
                <div className="bulkUpdate__validator__text">
                  <p>{validatorText}</p>
                </div>
              )}
              <div className="bulkUpdate__buttons">
                {this.state.radioValue !== "importRadio" && (
                  <IconButton
                    color="primary"
                    id="bulkUpdate_submit_button"
                    className="bulkUpdate__button"
                    onClick={this.submit}
                    disabled={this.state.submitDisabled}
                  >
                    <DoneIcon fontSize="large" />
                  </IconButton>
                )}
                <IconButton
                  color="secondary"
                  id="bulkupdate_close"
                  className="bulkUpdate__button"
                  onClick={this.closeModal}
                >
                  <ClearIcon fontSize="large" style={{ color: "#c6084b" }} />
                </IconButton>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
