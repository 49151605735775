import React, { Component } from "react";
import BulkUpdateModal from "./BulkUpdateModal";
import Axios from "../../service";
import { convertCouponArrayToCouponObjectArray } from "../ScheduleTab/util";
import "./BulkUpdate.css";
import Cookies from "js-cookie";

export default class BulkUpdateComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createModalIsOpen: false,
      couponsList: [],
    };
  }
  componentDidMount() {
    this.getcouponList();
  }
  getcouponList() {
    Axios.get("/event-service/coupon-ids", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          couponsList: convertCouponArrayToCouponObjectArray(response.data),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleModalIsOpen = (e) => {
    this.setState({
      createModalIsOpen: e,
    });
  };
  handleSuccessRefresh = (value) => {
    if (value) {
      this.props.refreshTable(true);
    }
  };
  render() {
    return (
      <div>
        <button
          className="bulkUpdate__link"
          id="bulkUpdate__id"
          onClick={() => this.setState({ createModalIsOpen: true })}
        >
          Bulk Update
        </button>
        <BulkUpdateModal
          modalIsOpen={this.state.createModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          couponsList={this.state.couponsList}
          onSuccessRefresh={this.handleSuccessRefresh}
        ></BulkUpdateModal>
      </div>
    );
  }
}
