const changelogState = {
    restoreSuccessStatus: false,
  };

  const ChangeLogReducer = (state = changelogState, action) => {
    if (action.type === "SET_RESTORE_STATUS") {
      return {
        ...state,
        restoreSuccessStatus: action.data,
      };
    }

    return state;
  };
  export default ChangeLogReducer;