import React,{Component} from 'react';
import CategoryTable from './CategoryTable';
import "./categoryTable.css";

export default class CategoryList extends Component {
render(){
  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 py-4">
                  <div id="coupons-header" className="coupon-header-style">CATEGORIES</div>
                  <hr className="horizontal-line-coupon"/>
                </div>
            </div>
            <div >
            <CategoryTable></CategoryTable>
            </div>
        </div>
    </div>
  );
  }
}


