import React, { Component } from "react";
import ActiveChart from "./ActiveChart";
import PublishedChart from "./PublishedChart";
import CategoryChart from "./CategoryChart";
import ProgramsChart from "./ProgramsChart";
import EnvironmentChart from "./EnvironmentChart";
import "./CouponDashboard.css";

export default class CouponDashboard extends Component {
  render() {
    return (
      <div className="dashboard">
        <h1>Dashboard</h1>
        <div className="dashboard__displaySection">
        <div className="dashboard__row">
          <ActiveChart/>
          <PublishedChart/>
        </div>
        <div className="dashboard__row">
          <ProgramsChart/>
          <EnvironmentChart/>
        </div>
        <div className="dashboard__row">
        <CategoryChart/>
        </div>
        </div>

      </div>
    );
  }
}
