import React, { Component } from "react";
import PriceHeader from "./priceHeader";
import PurlPriceHeader from "./purlPriceHeader";

export default class DealerCouponPURLTemplate extends Component {
  getCouponMarkup(markup) {
    return { __html: markup };
  }
  priceValueFont(str) {
    str = str.replace("$", "<sup>$</sup>");
    str = str.replace("%", "<sup>%</sup>");
    let st = str;
    let res = str.split(".");
    if (res.length !== 1) {
      res[1] = res[1].replace(res[1], "<sup>" + res[1] + "</sup>");
      st = res[0] + res[1];
    }
    return st;
  }
  render() {
    let html3PriceHeader = (
      <table id="table1" style={{ emptyCells: "show", tableLayout: "fixed" }}>
        <tr>
          <PriceHeader
            priceHeader={this.props.priceHeader1}
            postPrice={this.props.postPrice1}
            priceOverrideValue={this.props.priceOverride1}
            template="HTML6"
            templateFor={this.props.templateFor}
          />
          {this.props.priceHeader2 && (
            <PriceHeader
              priceHeader={this.props.priceHeader2}
              postPrice={this.props.postPrice2}
              priceOverrideValue={this.props.priceOverride2}
              template="HTML6"
              templateFor={this.props.templateFor}
            />
          )}
          {this.props.priceHeader3 && (
            <PriceHeader
              priceHeader={this.props.priceHeader3}
              postPrice={this.props.postPrice3}
              priceOverrideValue={this.props.priceOverride3}
              template="HTML6"
              templateFor={this.props.templateFor}
            />
          )}
          {this.props.priceHeader4 && (
            <PriceHeader
              priceHeader={this.props.priceHeader4}
              postPrice={this.props.postPrice4}
              priceOverrideValue={this.props.priceOverride4}
              template="HTML6"
              templateFor={this.props.templateFor}
            />
          )}
        </tr>
      </table>
    );
    let PdFPriceHeader = (
      <PurlPriceHeader
        priceHeader1={this.props.priceHeader1}
        priceHeader2={this.props.priceHeader2}
        priceHeader3={this.props.priceHeader3}
        priceHeader4={this.props.priceHeader4}
        postPrice1={this.props.postPrice1}
        postPrice2={this.props.postPrice2}
        postPrice3={this.props.postPrice3}
        postPrice4={this.props.postPrice4}
        priceOverride1={this.props.priceOverride1}
        priceOverride2={this.props.priceOverride2}
        priceOverride3={this.props.priceOverride3}
        priceOverride4={this.props.priceOverride4}
      />
    );
    return (
      <div>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <table cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr>
              <td bgcolor="#FFFFFF" style={{ border: "dotted black" }}>
                {/* coupon Template HTML_3-2 */}
                <table
                  height={300}
                  width={270}
                  style={{
                    width: this.props.templateFor === "PDF" ? "440px" : "270px",
                    verticalAlign: "top",
                    height: "300px",
                    backgroundColor: "#FFFFFF",
                  }}
                  cellPadding={0}
                  cellSpacing={1}
                  align="center"
                >
                  <tbody>
                    <tr height={270}>
                      <td valign="middle" height={270}>
                        <table align="center" width="100%" valign="middle">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontFamily: "Arial, sans-serif",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                }}
                                valign="top"
                                align="center"
                              >
                                <center>
                                  <font
                                    face="Arial"
                                    dangerouslySetInnerHTML={this.getCouponMarkup(
                                      this.props.header
                                    )}
                                  />
                                </center>
                              </td>
                            </tr>
                            {this.props.priceHeader1 ? (
                              <tr>
                                <td
                                  valign="top"
                                  style={{
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    fontSize: "11pt",
                                  }}
                                  align="center"
                                >
                                  {this.props.templateFor === "PDF"
                                    ? PdFPriceHeader
                                    : html3PriceHeader}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                    fontSize: "9pt",
                                  }}
                                  align="center"
                                >
                                  <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    border={0}
                                    style={{
                                      fontFamily: "Arial,Helvetica,sans-serif",
                                      fontSize: "9pt",
                                      width: "100%",
                                    }}
                                  >
                                    <tbody>
                                      <tr
                                        style={{ fontWeight: "bold" }}
                                        valign="middle"
                                      >
                                        <td
                                          style={{
                                            fontFamily: "Arial, sans-serif",
                                            fontSize: "44px",
                                            fontWeight: "bold",
                                          }}
                                          align="center"
                                          width="100%"
                                        >
                                          <font
                                            face="Arial"
                                            dangerouslySetInnerHTML={this.getCouponMarkup(
                                              this.props.priceOverride1 !==
                                                "" &&
                                                this.props.priceOverride1 !==
                                                  undefined
                                                ? this.priceValueFont(
                                                    this.props.priceOverride1
                                                  )
                                                : "<sup>$</sup>XX"
                                            )}
                                          ></font>
                                          <font
                                            face="Arial"
                                            style={{ fontSize: "28px" }}
                                          >
                                            {" "}
                                            {this.props.postPrice1}
                                          </font>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td
                                style={{
                                  color: "#000000",
                                  fontFamily: "Arial, sans-serif",
                                  fontSize: "9px",
                                }}
                              >
                                <div>
                                  <font
                                    face="Arial"
                                    dangerouslySetInnerHTML={this.getCouponMarkup(
                                      this.props.includes
                                    )}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  color: "#000000",
                                  fontFamily: "Arial, sans-serif",
                                  fontSize: "9px",
                                }}
                              >
                                <left>
                                  <font face="Arial">
                                    Offer Expires <b>{this.props.expires} </b>
                                  </font>
                                </left>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  color: "#000000",
                                  fontFamily: "Arial, sans-serif",
                                  fontSize: "9px",
                                }}
                              >
                                <left>
                                  <font face="Arial">
                                    <b>VIN:{"{vin code}"}</b>
                                    <br />
                                    xxxxx-xxxxxx-xx-xxxx-xxxx-us-en
                                  </font>
                                </left>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
