import Cookies from "js-cookie";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Axios from "../../service";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import "./logoutStyle.css";

class Logout extends Component {
  logout() {
    Axios.get("/manageCoupons/api/logout", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        alert("Successfully logged out");
      })
      .catch((e) => {
        console.log(e);
      });
    Cookies.remove("token");
    localStorage.removeItem("username_mcl");
    Cookies.remove("blobUrl");
    Cookies.remove("blobToken");
  }
  render() {
    return (
      <div className="sign-out-container">
        <Link
          to="/manageCoupons/login"
          onClick={this.logout}
          className="sign-out"
        >
          <OverlayTrigger
            key="logout"
            placement="left"
            overlay={
              <Tooltip id={`tooltip-"right"`}>
                <div>Sign Out</div>
              </Tooltip>
            }
          >
            <PowerSettingsNewIcon />
          
          </OverlayTrigger>
          {/* Sign Out */}
        </Link>
      </div>
    );
  }
}

export default Logout;
