import React, { Component } from "react";
import DateFilter from "../common/dateFilter";
import { connect } from "react-redux";

export class DateFilterController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        startDate: null,
        endDate: null,
      },
      startRange: null,
      endRange: null,
      dateFilterStatus: false,
    };
    this.handleDateFilter = this.handleDateFilter.bind(this);
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.resetDateFilter && nextprops.dateFilterTableData.length>0) {
      let minDate = nextprops.dateFilterTableData.reduce(
        (min, p) => (Date.parse(p.expires) < min ? Date.parse(p.expires) : min),
        Date.parse(nextprops.dateFilterTableData[0].expires)
      );
      let maxDate = nextprops.dateFilterTableData.reduce(
        (min, p) => (Date.parse(p.expires) > min ? Date.parse(p.expires) : min),
        Date.parse(nextprops.dateFilterTableData[0].expires)
      );
      this.setState({
        startRange: minDate,
        endRange: maxDate,
        data: { startDate: null, endDate: null },
        dateFilterStatus: false,
      });
      this.props.passResetDateFilter(false);
      this.props.passDateFilterStatus(false);
    }
  }

  handleDateFilter(recievedData) {
    if (recievedData.startDate !== null || recievedData.endDate !== null) {
      this.props.passDateData(recievedData);
      this.props.passDateFilterStatus(true);
      this.setState({ data: recievedData, dateFilterStatus: true });
    } else {
      this.props.passDateData(recievedData);
      this.props.passDateFilterStatus(false);
      this.setState({ data: recievedData, dateFilterStatus: false });
    }
    this.props.setDateFilterHandlerCall(true);
  }

  render() {
    return (
      <DateFilter
        data={this.state.data}
        startRange={this.state.startRange}
        endRange={this.state.endRange}
        onDateFilterselected={this.handleDateFilter}
      />
    );
  }
}

const mapStateToProps = (states) => {
  const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
  return {
    tableRows: state.rows,
    dateFilterTableData: state.dateFilterTableData,
    resetDateFilter: state.resetDateFilter,
    dateFilterStatus: state.dateFilterStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passData: (array) =>
      dispatch({
        type: "SET_COUPON_DATA",
        data: array,
      }),
    passDateData: (array) =>
      dispatch({
        type: "SET_DATE_FILTER_DATA",
        data: array,
      }),
    passDateFilterStatus: (array) =>
      dispatch({
        type: "SET_DATE_FILTER_STATUS",
        data: array,
      }),
    setDateFilterHandlerCall: (value) =>
      dispatch({
        type: "SET_DATE_FILTER_HANDLERCALL",
        data: value,
      }),
    passResetDateFilter: (data) =>
      dispatch({
        type: "RESET_DATE_FILTER",
        data: data,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateFilterController);
