const filterState = {
  isactivefilterApplied: false,
  ispublishfilterApplied: false,
  iscategoryfilterApplied: false,
  activeFilterCheckboxData: {},
  publishFilterCheckboxData: {},
  categoryFilterCheckboxData: {},
  filterArray: [],
  dateFilterData: {},
  dateFilterStatus: false,
  dateFilterHandler: false,
  countryFilterData:{},
  countryFilterStatus: false,
  countryFilterHandler: false,
  languageFilterData:{},
  languageFilterStatus: false,
  languageFilterHandler: false
};

const filterReducer = (state = filterState, action) => {
  if (action.type === "SET_ACTIVE_FILTER_STATUS") {
    return {
      ...state,
      isactivefilterApplied: action.data,
    };
  }
  if (action.type === "SET_PUBLISH_FILTER_STATUS") {
    return {
      ...state,
      ispublishfilterApplied: action.data,
    };
  }
  if (action.type === "SET_CATEGORY_FILTER_STATUS") {
    return {
      ...state,
      iscategoryfilterApplied: action.data,
    };
  }
  if (action.type === "SET_ACTIVE_FILTER_CHECKBOX_DATA") {
    return {
      ...state,
      activeFilterCheckboxData: action.data,
    };
  }
  if (action.type === "SET_PUBLISH_FILTER_CHECKBOX_DATA") {
    return {
      ...state,
      publishFilterCheckboxData: action.data,
    };
  }
  if (action.type === "SET_CATEGORY_FILTER_CHECKBOX_DATA") {
    return {
      ...state,
      categoryFilterCheckboxData: action.data,
    };
  }
  if (action.type === "SET_FILTER_ARRAY") {
    return {
      ...state,
      filterArray: action.data,
    };
  }
  if (action.type === "SET_DATE_FILTER_DATA") {
    return {
      ...state,
      dateFilterData: action.data,
    };
  }
  if (action.type === "SET_DATE_FILTER_STATUS") {
    return {
      ...state,
      dateFilterStatus: action.data,
    };
  }
  if (action.type === "SET_DATE_FILTER_HANDLERCALL") {
    return {
      ...state,
      dateFilterHandler: action.data,
    };
  }
  if (action.type === "SET_COUNTRY_FILTER_DATA") {
    return {
      ...state,
      countryFilterData: action.data,
    };
  }
  if (action.type === "SET_COUNTRY_FILTER_STATUS") {
    return {
      ...state,
      countryFilterStatus: action.data,
    };
  }
  if (action.type === "SET_COUNTRY_FILTER_HANDLERCALL") {
    return {
      ...state,
      countryFilterHandler: action.data,
    };
  }

  if (action.type === "SET_LANGUAGE_FILTER_DATA") {
    return {
      ...state,
      languageFilterData: action.data,
    };
  }
  if (action.type === "SET_LANGUAGE_FILTER_STATUS") {
    return {
      ...state,
      languageFilterStatus: action.data,
    };
  }
  if (action.type === "SET_LANGUAGE_FILTER_HANDLERCALL") {
    return {
      ...state,
      languageFilterHandler: action.data,
    };
  }

  return state;
};
export default filterReducer;
