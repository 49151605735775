import React, { Component } from "react";
import "./previewCouponStyle.css";
import TemplateCoupon from "./templateCoupon";
import { connect } from "react-redux";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Axios from "../../service";
import { convertCouponArrayToCouponObjectArray } from "../ScheduleTab/util";
import Cookies from "js-cookie";

export class PreviewCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: "HTML3",
      templates: ["HTML3", "HTML6"],
      selectedRow: [],
      isAnyRowSelected: false,
      couponSelected: null,
      couponsList: [],
      showComparison: false,
      comparison_couponId: "",
    };
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      selectedRow: nextprops.selectedRow,
      isAnyRowSelected: nextprops.isAnyRowSelected,
    });
    if (!nextprops.isCouponComparisonEnabled) {
      this.setState({
        showComparison: false,
        couponSelected: null,
        comparison_couponId: "",
      });
    }
  }
  componentDidMount() {
    if (this.props.isAnyRowSelected) {
      this.setState({
        selectedRow: this.props.selectedRow,
        isAnyRowSelected: this.props.isAnyRowSelected,
      });
    }
    this.getCouponList();
  }
  getCouponList() {
    Axios.get("/event-service/coupon-ids", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          couponsList: convertCouponArrayToCouponObjectArray(response.data),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleTemplateChange(event) {
    this.setState({ selectedTemplate: event.target.value });
    this.props.passTemplateType(event.target.value);
  }

  handleCouponChange = (couponSelected) => {
    this.setState({ couponSelected: couponSelected });
  };
  handleCompareButton = () => {
    if (this.state.couponSelected) {
      this.setState({
        showComparison: true,
        comparison_couponId: this.state.couponSelected.value,
      });
    } else {
      this.setState({ showComparison: false, comparison_couponId: "" });
    }
  };
  render() {
    return (
      <div className="preview-coupon-container">
        <div className="preview-coupon-header">
          <h1>Preview Template</h1>
          <select
            id="template-select"
            value={this.state.selectedTemplate}
            onChange={this.handleTemplateChange}
          >
            {this.state.templates.map((template) => {
              return <option value={template}>{template}</option>;
            })}
          </select>
        </div>
        <div className="previewContainer">
          <div
            className="previewSection"
            style={{
              marginTop: this.props.isCouponComparisonEnabled ? "40px" : "0px",
            }}
          >
            {this.state.isAnyRowSelected === true ? (
              <div
                id="id-coupon"
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  marginLeft: "0",
                }}
              >
                {this.state.selectedRow["id"]}
              </div>
            ) : (
              <div
                id="id-coupon"
                style={{ fontWeight: "bold", display: "none" }}
              >
                {this.state.selectedRow["id"]}
              </div>
            )}
            <TemplateCoupon></TemplateCoupon>
          </div>
          {this.props.isCouponComparisonEnabled && (
            <div className="previewSection">
              <div className="selectCouponSection">
                <Select
                  id="bulkUpdate__Coupon_selector"
                  value={this.state.couponSelected}
                  onChange={this.handleCouponChange}
                  options={this.state.couponsList}
                  required
                  maxMenuHeight={130}
                  menuShouldScrollIntoView={false}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.handleCompareButton}
                >
                  Compare
                </Button>
              </div>
              {this.state.showComparison && (
                <>
                  <div
                    id="id-coupon"
                    style={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {this.state.comparison_couponId}
                  </div>
                  <TemplateCoupon
                    comparisonCouponId={this.state.comparison_couponId}
                  ></TemplateCoupon>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    selectedRow: state.selectedRow,
    isAnyRowSelected: state.isAnyRowSelected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passTemplateType: (val) =>
      dispatch({
        type: "SET_COUPON_PREVIEW_TYPE",
        data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCoupon);
