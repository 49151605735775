import React, { Component } from "react";
import "./css/scheduler.css";

export default class TodayStatus extends Component {
  getAlertType = todayData => {
    if (todayData.status === "SUCCESS") {
      return "card-alert today-status-success";
    } else if (todayData.status === "FAILED") {
      return "card-alert today-status-failure";
    } else {
      return "card-alert today-status-in-progress";
    }
  };

  checkVisibilityStatus = schedulerName => {
    let todayData = this.props.firstData;
    let cardBodyClassNames ="card-body col-12 shadow-lg";
    let cardAlertClassNames="card-alert";
    let todayStatus = "N/A";
    let passedRecords = "N/A";
    let failedRecords = "N/A";
    let triggerMode = "N/A";
    let startTime = "N/A";
    let message = "The data will be populated only if the specific scheduler is selected.";
    if (schedulerName !== "ALL" && this.props.isTodayDataAvailable) {
      cardAlertClassNames = this.getAlertType(todayData);
      todayStatus=todayData.status;
      passedRecords=todayData.passedRecords;
      failedRecords=todayData.failedRecords;
      startTime=this.props.formatDateAndTime(todayData.startTime);
      triggerMode=todayData.triggerMode;
      message="This data is based on the last scheduler execution.";
    }
    else if (schedulerName !== "ALL" && !this.props.isTodayDataAvailable){
      cardAlertClassNames = "card-alert today-status-yet-to-run";
      message="The scheduler is not yet started.Please check the schedule time and the status.";
    }
      return (
        <div className="row col-12">
          <div className={cardAlertClassNames} role="alert">
            <div className={cardBodyClassNames}>
              <h5 className="card-title">Today's Status: {todayStatus}</h5>
              <p className="card-text">Passed Records: {passedRecords}</p>
              <p className="card-text">Failed Records: {failedRecords}</p>
              <p className="card-text">Trigger Mode: {triggerMode}</p>
              <p className="card-text">
                {message}
              </p>
            </div>
          </div>
        </div>
      );
  }

  render() {
    return this.checkVisibilityStatus(this.props.schedulerName);
  }
}
