import React, { Component } from 'react';

export class PurlPriceHeader extends Component {
     constructor(props){
         super(props);

     }
    render(){
      return (

        <table style={{emptyCells: "show", tableLayout: "fixed"}}>
        <tbody>
            <tr>
                <td style={{fontWeight: 'bold', padding:'10px', display:'flex'}} valign="middle" align="center">
                    {pricefields(this.props.priceHeader1,this.props.priceOverride1,this.props.postPrice1)}
                    {pricefields(this.props.priceHeader2,this.props.priceOverride2,this.props.postPrice2)}
                    {pricefields(this.props.priceHeader3,this.props.priceOverride3,this.props.postPrice3)}
                    {pricefields(this.props.priceHeader4,this.props.priceOverride4,this.props.postPrice4)}
                </td>
            </tr>
            </tbody>
        </table>
      );
    }
}

const priceValueFont=(str)=>{
    str=str.replace("$","<sup>$</sup>")
    str=str.replace("%","<sup>%</sup>")
    let st= str;
    let res = str.split(".");
    if(res.length!==1){
     res[1]=res[1].replace(res[1],"<sup>"+res[1]+"</sup>");
     st= res[0]+res[1];
    }
    return st;
 }
 const getCouponMarkup=(markup)=> {
    return {__html: markup};
  }

const pricefields= (priceHeader, priceOverride, PostPrice)=>{
    return(
        <div id="priceBlock" style={{padding:'5px',display:(priceHeader!==""?"block":"none")}}>
            <div id="priceheader1" style={{paddingRight: '5px', textAlign: 'center'}}>
                <font face="Arial" color="#000" dangerouslySetInnerHTML={getCouponMarkup(priceHeader)}></font>
            </div>
            <div id="price1" style={{fontFamily: 'Arial, sans-serif',fontSize:'28px' , fontWeight: 'bold'}}>
                <font face="Arial" color="#000" style={{fontSize:'28px'}} dangerouslySetInnerHTML={getCouponMarkup(priceOverride!==""? priceValueFont(priceOverride):priceHeader!==""?"<sup>$</sup>XX":"")}></font>
                <font face="Arial" color="#000" style={{fontSize:'15px'}}>{priceHeader!==""?PostPrice:""}</font>
            </div>
        </div>
    );

}

export default PurlPriceHeader;