const schedulerState = {
  eventList: [],
  refreshEventList: false,
};

const schedulerReducer = (state = schedulerState, action) => {
  if (action.type === "REFRESH_SCHEDULE_EVENT_LIST") {
    return {
      ...state,
      refreshEventList: action.data,
    };
  }
  if (action.type === "SET_SCHEDULE_EVENT_LIST") {
    return {
      ...state,
      eventList: action.data,
    };
  }

  return state;
};
export default schedulerReducer;
