import React from "react";
import NationalCouponTemplateCore from "./../previewCoupon/nationalCouponTemplateCore";
import DealerCouponPURLTemplate from "./../previewCoupon/dealerCouponPURLTemplate";
import "./couponCard.css";
const CouponCard = (props) => {
  const couponsDisplay = (item) => {
    if (props.label === "National Offer Coupons") {
     
      return (
        <NationalCouponTemplateCore
          header={item.header}
          includes={item.includes}
          imageUrl={item.imageUrl}
          id={item.id}
          expires={item.expires}
          imageAltText={item.imageAltText}
        ></NationalCouponTemplateCore>
      );
    } else {
      return (
        <DealerCouponPURLTemplate
          header={item.header}
          priceHeader1={item.priceHeader1}
          priceHeader2={item.priceHeader2}
          priceHeader3={item.priceHeader3}
          priceHeader4={item.priceHeader4}
          includes={item.includes}
          id={item.id}
          expires={item.expires}
          postPrice1={item.postPrice1}
          postPrice2={item.postPrice2}
          postPrice3={item.postPrice3}
          postPrice4={item.postPrice4}
          priceOverride1={item.priceOverride1}
          priceOverride2={item.priceOverride2}
          priceOverride3={item.priceOverride3}
          priceOverride4={item.priceOverride4}
          templateFor="PDF"
        ></DealerCouponPURLTemplate>
      );
    }
  };
  return (
    <div className="coupon-card" id={props.entry.id}>
      <CardInfo entry={props.entry} />
      <div className="coupon-display">{couponsDisplay(props.entry)}</div>
    </div>
  );
};
export default CouponCard;

export const CardInfo = (props) => {
  return (
    <div className="card-info">
      <div style={{ textAlign: "center", margin: "0 2px 2px 0" }}>
        ID: <b>{props.entry.id}</b>
      </div>
      <div
        style={{
          textAlign: "left",
          display: "flex",
          flexWrap: "wrap",
          width: isNationalOffer(props.entry) ? "277px" : "438px",
        }}
      >
        {props.entry.active ? (
          <span
            id="active_status"
            style={{
              backgroundColor: "#0c800c",
              borderRadius: "20px",
              padding: "0px 5px",
              color: "white",
              margin: "0 2px 2px 0",
            }}
          >
            A
          </span>
        ) : (
          <span
            id="active_status"
            style={{
              backgroundColor: "#a72323",
              borderRadius: "20px",
              padding: "0px 5px",
              color: "white",
              margin: "0 2px 2px 0",
            }}
          >
            I
          </span>
        )}
        {props.entry.statusPublished ? (
          <span
            id="published_status"
            style={{
              backgroundColor: "#0c800c",
              borderRadius: "20px",
              padding: "0px 5px",
              color: "white",
              margin: "0 2px 2px 0",
            }}
          >
            P
          </span>
        ) : (
          <span
            id="published_status"
            style={{
              backgroundColor: "#a72323",
              borderRadius: "20px",
              padding: "0px 5px",
              color: "white",
              margin: "0 2px 2px 0",
            }}
          >
            UP
          </span>
        )}
        {getEnvTags(props.entry.environmental) !== null ? (
          <span
            id="environmental_indicator"
            style={{
              backgroundColor: "#0068b3",
              borderRadius: "20px",
              padding: "0px 5px",
              color: "white",
              margin: "0 2px 2px 0",
            }}
          >
            {props.entry.environmental.indicator}
          </span>
        ) : (
          ""
        )}
      </div>
      <div
        id="programs"
        style={{
          textAlign: "left",
          display: "flex",
          flexWrap: "wrap",
          width: isNationalOffer(props.entry) ? "277px" : "438px",
        }}
      >
        {getprogramTags(props.entry.programs).map((it) => {
          return (
            <span
              key={it}
              style={{
                backgroundColor: "#52565c",
                borderRadius: "20px",
                padding: "0px 5px",
                color: "white",
                margin: "0 2px 2px 0",
              }}
            >
              {it}
            </span>
          );
        })}
      </div>
    </div>
  );
};
const getprogramTags = (item) => {
  let programarray = [];
  item.reduce((arr, it) => {
    programarray.push(it.program);
  }, []);
  return programarray;
};
const getEnvTags = (item) => {
  if (item !== null) {
    if (item.indicator !== "BLANK") {
      return item.indicator;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
const isNationalOffer = (item) => {
  return item.category.name.toUpperCase() === "Featured Service".toUpperCase();
};
