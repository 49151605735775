import React, { Component } from "react";
import Modal from "react-modal";
import "./categoryTable.css";
import Axios from "../../service";
import ReactTooltip from "react-tooltip";
import Cookies from "js-cookie";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "auto",
    height: "auto",
    backgroundColor: "#DADFE6",
  },
};
export default class EditCategoryModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      categoryName: "",
      categoryOrder: "",
      categoryId: "",
      invalidAttempt: "",
      categoryOrderValidationFlag: true,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCategoryNameChange = this.handleCategoryNameChange.bind(this);
    this.handleCategoryOrderChange = this.handleCategoryOrderChange.bind(this);
    this.handleUpdatebutton = this.handleUpdatebutton.bind(this);
    this.handleCancelbutton = this.handleCancelbutton.bind(this);
    this.handlecategoryNametrim = this.handlecategoryNametrim.bind(this);
    this.handleCategoryOrderTrim = this.handleCategoryOrderTrim.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("#root");
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //   this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false, invalidAttempt: "" });
    this.props.modalIsOpenHandle(false);
  }
  handleCategoryNameChange(event) {
    this.setState({ categoryName: event.target.value, invalidAttempt: "" });
  }
  handlecategoryNametrim(event) {
    this.state.categoryName = event.target.value.trim();
    this.setState({});
  }
  handleCategoryOrderChange(event) {
    this.setState({ categoryOrder: event.target.value });
  }
  handleCategoryOrderTrim(event) {
    this.state.categoryOrder = event.target.value.trim();
    this.setState({});
    if (this.state.categoryOrder.match("^[0-9][0-9]*$")) {
      this.setState({ categoryOrderValidationFlag: true });
    } else {
      this.setState({ categoryOrderValidationFlag: false });
    }
  }
  handleCancelbutton() {
    this.setState({ invalidAttempt: "" });
    this.props.modalIsOpenHandle(false);
  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      categoryId: nextprops.categoryID,
      categoryName: nextprops.categoryName,
      categoryOrder: nextprops.categoryOrder,
    });
  }
  handleUpdatebutton(event) {
    if (
      this.state.categoryName !== "" &&
      this.state.categoryOrder !== "" &&
      this.state.categoryOrderValidationFlag
    ) {
      event.preventDefault();
      Axios.put(
        `/manageCoupons/api/categories/${this.state.categoryId}`,
        {
          name: this.state.categoryName,
          categoryOrder: this.state.categoryOrder,
        },
        {
          headers: {
            Authorization: Cookies.get("token"),
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            this.props.categoryListRefresh(true);
            this.props.modalIsOpenHandle(false);
          } else if (response.status === 401) {
            this.setState({
              invalidAttempt: "Not Authorized to create category",
            });
          } else if (response.status === 500) {
            this.setState({
              invalidAttempt:
                "Unable to save the category please try again later.",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.setState({ invalidAttempt: error.response.data.errorMessage });
          } else {
            this.setState({
              invalidAttempt:
                "Unable to save the category. Please try again later",
            });
          }
        });
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Edit Category Modal"
        >
          <div className="create-category-modal-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <span className="create-category-label-style">
                  EDIT CATEGORY
                </span>
              </div>
              <div>
                <button style={{ border: "none", backgroundColor: "#DADFE6" }}>
                  <img
                    src="./../close_button.png"
                    id="close-button-id"
                    style={{ height: "21px", width: "21px" }}
                    onClick={this.closeModal}
                    alt="close button"
                  />
                </button>
              </div>
            </div>
            <form>
              <div className="create-category-form-row">
                <div className="create-category-form-column">
                  <span className="create-category-field-header">
                    NAME<superscript style={{ color: "red" }}>*</superscript>
                  </span>
                  <br />
                  <input
                    id="create-category-name-field-id"
                    className="create-category-name-field-style"
                    name=""
                    maxLength="45"
                    value={this.state.categoryName}
                    onChange={this.handleCategoryNameChange}
                    onBlur={this.handlecategoryNametrim}
                    required
                  />
                </div>
                <div className="create-category-form-column">
                  <span className="create-category-field-header">
                    ORDER<superscript style={{ color: "red" }}>*</superscript>
                  </span>
                  <br />
                  <input
                    pattern="^[0-9][0-9]*$"
                    id="create-category-order-field-id"
                    className="create-category-order-field-style"
                    data-tip="Tip: Accepts only positive integer number"
                    maxLength="10"
                    value={this.state.categoryOrder}
                    name="categoy-order"
                    onChange={this.handleCategoryOrderChange}
                    onBlur={this.handleCategoryOrderTrim}
                    required
                  />
                </div>
              </div>
              <div className="create-category-form-row">
                <div className="create-category-form-column">
                  <button
                    id="create-category-button-id"
                    className="create-category-button"
                    onClick={this.handleUpdatebutton}
                  >
                    <span className="create-category-button-text-style">
                      UPDATE
                    </span>
                  </button>
                </div>
                <div className="create-category-form-column">
                  <button
                    id="create-category-cancel-button-id"
                    className="create-category-cancel-button"
                    onClick={this.handleCancelbutton}
                  >
                    <span className="create-category-button-text-style">
                      CANCEL
                    </span>
                  </button>
                </div>
              </div>
              <div>
                <span style={{ color: "red" }}>
                  {this.state.invalidAttempt}
                </span>
              </div>
            </form>
          </div>
          <ReactTooltip />
        </Modal>
      </div>
    );
  }
}
