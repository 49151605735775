import React, { Component } from "react";
import Modal from "react-modal";
import Select from "react-select";
import Axios from "../../service";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import {
  ValidateCouponIsPresent,
  getUsername,
  ValidateCouponIsPresentForEdit,
  getMaxDate,
  inputDateValidator,
} from "./util";
import "./NewEventModal.css";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";

const couponsStatus = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];
export default class NewEventModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      couponSelected: null,
      couponStatus: null,
      validatorStatus: true,
      validatorText: "",
      fromDate: "",
      toDate: "",
      couponIsDisabled: false,
      couponsList: [],
      eventId: "",
      event: null,
      submitDisabled: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("#root");
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
      couponSelected: null,
      couponStatus: null,
      fromDate: "",
      toDate: "",
      validatorText: "",
      validatorStatus: false,
      event: null,
      eventId: "",
      submitDisabled: false,
    });
    this.props.modalIsOpenHandle(false);
  }
  componentWillReceiveProps(nextprops) {
    if (!nextprops.isCreate) {
      this.setState({
        couponSelected: nextprops.selectedCoupon,
        couponStatus: {
          label: nextprops.event.desired_status,
          value: nextprops.event.desired_status,
        },
        eventId: nextprops.eventId,
        fromDate:
          nextprops.event.start_date &&
          nextprops.event.start_date.split("T")[0],
        toDate:
          nextprops.event.end_date && nextprops.event.end_date.split("T")[0],
      });
    }
  }
  createEvent(couponSelected, couponStatus, fromDate, toDate) {
    if (inputDateValidator(fromDate) && inputDateValidator(toDate)) {
      Axios.post(
        "/event-service/event",
        {
          coupon_id: couponSelected.value,
          start_date: fromDate,
          end_date: toDate,
          desired_status: couponStatus.value,
          user_id: getUsername(),
        },
        {
          headers: {
            Authorization: Cookies.get("token"),
          },
        }
      )
        .then((response) => {
          if (response.status === 201) {
            this.closeModal();
          } else {
            this.setState({
              validatorText: "Unable to create, Try again later.",
              validatorStatus: true,
              submitDisabled: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            validatorText: "Unable to create, Try again later.",
            validatorStatus: true,
            submitDisabled: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        validatorText:
          "Please make sure from and to date is not beyond 2 years from today.",
        validatorStatus: true,
        submitDisabled: false,
      });
    }
  }
  EditEvent(couponSelected, couponStatus, fromDate, toDate, eventId) {
    if (inputDateValidator(fromDate) && inputDateValidator(toDate)) {
      Axios.put(
        "/event-service/event",
        {
          event_id: eventId,
          coupon_id: couponSelected.value,
          start_date: fromDate,
          end_date: toDate,
          desired_status: couponStatus.value,
          user_id: getUsername(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allowed-Origin": "*",
            Authorization: Cookies.get("token"),
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            this.closeModal();
          } else {
            this.setState({
              validatorText: "Unable to create, Try again later.",
              validatorStatus: true,
              submitDisabled: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            validatorText: "Unable to create, Try again later.",
            validatorStatus: true,
            submitDisabled: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        validatorText:
          "Please make sure from and to Date is not beyond 2 years from today.",
        validatorStatus: true,
        submitDisabled: false,
      });
    }
  }
  submit = () => {
    this.setState({ submitDisabled: true });
    const { couponSelected, couponStatus, fromDate, toDate } = this.state;
    if (this.props.isCreate) {
      if (
        couponSelected !== null &&
        couponStatus !== null &&
        fromDate !== "" &&
        toDate !== ""
      ) {
        if (
          !ValidateCouponIsPresent(
            couponSelected,
            fromDate,
            toDate,
            this.props.eventList
          )
        ) {
          this.createEvent(couponSelected, couponStatus, fromDate, toDate);
        } else {
          this.setState({
            validatorText:
              "Event is already scheduled for this coupon for these dates.",
            validatorStatus: true,
            submitDisabled: false,
          });
        }
      } else {
        this.setState({
          validatorText: "Please fill all the fields",
          validatorStatus: true,
          submitDisabled: false,
        });
      }
    } else {
      this.submitEditSchedule();
    }
  };
  submitEditSchedule() {
    const { couponSelected, couponStatus, fromDate, toDate, eventId } =
      this.state;
    if (
      couponSelected !== null &&
      couponStatus !== null &&
      fromDate !== "" &&
      toDate !== ""
    ) {
      if (
        !ValidateCouponIsPresentForEdit(
          couponSelected,
          fromDate,
          toDate,
          this.props.eventList,
          eventId
        )
      ) {
        this.EditEvent(couponSelected, couponStatus, fromDate, toDate, eventId);
      } else {
        this.setState({
          validatorText:
            "Event is already scheduled for this coupon for these dates.",
          validatorStatus: true,
          submitDisabled: false,
        });
      }
    } else {
      this.setState({
        validatorText: "Please fill all the fields",
        validatorStatus: true,
        submitDisabled: false,
      });
    }
  }
  handleFromDate = (event) => {
    this.setState({ fromDate: event.target.value, toDate: event.target.value });
  };
  handleToDate = (event) => {
    this.setState({ toDate: event.target.value });
  };
  handleCouponChange = (couponSelected) => {
    this.setState({ couponSelected });
  };
  handleCouponStatusChange = (couponStatus) => {
    this.setState({ couponStatus });
  };
  render() {
    const { couponSelected, couponStatus, validatorStatus, validatorText } =
      this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          className="scheduleModal"
          contentLabel="SCHEDULE EVENT"
        >
          <div className="scheduleModal__headerSection">
            <h3>
              {this.props.isCreate
                ? "Create Schedule Event"
                : "Edit Schedule Event"}
            </h3>
          </div>
          <form className="scheduleModal__form" onSubmit={this.submit}>
            <div className="scheduleModal__row">
              <div className="scheduleModal__fields_select">
                <label>Coupon</label>
                <Select
                  id="Coupon_selector"
                  value={couponSelected}
                  onChange={this.handleCouponChange}
                  options={this.props.couponsList}
                  styles={{ width: "200px" }}
                  required
                  maxMenuHeight={130}
                  menuShouldScrollIntoView={false}
                  isDisabled={!this.props.isCreate}
                />
              </div>
              <div className="scheduleModal__fields_select">
                <label>Coupon Status</label>
                <Select
                  id="coupon_status_selector"
                  value={couponStatus}
                  onChange={this.handleCouponStatusChange}
                  options={couponsStatus}
                  styles={{ width: "200px" }}
                  required
                />
              </div>
            </div>
            <div className="scheduleModal__row">
              <div className="scheduleModal__fields__date">
                <label>From</label>
                <input
                  type="date"
                  name="From"
                  id="from_date"
                  min={new Date().toISOString().split("T")[0]}
                  required
                  max={getMaxDate()}
                  onChange={this.handleFromDate}
                  value={this.state.fromDate}
                />
              </div>

              <div className="scheduleModal__fields__date">
                <label>To</label>
                <input
                  type="date"
                  name="To"
                  id="to_date"
                  min={
                    this.state.fromDate
                      ? this.state.fromDate
                      : new Date().toISOString().split("T")[0]
                  }
                  max={getMaxDate()}
                  required
                  onChange={this.handleToDate}
                  value={this.state.toDate}
                />
              </div>
            </div>
            <div className="scheduleModal__row">
              {validatorStatus && (
                <div className="scheduleModal__validator__text">
                  <p>{validatorText}</p>
                </div>
              )}
              <div className="scheduleModal__buttons">
                <IconButton
                  color="primary"
                  id="event_submit_button"
                  className="scheduleModal__button"
                  onClick={this.submit}
                  disabled={this.state.submitDisabled}
                  title="Save"
                >
                  <DoneIcon fontSize="large" />
                </IconButton>
                <IconButton
                  color="secondary"
                  id="modal_close"
                  className="scheduleModal__button"
                  onClick={this.closeModal}
                  title="Close"
                >
                  <ClearIcon fontSize="large" style={{ color: "#c6084b" }} />
                </IconButton>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
