import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "./ScheduleTable.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import NewEventModal from "./NewEventModal";
import { connect } from "react-redux";
import Axios from "../../service";
import Cookies from "js-cookie";

export class ScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createModalIsOpen: false,
      couponsList: [],
      isCreate: false,
      eventList: [],
      eventId: "",
      selectedCoupon: {},
      event: {},
    };
  }
  handleModalIsOpen = (e) => {
    this.setState({
      createModalIsOpen: e,
    });
    this.props.refreshEventList(true);
    //pass eventlist refresh token
  };
  handleEditEvent = (event) => {
    this.setState({
      selectedCoupon: { label: event.coupon_id, value: event.coupon_id },
      eventId: event.event_id,
      createModalIsOpen: true,
      event: event,
    });
  };
  handleDeleteEvent = (event) => {
    if (window.confirm("Are you sure to delete the event?")) {
      Axios.delete(`/event-service/event?event-id=${event}`, {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
        .then((response) => {
          this.props.refreshEventList(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  componentWillReceiveProps(nextprops) {
    this.setState({ eventList: nextprops.eventList });
  }
  render() {
    const { events, month } = this.props;
    return (
      <div className="table-wrapper-scroll-y my-custom-scrollbar">
        <Table
          id={month}
          className="table table-striped table-lg text-nowrap"
          cellSpacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th> Coupon</th>
              <th>Status</th>
              <th>From</th>
              <th>To</th>
              {/* <th>Created On</th> */}
              <th>Username</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {events.map((ev) => (
              <tr id={ev.event_id} key={ev.event_id}>
                <td>{ev.coupon_id}</td>
                <td>{ev.desired_status}</td>
                <td>{ev.start_date && ev.start_date.split("T")[0]}</td>
                <td>{ev.end_date && ev.end_date.split("T")[0]}</td>
                {/* <td>
                            {ev.created_at && ev.created_at.split('T')[0]}
                        </td> */}
                <td>{ev.user_id}</td>
                <td>
                  <IconButton
                    color="primary"
                    id="editEvent"
                    onClick={() => this.handleEditEvent(ev)}
                    style={{ padding: "5px" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ color: "#c6084b", padding: "5px" }}
                    id="deleteEvent"
                    onClick={() => this.handleDeleteEvent(ev.event_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <NewEventModal
          modalIsOpen={this.state.createModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          couponsList={this.state.couponsList}
          isCreate={this.state.isCreate}
          selectedCoupon={this.state.selectedCoupon}
          eventList={this.state.eventList}
          eventId={this.state.eventId}
          event={this.state.event}
        ></NewEventModal>
      </div>
    );
  }
}
const mapStateToProps = (states) => {
  return {
    eventList: states.schedulerReducer.eventList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    refreshEventList: (value) =>
      dispatch({
        type: "REFRESH_SCHEDULE_EVENT_LIST",
        data: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTable);
