import React, { Component } from 'react';
import './previewCouponStyle.css';
import TemplateCoupon from "./templateCoupon";
import { connect } from 'react-redux';

export class PreviewCoupon extends Component {
     constructor(props){
         super(props);
         this.state={
             selectedTemplate:"HTML3",
             templates:["HTML3", "HTML6"],
             selectedRow:[]
         };
         this.handleTemplateChange=this.handleTemplateChange.bind(this);
     }
     componentWillReceiveProps(nextprops){
        this.setState({selectedRow:nextprops.selectedRow});
     }
     handleTemplateChange(event){
         this.setState({selectedTemplate:event.target.value});
         this.props.passTemplateType(event.target.value);
     }

    render(){
        return (
        <div className="preview-coupon-container-inside-updatecoupon">
            <div className="preview-coupon-header">
                <h1>Preview Template</h1>
                <select id='template-select' value={this.state.selectedTemplate} onChange={this.handleTemplateChange}>
                {this.state.templates.map((template)=>{
                    return <option value={template}>{template}</option>;
                })}
                </select>
            </div>

            <div id="id-coupon"><p><strong>{this.state.selectedRow["id"]}</strong></p></div>
            <TemplateCoupon></TemplateCoupon>
        </div>
        );
    }
}

const mapStateToProps = (states) => {
    const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
    return {
        selectedRow: state.selectedRow
    };
  };

  const mapDispatchToProps=dispatch => {
    return {
        passTemplateType: (val) => dispatch({
            type: "SET_EDIT_COUPON_PREVIEW_TYPE",
            data:val
        })
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(PreviewCoupon);