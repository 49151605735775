import axios from "axios";

const Axios = axios.create({
  baseURL: `https://${window.env?.ENV_URL}`,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allowed-Origin": "*",
    "Access-Control-Allow-Origin": "*",
  },
});

export default Axios;
