import React, { Component } from "react";
import "./createCoupon.css";
import Axios from "../../../service";
import PriceHeaderComponent from "./priceHeaderComponent";
import "./../couponHeader.css";
import history from "../../history";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import TextField from "@material-ui/core/TextField";
import MultiSelectDropDown from "../../common/multiSelectDropDown";
import { getUsername } from "../../ScheduleTab/util";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

const {
  Aborter,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  ServiceURL,
  StorageURL,
  AnonymousCredential,
} = require("@azure/storage-blob");
export class CreateCouponForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: "United States",
      countryCode: "us",
      languageValue: "English",
      languageCode: "en",
      categoryID: "11",
      categoryOrder: "1",
      categoryName: "Featured Service",
      countries: [],
      languages: [],
      categories: [],
      name: "",
      header: "",
      couponID: "",
      includes: "",
      image: " ",
      imageURL: " ",
      imageAltText: " ",
      CategoryEntries: [],
      LanguageEntries: [],
      CountryEntries: [],
      priceHeader_1: "",
      priceHeader_2: "",
      priceHeader_3: "",
      priceHeader_4: "",
      priceRequired_1: "",
      priceRequired_2: "",
      priceRequired_3: "",
      priceRequired_4: "",
      defaultFree_1: "",
      defaultFree_2: "",
      defaultFree_3: "",
      defaultFree_4: "",
      postPrice_1: "",
      postPrice_2: "",
      postPrice_3: "",
      postPrice_4: "",
      priceOverride_1: "",
      priceOverride_2: "",
      priceOverride_3: "",
      priceOverride_4: "",
      priceOverrideCheckbox_1: "",
      priceOverrideCheckbox_2: "",
      priceOverrideCheckbox_3: "",
      priceOverrideCheckbox_4: "",
      invalidAttempt: " ",
      priceValidationFlag_1: false,
      priceValidationFlag_2: false,
      priceValidationFlag_3: false,
      priceValidationFlag_4: false,
      sortOrder: 0,
      sortOrderValidationFlag: true,
      expiryDateUIFormat: "",
      expiryDateDBFormat: "",
      isPriceFieldDisplayed1: true,
      isPriceFieldDisplayed2: false,
      isPriceFieldDisplayed3: false,
      isPriceFieldDisplayed4: false,
      count_PriceFieldDisplayed: 1,
      environmental_indicators_list: [],
      envIndi_value: "BLANK",
      envIndi_id: 0,
      programs_list: [],
      programs_value: "BLANK",
      programs_id: 0,
      programsData: {},
      selectedPrograms: [],
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCouponIDChange = this.handleCouponIDChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleImageAltText = this.handleImageAltText.bind(this);
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handleIncludeschange = this.handleIncludeschange.bind(this);
    this.createCouponSubmitButton = this.createCouponSubmitButton.bind(this);
    this.handlePriceHeaderChange = this.handlePriceHeaderChange.bind(this);
    this.handlePriceRequiredChange = this.handlePriceRequiredChange.bind(this);
    this.handleDefaultFreeChange = this.handleDefaultFreeChange.bind(this);
    this.handlePostPriceChange = this.handlePostPriceChange.bind(this);
    this.handlePriceOverrideCheckboxChange =
      this.handlePriceOverrideCheckboxChange.bind(this);
    this.handlePriceOverrideChange = this.handlePriceOverrideChange.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handlePriceValidationFlag = this.handlePriceValidationFlag.bind(this);
    this.handlesortOrder = this.handlesortOrder.bind(this);
    this.handleExpiryDate = this.handleExpiryDate.bind(this);
    this.handlePriceFieldAdd = this.handlePriceFieldAdd.bind(this);
    this.handlePriceFieldSub = this.handlePriceFieldSub.bind(this);
    this.handleEnvironmentalIndicatorChange =
      this.handleEnvironmentalIndicatorChange.bind(this);
    this.handleProgramsChange = this.handleProgramsChange.bind(this);
    this.decodedTokenData = jwt.decode (Cookies.get("token")) || {};
  }
  componentWillMount() {
    Axios.get("/manageCoupons/api/getCategoryList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((CategoryResponse) => {
        CategoryResponse.data.map((res) => {
          this.state.CategoryEntries.push(res);
        });
        this.setState({ categories: this.state.CategoryEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/getLanguageList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((LanguageResponse) => {
        LanguageResponse.data.map((res) => {
          this.state.LanguageEntries.push(res);
        });
        this.setState({ languages: this.state.LanguageEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/getCountryList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((CountryResponse) => {
        CountryResponse.data.map((res) => {
          this.state.CountryEntries.push(res);
        });
        this.setState({ countries: this.state.CountryEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    //axios all for environmental indicator
    Axios.get("/manageCoupons/api/environmentals", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState({ environmental_indicators_list: arr });
      })
      .catch((error) => {
        console.log(error);
      });
    //axios all for programs
    Axios.get("/manageCoupons/api/programs", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState(
          {
            programs_list: arr,
            programsData: this.setCheckBoxProgramData(arr),
          },
          this.setSelectedPrograms
        );
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ expiryDateUIFormat: this.formatToUIDateType("") });
    if (Object.keys(this.props.copyData).length > 0) {
      this.copyDataToInputFields(this.props.copyData);
    }
    if (this.decodedTokenData && this.decodedTokenData.market) {
      this.setState({ countryCode: this.decodedTokenData.market });
    }
  }
  setCheckBoxProgramData(item) {
    let checkboxProgramData = item.reduce(
      (options, option) => ({
        ...options,
        [option.program]: false,
      }),
      {}
    );
    return checkboxProgramData;
  }
  submitcall() {
    Axios.post(
      "/manageCoupons/api/createCoupon",
      {
        id: this.state.couponID,
        couponName: this.state.name,
        header: this.state.header,
        category: {
          name: this.state.categoryName,
          id: this.state.categoryID,
          categoryOrder: this.state.categoryOrder,
        },
        country: this.state.countryCode,
        language: this.state.languageCode,
        includes: this.state.includes,
        imageUrl: this.state.imageURL,
        imageAltText: this.state.imageAltText,
        approved: "on",
        couponType: "standard",
        forcePrice1: this.state.priceOverrideCheckbox_1,
        forcePrice2: this.state.priceOverrideCheckbox_2,
        forcePrice3: this.state.priceOverrideCheckbox_3,
        forcePrice4: this.state.priceOverrideCheckbox_4,
        // "lastUpdate":this.getCurrentDate(),
        parentCoupon: " ",
        postPrice1: this.state.postPrice_1,
        postPrice2: this.state.postPrice_2,
        postPrice3: this.state.postPrice_3,
        postPrice4: this.state.postPrice_4,
        price1Free: this.state.defaultFree_1,
        price2Free: this.state.defaultFree_2,
        price3Free: this.state.defaultFree_3,
        price4Free: this.state.defaultFree_4,
        priceHeader1: this.state.priceHeader_1,
        priceHeader2: this.state.priceHeader_2,
        priceHeader3: this.state.priceHeader_3,
        priceHeader4: this.state.priceHeader_4,
        price1Required: this.state.priceRequired_1,
        price2Required: this.state.priceRequired_2,
        price3Required: this.state.priceRequired_3,
        price4Required: this.state.priceRequired_4,
        priceOverride1: this.state.priceOverride_1,
        priceOverride2: this.state.priceOverride_2,
        priceOverride3: this.state.priceOverride_3,
        priceOverride4: this.state.priceOverride_4,
        specialPriceParent: " ",
        sortOrder: this.state.sortOrder,
        expires: this.convertExpiryDateToDBFormat(
          this.state.expiryDateUIFormat
        ),
        environmental: {
          id: this.state.envIndi_id,
          indicator: this.state.envIndi_value,
        },
        programs: this.getSelectedPrograms(
          this.state.programs_list,
          this.state.programsData
        ),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allowed-Origin": "*",
          username: getUsername(),
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          this.props.setCreateCouponModalClose(true);
          this.props.passRefreshCouponTable(true);
        } else if (response.status === 401) {
          this.setState({ invalidAttempt: "Not Authorized to create coupon" });
        } else if (response.status === 500) {
          this.setState({
            invalidAttempt: "Unable to save the coupon please try again later.",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({ invalidAttempt: error.response.data.errorMessage });
        } else {
          this.setState({
            invalidAttempt: "Unable to save the coupon. Please try again later",
          });
        }
      });
  }
  createCouponSubmitButton(event) {
    if (
      this.state.couponID !== "" &&
      this.state.name !== "" &&
      this.state.includes !== "" &&
      this.state.priceValidationFlag_1 === false &&
      this.state.priceValidationFlag_2 === false &&
      this.state.priceValidationFlag_3 === false &&
      this.state.priceValidationFlag_4 === false &&
      this.state.sortOrderValidationFlag
    ) {
      event.preventDefault();
      this.submitcall();
    }
  }
  handleCountryChange(event) {
    this.state.countries.map((country) => {
      if (country.code === event.target.value) {
        this.setState({
          countryCode: country.code,
          countryValue: event.target.value,
        });
      }
    });
    this.setState({ countryValue: event.target.value });
  }

  handleLanguageChange(event) {
    this.state.languages.map((lang) => {
      if (lang.code === event.target.value) {
        this.setState({
          languageCode: lang.code,
          languageValue: event.target.value,
        });
      }
    });
    this.setState({ languageValue: event.target.value });
  }
  
  handleCategoryChange(event) {
    this.state.categories.map((cat) => {
      if (cat.id == event.target.value) {
        this.setState({
          categoryID: cat.id,
          categoryOrder: cat.categoryOrder,
          categoryName: cat.name,
        });
        
        if(cat.name !== "Featured Service"){
          this.setState({
            image: " ",
            imageURL: " ",
            imageAltText: " "
          })
        }
      }
     
    });
    
  }
  handleEnvironmentalIndicatorChange(event) {
    this.state.environmental_indicators_list.map((env) => {
      if (env.id == event.target.value) {
        this.setState({
          envIndi_id: env.id,
          envIndi_value: env.indicator,
        });
      }
    });
  }

  
  handleProgramsChange(event) {
    this.setState({ programsData: event });
  }
  getSelectedPrograms(programs_list, programsData) {
    return programs_list.filter((ab) => programsData[ab.program] === true);
  }
  setSelectedPrograms() {
    this.state.selectedPrograms.map((ab) => {
      this.state.programsData[ab.program] = true;
    });
    this.setState({});
  }
  handlesortOrder(event) {
    this.setState({ sortOrder: event.target.value });
    if (event.target.value.trim() !== null) {
      if (event.target.value.match("^[0-9][0-9]*$")) {
        this.setState({ sortOrderValidationFlag: true });
      } else {
        this.setState({ sortOrderValidationFlag: false });
      }
    } else {
      this.setState({ sortOrderValidationFlag: false });
    }
  }
  handleImageAltText(event) {
    this.setState({ imageAltText: event.target.value });
  }
  handleIncludeschange(event) {
    this.setState({ includes: event.target.value });
  }
  handleCouponIDChange(event) {
    this.setState({ couponID: event.target.value.trim() });
  }
  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleHeaderChange(event) {
    this.setState({ header: event.target.value });
  }
  handleExpiryDate(event) {
    this.setState({
      expiryDateUIFormat: event.target.value,
      expiryDateDBFormat: this.convertExpiryDateToDBFormat(event.target.value),
    });
  }

  convertExpiryDateToDBFormat(date) {
    var dateArray = date.split("-");
    return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
  }

  handleUploadImage(event) {
    const anonymousCredential = new AnonymousCredential();
    const pipeline = StorageURL.newPipeline(anonymousCredential);
    const serviceURL = new ServiceURL(
      "https://" + Cookies.get("blobUrl") + "/" + Cookies.get("blobToken"),
      pipeline
    );
    const containerName = "coupon-images";
    const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
    var content = new Blob([event.target.files[0]], {
      type: [
        "image/PNG",
        "image/png",
        "image/JPEG",
        "image/jpeg",
        "image/JPG",
        "image/jpg",
      ],
    });
    const blobName = event.target.files[0].name;
    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
    blockBlobURL
      .upload(Aborter.none, content, content.size)
      .then((response) => {
        blockBlobURL.setHTTPHeaders(Aborter.none, {
          blobContentType: "image/jpeg",
        });
        var imgURL = blobName;
        this.setState({ image: "Uploaded Image : " + blobName });
        this.setState({ imageURL: imgURL });
      })
      .catch((error) => {
        this.setState({ image: "unable to upload" });
        console.log(error);
      });
  }
  handlePriceHeaderChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceHeader_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceHeader_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceHeader_3: valuefromchild });
    } else {
      this.setState({ priceHeader_4: valuefromchild });
    }
  }
  handlePriceRequiredChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceRequired_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceRequired_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceRequired_3: valuefromchild });
    } else {
      this.setState({ priceRequired_4: valuefromchild });
    }
  }
  handleDefaultFreeChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ defaultFree_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ defaultFree_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ defaultFree_3: valuefromchild });
    } else {
      this.setState({ defaultFree_4: valuefromchild });
    }
  }
  handlePostPriceChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ postPrice_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ postPrice_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ postPrice_3: valuefromchild });
    } else {
      this.setState({ postPrice_4: valuefromchild });
    }
  }
  handlePriceOverrideCheckboxChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceOverrideCheckbox_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceOverrideCheckbox_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceOverrideCheckbox_3: valuefromchild });
    } else {
      this.setState({ priceOverrideCheckbox_4: valuefromchild });
    }
  }
  handlePriceValidationFlag(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceValidationFlag_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceValidationFlag_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceValidationFlag_3: valuefromchild });
    } else {
      this.setState({ priceValidationFlag_4: valuefromchild });
    }
  }
  handlePriceOverrideChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceOverride_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceOverride_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceOverride_3: valuefromchild });
    } else {
      this.setState({ priceOverride_4: valuefromchild });
    }
  }
  handlePriceFieldAdd() {
    if (this.state.count_PriceFieldDisplayed < 4) {
      this.state.count_PriceFieldDisplayed =
        this.state.count_PriceFieldDisplayed + 1;
      if (this.state.count_PriceFieldDisplayed === 4) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: true,
          isPriceFieldDisplayed4: true,
        });
      }
      if (this.state.count_PriceFieldDisplayed === 3) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: true,
          isPriceFieldDisplayed4: false,
        });
      }
      if (this.state.count_PriceFieldDisplayed === 2) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: false,
          isPriceFieldDisplayed4: false,
        });
      }
    }
  }
  priceFieldSub_4_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader_4,
        this.state.priceRequired_4,
        this.state.defaultFree_4,
        this.state.postPrice_4,
        this.state.priceOverride_4,
        this.state.priceOverrideCheckbox_4
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 4 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed4: false,
          priceHeader_4: "",
          priceRequired_4: "",
          defaultFree_4: "",
          postPrice_4: "",
          priceOverride_4: "",
          priceOverrideCheckbox_4: "",
          priceValidationFlag_4: false,
          count_PriceFieldDisplayed: 3,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed4: false,
        priceHeader_4: "",
        priceRequired_4: "",
        defaultFree_4: "",
        postPrice_4: "",
        priceOverride_4: "",
        priceOverrideCheckbox_4: "",
        priceValidationFlag_4: false,
        count_PriceFieldDisplayed: 3,
      });
    }
  }
  priceFieldSub_3_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader_3,
        this.state.priceRequired_3,
        this.state.defaultFree_3,
        this.state.postPrice_3,
        this.state.priceOverride_3,
        this.state.priceOverrideCheckbox_3
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 3 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed3: false,
          priceHeader_3: "",
          priceRequired_3: "",
          defaultFree_3: "",
          postPrice_3: "",
          priceOverride_3: "",
          priceOverrideCheckbox_3: "",
          priceValidationFlag_3: false,
          count_PriceFieldDisplayed: 2,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed3: false,
        priceHeader_3: "",
        priceRequired_3: "",
        defaultFree_3: "",
        postPrice_3: "",
        priceOverride_3: "",
        priceOverrideCheckbox_3: "",
        priceValidationFlag_3: false,
        count_PriceFieldDisplayed: 2,
      });
    }
  }
  priceFieldSub_2_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader_2,
        this.state.priceRequired_2,
        this.state.defaultFree_2,
        this.state.postPrice_2,
        this.state.priceOverride_2,
        this.state.priceOverrideCheckbox_2
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 2 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed2: false,
          priceHeader_2: "",
          priceRequired_2: "",
          defaultFree_2: "",
          postPrice_2: "",
          priceOverride_2: "",
          priceOverrideCheckbox_2: "",
          priceValidationFlag_2: false,
          count_PriceFieldDisplayed: 1,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed2: false,
        priceHeader_2: "",
        priceRequired_2: "",
        defaultFree_2: "",
        postPrice_2: "",
        priceOverride_2: "",
        priceOverrideCheckbox_2: "",
        priceValidationFlag_2: false,
        count_PriceFieldDisplayed: 1,
      });
    }
  }
  handlePriceFieldSub() {
    if (this.state.count_PriceFieldDisplayed > 1) {
      if (this.state.count_PriceFieldDisplayed === 4) {
        this.priceFieldSub_4_Display();
      }
      if (this.state.count_PriceFieldDisplayed === 3) {
        this.priceFieldSub_3_Display();
      }
      if (this.state.count_PriceFieldDisplayed === 2) {
        this.priceFieldSub_2_Display();
      }
    }
  }

  copyDataToInputFields = (copyData) => {
    this.setState(
      {
        name: copyData.couponName,
        header: copyData.header,
        countryCode: copyData.country,
        countryValue: this.state.CountryEntries[copyData.country],
        languageCode: copyData.language,
        languageValue: this.state.LanguageEntries[copyData.language],
        categoryName: copyData.category.name,
        categoryID: copyData.category.id,
        categoryOrder: copyData.category.categoryOrder,
        sortOrder: copyData.sortOrder,
        includes: copyData.includes,
        expiryDateDBFormat: copyData.expires,
        priceHeader_1: copyData.priceHeader1,
        priceRequired_1: copyData.price1Required,
        defaultFree_1: copyData.price1Free,
        postPrice_1: copyData.postPrice1,
        priceOverrideCheckbox_1: copyData.forcePrice1,
        priceOverride_1: copyData.priceOverride1,
        priceHeader_2: copyData.priceHeader2,
        priceRequired_2: copyData.price2Required,
        defaultFree_2: copyData.price2Free,
        postPrice_2: copyData.postPrice2,
        priceOverrideCheckbox_2: copyData.forcePrice2,
        priceOverride_2: copyData.priceOverride2,
        priceHeader_3: copyData.priceHeader3,
        priceRequired_3: copyData.price3Required,
        defaultFree_3: copyData.price3Free,
        postPrice_3: copyData.postPrice3,
        priceOverrideCheckbox_3: copyData.forcePrice3,
        priceOverride_3: copyData.priceOverride3,
        priceHeader_4: copyData.priceHeader4,
        priceRequired_4: copyData.price4Required,
        defaultFree_4: copyData.price4Free,
        postPrice_4: copyData.postPrice4,
        priceOverrideCheckbox_4: copyData.forcePrice4,
        priceOverride_4: copyData.priceOverride4,
        //imageURL: copyData.imageUrl,        
        imageURL: copyData.category.name == "Featured Service" ? copyData.imageUrl : "",        
        imageAltText: copyData.category.name == "Featured Service" ? copyData.imageAltText : "",
        image:
          copyData.imageUrl !== " " && copyData.category.name == "Featured Service"
            ? "Uploaded Image : " + copyData.imageUrl
            : "",
        expiryDateUIFormat: this.formatToUIDateType(copyData.expires),
        isPriceFieldDisplayed2: this.checkPriceFieldDisplay(
          copyData.priceHeader2,
          copyData.price2Required,
          copyData.price2Free,
          copyData.postPrice2,
          copyData.forcePrice2,
          copyData.priceOverride2
        ),
        isPriceFieldDisplayed3: this.checkPriceFieldDisplay(
          copyData.priceHeader3,
          copyData.price3Required,
          copyData.price3Free,
          copyData.postPrice3,
          copyData.forcePrice3,
          copyData.priceOverride3
        ),
        isPriceFieldDisplayed4: this.checkPriceFieldDisplay(
          copyData.priceHeader4,
          copyData.price4Required,
          copyData.price4Free,
          copyData.postPrice4,
          copyData.forcePrice4,
          copyData.priceOverride4
        ),
        envIndi_id:
          copyData.environmental === null
            ? this.state.envIndi_id
            : copyData.environmental.id,
        envIndi_value:
          copyData.environmental === null
            ? this.state.envIndi_value
            : copyData.environmental.indicator,
        selectedPrograms: copyData.programs,
      },
      this.handlePriceFieldDisplay
    );
    this.props.copyCouponData({});
  };
  handlePriceFieldDisplay() {
    if (this.state.isPriceFieldDisplayed4 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: true,
        isPriceFieldDisplayed4: true,
        count_PriceFieldDisplayed: 4,
      });
    } else if (this.state.isPriceFieldDisplayed3 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: true,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 3,
      });
    } else if (this.state.isPriceFieldDisplayed2 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: false,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 2,
      });
    } else {
      this.setState({
        isPriceFieldDisplayed2: false,
        isPriceFieldDisplayed3: false,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 1,
      });
    }
  }
  checkPriceFieldDisplay(
    priceHeader,
    priceRequired,
    priceFree,
    postPrice,
    forcePrice,
    priceOverride
  ) {
    if (
      priceHeader.trim() !== "" ||
      priceRequired.trim() !== "" ||
      priceFree.trim() !== "" ||
      postPrice.trim() !== "" ||
      forcePrice.trim() !== "" ||
      priceOverride.trim() !== ""
    ) {
      this.state.count_PriceFieldDisplayed =
        this.state.count_PriceFieldDisplayed + 1;
      return true;
    } else {
      return false;
    }
  }
  checkPriceField(
    priceHeader,
    priceRequired,
    priceFree,
    postPrice,
    forcePrice,
    priceOverride
  ) {
    if (
      priceHeader.trim() !== "" ||
      priceRequired.trim() !== "" ||
      priceFree.trim() !== "" ||
      postPrice.trim() !== "" ||
      forcePrice.trim() !== "" ||
      priceOverride.trim() !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  //returns the date in yyyy-mm-dd format
  formatToUIDateType = (date) => {
    if (date.trim() !== "" && date !== "null") {
      //mm/dd/yyyy
      let dateValues = date.split("/");
      //yyyy-mm-dd
      return dateValues[2] + "-" + dateValues[0] + "-" + dateValues[1];
    } else {
      //Will return the last date of the month
      let today = new Date();
      let lastDateOfTheMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      // month + 1 is to denote the current month as the getMonth() will provide index starts from 0
      let currentMonth =
        today.getMonth() + 1 > 9
          ? today.getMonth() + 1
          : "0" + (today.getMonth() + 1);
      let lastDate =
        lastDateOfTheMonth.getDate() > 9
          ? lastDateOfTheMonth.getDate()
          : "0" + lastDateOfTheMonth.getDate();
      //yyyy-mm-dd
      return today.getFullYear() + "-" + currentMonth + "-" + lastDate;
    }
  };

  imageAltLimit() {
    document.getElementById("alt-div").classList.remove("hide");
  }

  hideAltLimit() {
    document.getElementById("alt-div").classList.add("hide");
  }


  render() {
    
    return (
      <form className="form-style">
        {/* <div style={{marginBottom:"10px"}}>
        <span className= "create-coupon"> CREATE COUPON</span>
          </div> */}
        <div>
          <div className="form-row">
            <div className="form-column">
              <span className="field-header">
                ID<sup style={{ color: "red" }}>*</sup>
              </span>
              <br />
              <input
                id="couponId-id"
                className="coupon-id-style"
                maxLength="4"
                name="couponId"
                onChange={this.handleCouponIDChange}
                value={this.state.couponID}
                required
              ></input>
            </div>
            <div className="form-column">
              <span className="field-header">
                SORT ORDER<sup style={{ color: "red" }}>*</sup>
              </span>
              <br />
              <input
                id="id-sort-order"
                pattern="^[0-9][0-9]*$"
                className="sort-order"
                data-tip="Tip: Accepts only positive integer number"
                maxLength="11"
                name="sortOrder"
                value={this.state.sortOrder}
                onChange={this.handlesortOrder}
                required
              ></input>
            </div>
            <div className="form-column">
              <span className="name">EXPIRE DATE</span>
              <br />
              <input
                type="date"
                className="expire-date"
                id="expiry-date"
                name="expiry-date"
                onChange={this.handleExpiryDate}
                value={this.state.expiryDateUIFormat}
              ></input>
            </div>
            <div className="form-column">
              <span className="field-header">PROGRAMS</span>
              <br />
              <MultiSelectDropDown
                optionData={this.state.programsData}
                selectedCheckBoxes={this.handleProgramsChange}
                filter="category_filter"
              ></MultiSelectDropDown>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <span className="field-header">COUNTRY</span>
              <br />
              <select
                className="rectangle-copy"
                name="country"
                id="id-country"
                value={this.decodedTokenData.market || this.state.countryCode}
                onChange={this.handleCountryChange}
              >
                {this.state.countries.map((country) => {
                  return (
                    <option
                      className="lt"
                      value={country.code}
                      key={country.code}
                      disabled={this.decodedTokenData.market === '' ? false : this.decodedTokenData.market !== country.code ? true : false}
                    >
                      {country.countryName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-column">
              <span className="field-header">LANGUAGE</span>
              <br />
              <select
                className="rectangle-copy"
                name="language"
                id="id-language"
                value={this.state.languageCode}
                onChange={this.handleLanguageChange}
              >
                {this.state.languages.map((language) => {
                  return (
                    <option value={language.code} key={language.code}>
                      {language.language}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-column">
              <span className="field-header">
                CATEGORY<sup style={{ color: "red" }}>*</sup>
              </span>
              <br />
              <select
                className="rectangle-copy"
                id="id-category"
                key={this.state.categoryID}
                value={this.state.categoryID}
                onChange={this.handleCategoryChange}
              >
                {this.state.categories.map((category) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-column mg-tp-m12">
              <span className="price-header">
                ENVIRONMENTAL
                <br />
                INDICATOR
              </span>
              <br />
              <select
                className="rectangle-copy"
                id="id-environmental_indicator"
                key={this.state.envIndi_id}
                value={this.state.envIndi_id}
                onChange={this.handleEnvironmentalIndicatorChange}
              >
                {this.state.environmental_indicators_list.map((env) => {
                  return (
                    <option value={env.id} key={env.id}>
                      {env.id === 0 ? "Select" : env.indicator}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <span className="name">
                NAME<sup style={{ color: "red" }}>*</sup>
              </span>
              <br />
              <input
                className="inputfield"
                id="id-name"
                name="Name"
                onChange={this.handleNameChange}
                value={this.state.name}
                required
              ></input>
            </div>
            <div className="form-column">
              <span className="field-header">HEADER</span>
              <br />
              <input
                className="inputfield"
                id="id-header"
                name="Header"
                onChange={this.handleHeaderChange}
                value={this.state.header}
              ></input>
            </div>
          </div>
          <div>
            <div className="price-field-header-wrapper">
              <span className="price-field-header">PRICING FIELDS</span>
              <AddCircleOutlineOutlinedIcon
                className={
                  this.state.count_PriceFieldDisplayed === 4
                    ? "add-icon-disable"
                    : "add-icon-enable"
                }
                onClick={this.handlePriceFieldAdd}
              />
              <RemoveCircleOutlineOutlinedIcon
                className={
                  this.state.count_PriceFieldDisplayed === 1
                    ? "remove-icon-disable"
                    : "remove-icon-enable"
                }
                onClick={this.handlePriceFieldSub}
              />
            </div>
            {this.state.isPriceFieldDisplayed1 && (
              <PriceHeaderComponent
                value="1"
                priceHeaderProp={this.handlePriceHeaderChange}
                priceRequiredProp={this.handlePriceRequiredChange}
                defaultFreeProp={this.handleDefaultFreeChange}
                postPriceProp={this.handlePostPriceChange}
                priceOverrideCheckboxProp={
                  this.handlePriceOverrideCheckboxChange
                }
                priceOverrideProps={this.handlePriceOverrideChange}
                priceValidationFlagProps={this.handlePriceValidationFlag}
                priceHeaderValue={this.state.priceHeader_1}
                priceRequiredValue={this.state.priceRequired_1}
                priceFreeValue={this.state.defaultFree_1}
                postPriceValue={this.state.postPrice_1}
                priceOverride={this.state.priceOverrideCheckbox_1}
                priceOverrideValue={this.state.priceOverride_1}
              />
            )}
            {this.state.isPriceFieldDisplayed2 && (
              <PriceHeaderComponent
                value="2"
                priceHeaderProp={this.handlePriceHeaderChange}
                priceRequiredProp={this.handlePriceRequiredChange}
                defaultFreeProp={this.handleDefaultFreeChange}
                postPriceProp={this.handlePostPriceChange}
                priceOverrideCheckboxProp={
                  this.handlePriceOverrideCheckboxChange
                }
                priceOverrideProps={this.handlePriceOverrideChange}
                priceValidationFlagProps={this.handlePriceValidationFlag}
                priceHeaderValue={this.state.priceHeader_2}
                priceRequiredValue={this.state.priceRequired_2}
                priceFreeValue={this.state.defaultFree_2}
                postPriceValue={this.state.postPrice_2}
                priceOverride={this.state.priceOverrideCheckbox_2}
                priceOverrideValue={this.state.priceOverride_2}
              />
            )}
            {this.state.isPriceFieldDisplayed3 && (
              <PriceHeaderComponent
                value="3"
                priceHeaderProp={this.handlePriceHeaderChange}
                priceRequiredProp={this.handlePriceRequiredChange}
                defaultFreeProp={this.handleDefaultFreeChange}
                postPriceProp={this.handlePostPriceChange}
                priceOverrideCheckboxProp={
                  this.handlePriceOverrideCheckboxChange
                }
                priceOverrideProps={this.handlePriceOverrideChange}
                priceValidationFlagProps={this.handlePriceValidationFlag}
                priceHeaderValue={this.state.priceHeader_3}
                priceRequiredValue={this.state.priceRequired_3}
                priceFreeValue={this.state.defaultFree_3}
                postPriceValue={this.state.postPrice_3}
                priceOverride={this.state.priceOverrideCheckbox_3}
                priceOverrideValue={this.state.priceOverride_3}
              />
            )}
            {this.state.isPriceFieldDisplayed4 && (
              <PriceHeaderComponent
                value="4"
                priceHeaderProp={this.handlePriceHeaderChange}
                priceRequiredProp={this.handlePriceRequiredChange}
                defaultFreeProp={this.handleDefaultFreeChange}
                postPriceProp={this.handlePostPriceChange}
                priceOverrideCheckboxProp={
                  this.handlePriceOverrideCheckboxChange
                }
                priceOverrideProps={this.handlePriceOverrideChange}
                priceValidationFlagProps={this.handlePriceValidationFlag}
                priceHeaderValue={this.state.priceHeader_4}
                priceRequiredValue={this.state.priceRequired_4}
                priceFreeValue={this.state.defaultFree_4}
                postPriceValue={this.state.postPrice_4}
                priceOverride={this.state.priceOverrideCheckbox_4}
                priceOverrideValue={this.state.priceOverride_4}
              />
            )}
          </div>
          <div className="form-row">
            <div className="form-column">
              <span className="field-header">
                INCLUDES<sup style={{ color: "red" }}>*</sup>
              </span>
              <br />
              <TextField
                id="id-include"
                multiline
                rowsMax="8"
                onChange={this.handleIncludeschange}
                value={this.state.includes}
                className="text-area"
                required
                variant="outlined"
              />
            </div>
          </div>
          <div className={this.state.categoryName == "Featured Service" ? "show-div" : "hide-div"}>
            <div className="form-row">
              <div className="form-column">
                <span className="field-header">IMAGE ALT TEXT</span>
                <br />
                <input
                  className="inputfield"
                  id="id-imageAlt"
                  name="image-alt"
                  maxLength="100"
                  onMouseOver={this.imageAltLimit}
                  onFocus={this.imageAltLimit}
                  onMouseOut={this.hideAltLimit}
                  onBlur={this.hideAltLimit}
                  onChange={this.handleImageAltText}
                  value={this.state.imageAltText}
                ></input>
                <div className="hide" id="alt-div">
                  This input field has a limit of 100 characters
                </div>
                <br></br>
              </div>
            </div>
            <div className="form-row" style={{ display: "flex" }}>
              <img
                src="./../upload_icon.png"
                height="20px"
                alt="upload icon"
                onClick={() => this.fileInput.click()}
              />

              <div className="form-column" style={{ marginTop: "0px" }}>
                <div className="upload-btn-wrapper">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="image-upload"
                    onChange={this.handleUploadImage}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                  />
                  <span
                    className="btn"
                    id="id-upload-image"
                    style={{ padding: 0, textAlign: "left" }}
                    onClick={() => this.fileInput.click()}
                  >
                    UPLOAD IMAGE
                  </span>
                </div>
              </div>
            
              <div>
                <span className="field-header">{this.state.image}</span>
              </div>
            </div>
          </div>      
          <div className="form-row">
            <div className="form-column">
              <button
                className="register-now"
                id="id-save-button"
                value="save"
                onClick={this.createCouponSubmitButton}
              >
                SAVE
              </button>
            </div>
            <div className="form-column">
              <button
                className="sign-in-register"
                id="id-cancel-button"
                value="CANCEL"
                onClick={() => this.props.setCreateCouponModalClose(true)}
              >
                CANCEL
              </button>
            </div>
          </div>
          <div className="form-row">
            <div style={{ color: "red" }}>{this.state.invalidAttempt}</div>
          </div>
        </div>
        <ReactTooltip />
      </form>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.createCouponModalReducer,
  };
  return {
    copyData: state.copyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    copyCouponData: (val) =>
      dispatch({
        type: "COPY_COUPON_DATA",
        data: val,
      }),
    setCreateCouponModalClose: (val) =>
      dispatch({
        type: "CREATE_COUPON_MODAL_STATUS",
        data: val,
      }),
    passRefreshCouponTable: (val) =>
      dispatch({
        type: "SET_REFRESH_COUPON_TABLE_STATUS",
        data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCouponForm);
