import React, { Component } from "react";
import Pagination from "react-js-pagination";
import {connect} from "react-redux";
import './../couponsTab/paginationStyle.css';

export class DealerPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            entries:[]
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    componentWillReceiveProps(nextprops){
        this.setState({activePage:nextprops.Dealer_PageNo});
    }

    handlePageChange(pageNumber) {
        if(pageNumber!==this.state.activePage){
        this.setState({activePage: pageNumber});
        this.props.sendPageNo(pageNumber);
         }
    }

    render() {
        const numOfEntries=this.props.Dealer_NumberOfEntriesPerPage;
        return (<div className="pagination-container">
        <Pagination activePage={this.state.activePage}  hideFirstLastPages={false}
                totalItemsCount={this.props.Dealer_TableEntries.length}
                itemsCountPerPage={numOfEntries}
                pageRangeDisplayed={(((this.props.Dealer_TableEntries.length/numOfEntries)+1)<4) ? (this.props.Dealer_TableEntries.length/numOfEntries)+1: 4 }
                prevPageText="BACK"
                nextPageText="NEXT"
                onChange={this.handlePageChange}
                //default classes
                activeClass="page-link-active-li-container"
                linkClass="page-link-anchor-container"
                itemClass="page-link-li-container"
                activeLinkClass="page-link-active-anchor-container"
                //for back button
                itemClassPrev="page-li-prev"
                linkClassPrev="page-anchor-prev"
                //for next button
                itemClassNext="page-li-next"
                linkClassNext="page-anchor-next"
                style={{'padding-left':'120px'}}
            />
        </div>);
    };
}

const mapStateToProps=(states) => {
    const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
    return {
        Dealer_TableEntries: state.dealer_rows,
        Dealer_NumberOfEntriesPerPage: state.entriesPerPage,
        Dealer_PageNo:state.Dealer_PageNo
    };
};

const mapDispatchToProps=dispatch =>{
    return{
        sendPageNo: (val) => dispatch({
            type: "DEALER_SEND_PAGENUMBER",
            Dealer_Data: val
        })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DealerPagination);