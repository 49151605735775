import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import history from '../history';
import {connect} from 'react-redux';
import './NavBar.css';

// pageLoadedFromNavBar returns whether page was loaded on navbar link click or from back button click
class  NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:0,
      activeTabFromUrl:"",
      pageLoadedFromNavbar:false
    };
  }

  componentWillMount() {
    // logic for selecting corresponding navbar tab when back button is clicked to go to another page
    window.onpopstate = ()=> {
      this.setState({pageLoadedFromNavbar:false});
      let urlPath = window.location.pathname;
      let currentUrl = urlPath.split('/');
      currentUrl=currentUrl[1]+"/"+currentUrl[2];
      this.setState({activeTabFromUrl:currentUrl});
    };
  }
  render(){
    //activeTabFromNavbar contains the key of the tab to be set when the corresponding Navbar link is clicked
    let activeTabFromNavbar=this.props.currentTab;
    let urlPath = window.location.pathname;
    let currentUrl = urlPath.split('/');
    currentUrl=currentUrl[1]+"/"+currentUrl[2];
    if(currentUrl==='')
    {
      currentUrl="manageCoupons/coupons";
    }
  return (
    <div className="navbar-container">
      <Tabs defaultActiveKey={(this.state.activeTabFromUrl!=="")?this.state.activeTabFromUrl:currentUrl}
          activeKey={(this.state.activeTabFromUrl!=="")?[this.state.pageLoadedFromNavbar?activeTabFromNavbar:this.state.activeTabFromUrl]:[currentUrl]}
          transition={false}
          onSelect={key =>{
          history.push("/"+key);
          this.props.sendTab(key);
          this.setState({pageLoadedFromNavbar:true});
        }}
        variant="tabs"
      >
        <Tab eventKey="manageCoupons/coupons" title="COUPONS"></Tab>
        <Tab eventKey="manageCoupons/dealers" title="DEALERS"></Tab>
        <Tab eventKey="manageCoupons/categories" title="CATEGORIES"></Tab>
        <Tab eventKey="manageCoupons/controlCenter" title="SCHEDULER"></Tab>
      </Tabs>
    </div>
  );
}
}

const mapStateToProps=(states) => {
  const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
  return {
    currentTab: state.tab
  };
};

const mapDispatchToProps=dispatch =>{
  return{
      sendTab: (val) => dispatch({
          type: "SEND_TAB",
          data: val
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
