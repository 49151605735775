import React, { Component } from "react";
import VerticalTabs from "./VerticalTabs";
import { getEventListByMonths } from "./util";
import "./ScheduleConfirmation.css";
export default class ScheduleConfirmation extends Component {
  render() {
    return (
      <div className="scheduleConfirmation">
        <h4 className="scheduleConfirmationHeader">Scheduled Coupon Details</h4>
        {this.props.eventList.length > 0 ?
          (<VerticalTabs
            eventList={getEventListByMonths(this.props.eventList)}
          />)
          :
          (
          this.props.isSearchEnabled &&
          (
            <div className="alert alert-danger" role="alert">
            Unable to find scheduled coupons, please try again with a different search
            criteria
          </div>
          )
          )
        }
      </div>
    );
  }
}
