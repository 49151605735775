import React, { Component } from "react";
import Axios from "../../service";
import "./css/scheduler.css";
import Cookies from "js-cookie";

export default class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchedulerType: "ALL",
      selectedSchedulerName: "ALL",
      schedulerStatus: false,
      selectedFile: null,
      schedulerDetails: {},
      schedulerTypesToBeDisabled: [],
      isRunning: false,
      runInterval: null,
      loaded: 0,
    };
  }

  componentDidMount() {
    this.setState({
      runInterval: setInterval(() => this.props.getSchedulerDetails(), 10000),
    });
  }

  componentWillUnmount(){
    clearInterval(this.state.runInterval)
  }

  changeSchedulerName = (e) => {
    let value = e.target.value;
    this.props.getData(value, this.state.selectedSchedulerType);
    this.props.getSchedulerDetails();
    this.setState({
      selectedSchedulerName: value,
    });
    if (
      Object.keys(this.props.schedulerDetails).length > 0 &&
      typeof this.props.schedulerDetails[
        value + "-" + this.state.selectedSchedulerType
      ] !== "undefined"
    ) {
      this.setState({
        schedulerStatus:
          this.props.schedulerDetails[
            value + "-" + this.state.selectedSchedulerType
          ].enabled,
      });
    }
  };

  changeSchedulerType = (e) => {
    let value = e.target.value;
    this.props.getData(this.state.selectedSchedulerName, value);
    this.props.getSchedulerDetails();
    this.setState({
      selectedSchedulerType: value,
    });
    if (
      Object.keys(this.props.schedulerDetails).length > 0 &&
      typeof this.props.schedulerDetails[
        this.state.selectedSchedulerName + "-" + value
      ] !== "undefined"
    ) {
      this.setState({
        schedulerStatus:
          this.props.schedulerDetails[
            this.state.selectedSchedulerName + "-" + value
          ].enabled,
      });
    }
  };

  changeSchedulerStatus = (url) => {
    Axios
      .post(url, { }, {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
      .then(() => {
        this.setState({
          schedulerStatus: !this.state.schedulerStatus,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  retrigger = (url) => {
    this.state.isRunning = true;
    Axios
      .post(url,{}, {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
      .then(() => {
        console.log("triggered successfully");
        this.state.isRunning = false;
      })
      .catch((err) => {
        console.log("triggered failed");
        this.state.isRunning = false;
        console.log(err);
      });
  };

  getRetriggerButton = (url) => {
    if (
      this.props.schedulerDetails[
        this.state.selectedSchedulerName +
          "-" +
          this.state.selectedSchedulerType
      ].enabled
    ) {
      return (
        <button
          id="btn1"
          type="button"
          className={`ml-3 btn btn-primary btn-sm sch-button`}
          onClick={() => this.retrigger(url)}
        >
          Retrigger
        </button>
      );
    } else {
      return (
        <button
          id="btn2"
          type="button"
          className={`ml-3 btn btn-secondary btn-sm sch-button`}
          disabled
        >
          Retrigger
        </button>
      );
    }
  };

  getRunningSpinner = () => {
    return (
      <button
        id="btn3"
        class="ml-3 btn btn-primary sch-button"
        type="button"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        >
          {" "}
        </span>
        &nbsp; Execution is in progress...
      </button>
    );
  };

  handleselectedFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  handleUpload = (url) => {
    this.state.isRunning = true;
    const data = new FormData();
    data.append(
      "feedFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    Axios
      .post(
        url,
        data,
        {
          headers: {
            Authorization: Cookies.get("token"),
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            this.setState({
              loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            });
          },
        }
      )
      .then(() => {
        this.state.isRunning = false;
      })
      .catch((err) => {
        this.state.isRunning = false;
        console.log(err);
      });
  };

  getUploader = () => {
    if (
      this.props.schedulerDetails[
        this.state.selectedSchedulerName +
          "-" +
          this.state.selectedSchedulerType
      ].status === "IDLE" &&
      !this.state.isRunning
    ) {
      return (
        <div id="upload-div" style={{ display: "inline" }}>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => this.handleselectedFile(e)}
            className="ml-3"
          />
          <button
            onClick={() =>
              this.handleUpload(
                this.props.schedulerDetails[
                  this.state.selectedSchedulerName +
                    "-" +
                    this.state.selectedSchedulerType
                ].retriggerURL
              )
            }
            className="btn btn-primary sch-button"
          >
            Upload
          </button>
        </div>
      );
    } else {
      return this.getRunningSpinner();
    }
  };

  getOperations = () => {
    if (
      this.state.selectedSchedulerName !== "ALL" &&
      this.state.selectedSchedulerType !== "ALL"
    ) {
      if (
        typeof this.props.schedulerDetails[
          this.state.selectedSchedulerName +
            "-" +
            this.state.selectedSchedulerType
        ] !== "undefined" &&
        this.props.schedulerDetails[
          this.state.selectedSchedulerName +
            "-" +
            this.state.selectedSchedulerType
        ].type === "SCHEDULER"
      ) {
        return this.getSchedulerOperations();
      } else if (
        typeof this.props.schedulerDetails[
          this.state.selectedSchedulerName +
            "-" +
            this.state.selectedSchedulerType
        ] !== "undefined" &&
        this.props.schedulerDetails[
          this.state.selectedSchedulerName +
            "-" +
            this.state.selectedSchedulerType
        ].type === "UPLOADER"
      ) {
        return this.getUploaderOperations();
      }
    } else {
      return null;
    }
  };

  getUploaderOperations = () => {
    return this.getUploader();
  };

  getSchedulerStatus = () => {
    return (
      <div className="ml-3" style={{ display: "inline" }}>
        Status
        <label className="switch ml-3">
          <input
            type="checkbox"
            defaultChecked={
              this.props.schedulerDetails[
                this.state.selectedSchedulerName +
                  "-" +
                  this.state.selectedSchedulerType
              ].enabled
            }
            onChange={() =>
              this.changeSchedulerStatus(
                this.props.schedulerDetails[
                  this.state.selectedSchedulerName +
                    "-" +
                    this.state.selectedSchedulerType
                ].toggleStatusURL
              )
            }
          />
          <span className="slider round" />
        </label>
      </div>
    );
  };

  getSchedulerOperations = () => {
    let triggerButton = "";
    if (
      this.props.schedulerDetails[
        this.state.selectedSchedulerName +
          "-" +
          this.state.selectedSchedulerType
      ].status === "IDLE" &&
      !this.state.isRunning
    ) {
      triggerButton = this.getRetriggerButton(
        this.props.schedulerDetails[
          this.state.selectedSchedulerName +
            "-" +
            this.state.selectedSchedulerType
        ].retriggerURL
      );
    } else {
      triggerButton = this.getRunningSpinner();
    }
    return (
      <div id="scheduler-operations" style={{ display: "inline" }}>
        {this.getSchedulerStatus()}
        {triggerButton}
      </div>
    );
  };

  render() {
    let schNames = this.props.schNames;
    let schNameRender = schNames.map((item) => {
      return <option value={item}>{item}</option>;
    });
    let schTypes = this.props.schTypes;
    let schTypeRender = schTypes.map((item) => {
      return <option value={item}>{item}</option>;
    });
    return (
      <div className="row  mt-3 mb-3">
        <div className="col-12 align-items-left pl-10 input-field-scheduler">
          <div className="ml-3" style={{ display: "contents" }}>
            <span className="input-field-text">Scheduler</span>
            <select
              id="scheduler-select"
              className="form-control input-field-select"
              // style={{ width: "20%", display: "inline" }}
              onChange={this.changeSchedulerName}
            >
              {schNameRender}
            </select>
          </div>
          <div
            id="type-div-for-ut"
            className="ml-3"
            style={{ display: "contents" }}
            onChange={this.changeSchedulerType}
          >
            <span className="input-field-text">Type</span>
            <select
              className="form-control input-field-select"
              // style={{ width: "20%", display: "inline" }}
            >
              {schTypeRender}
            </select>
          </div>
          {this.getOperations()}
          <button
            type="button"
            className={`input-field-button btn btn-primary btn-sm sch-button`}
            onClick={() =>
              this.props.getData(
                this.state.selectedSchedulerName,
                this.state.selectedSchedulerType
              )
            }
          >
            Refresh
          </button>
        </div>
      </div>
    );
  }
}
