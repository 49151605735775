import React from 'react'
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

import { Link } from 'react-router-dom';
import Home from '@material-ui/icons/Home';
import Avatar from 'react-avatar';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ProfileIcon = () => {
    let decodeData = jwt.decode (Cookies.get("token"));
    let urlPath = window.location.pathname;
    console.log(urlPath);
    const displayHomeIcon = () => {
        if(urlPath === "/manageCoupons/userdetail"){
            return (
                <>
                    <div className="home-button">
                        <Link to="/manageCoupons/coupons">
                            <OverlayTrigger
                            key="home-page"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-"right"`}>
                                <div>Home Page</div>
                                </Tooltip>
                            }
                            
                            >
                            <Home/>
                            </OverlayTrigger>
                                    
                        </Link>
                    </div>

                    <div id="persistent-header-partition">|</div>  
                </>
            )
        }
    }
    return (
        <>
            {displayHomeIcon()}
            
            <div id="greet">
            <Link to="/manageCoupons/userdetail">
                <OverlayTrigger
                key="profile"
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-"right"`}>
                    <div>{decodeData.first_name}</div>
                    </Tooltip>
                }
                >
                <Avatar name={decodeData.first_name} size="30" round={true} />
                </OverlayTrigger>
                
            </Link>
            </div>
            <div id="persistent-header-partition">|</div>      
        </>
    )
}

export default ProfileIcon
