const dashboardFilterState = {
    dashboardFilter: {
        type:"",
        value:""
    },
  };

  const dashboardReducer = (state = dashboardFilterState, action) => {
    if (action.type === "APPLY_DASHBOARD_FILTER") {
      return {
        ...state,
        dashboardFilter: action.data,
      };
    }
    return state;
  };
  export default dashboardReducer;