import React,{Component} from 'react';
import CreateCouponForm from './createCouponForm';

export default class CreateCoupon extends Component {
render(){
  return (
    <div style={{overflowX:"auto"}}>
     <CreateCouponForm ></CreateCouponForm>
    </div>
  );
    }
}