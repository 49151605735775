import Cookies from "js-cookie";
import React, { Component } from "react";
import Axios from "../../service";
import "./css/scheduler.css";
import InputField from "./input-fields";
import SchedulerHistory from "./schedulerHistory";

export default class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schNames: [],
      schTypes: [],
      data: [],
      schedulerDetails: {},
      schedulerType: "ALL",
      schedulerName: "ALL",
      isLoading: false,
      schedulerTypesToBeDisabled: [],
    };
  }
  componentDidMount() {
    this.getData(this.state.schedulerName, this.state.schedulerType);
    this.getSchedulerDetails();
  }

  getData = (schedulerName, schedulerType) => {
    this.setState({
      data: [],
      isLoading: true,
    });
    Axios.post(
      "/manageCoupons/api/scheduler/history",
      {
        schedulerName: schedulerName,
        schedulerType: schedulerType,
      },
      {
        headers: {
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then((res) => {
        this.setState({
          schNames: res.data.schedulerNames,
          schTypes: res.data.schedulerTypes,
          data: res.data.schedulerStatus,
          schedulerName: schedulerName,
          schedulerType: schedulerType,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          schedulerName: schedulerName,
          schedulerType: schedulerType,
          isLoading: false,
        });
      });
  };

  getSchedulerDetails = () => {
    Axios.get("/manageCoupons/api/schedulerDetails", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((res) => {
        this.setState({ schedulerDetails: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="container-fluid scheduler">
        <InputField
          getData={this.getData}
          schNames={this.state.schNames}
          schTypes={this.state.schTypes}
          schedulerDetails={this.state.schedulerDetails}
          getSchedulerDetails={this.getSchedulerDetails}
        />
        <SchedulerHistory
          data={data}
          schedulerName={this.state.schedulerName}
          schedulerType={this.state.schedulerType}
          schedulerDetails={this.state.schedulerDetails}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}
