import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateFilter.css";
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';

export class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      setOpen: false,
      data: { startDate: "", endDate: "" },
      startRange: null,
      endRange: null,
    };
  }
  handleClickOutside = (evt) => {
    this.setState({ open: false, data: this.props.data });
  };
  toggle = () => {
    this.setState({ setOpen: !this.state.open, open: !this.state.open });
    if (this.state.open) {
      //set start date and end date for date
      this.setState({
        data: this.props.data,
        startRange: this.props.startRange,
        endRange: this.props.endRange,
      });
    }
  };
  componentWillReceiveProps(nextprops) {
    this.setState({
      data: nextprops.data,
      startRange: nextprops.startRange,
      endRange: nextprops.endRange,
    });
  }
  setStartDate(date) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ["startDate"]: date,
      },
    }));
  }
  setEndDate(date) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ["endDate"]: date,
      },
    }));
  }

  handleDateSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    if(this.state.data.startDate!==this.props.data.startDate || this.state.data.endDate!==this.props.data.endDate){
      this.props.onDateFilterselected(this.state.data);
    }
    this.toggle(!this.state.open);
  };

  render() {
    return (
      <div>
        <div className="date-filter">
          {
            (this.props.data.startDate!=null || this.props.data.endDate!=null)?
            <EventAvailableOutlinedIcon
            id="date-filter-icon"
            onKeyPress={() => this.toggle(!this.state.open)}
            onClick={() => this.toggle(!this.state.open)}
            tabIndex="0"
          />
            :
            <EventBusyOutlinedIcon 
            id="date-filter-icon"
            onKeyPress={() => this.toggle(!this.state.open)}
            onClick={() => this.toggle(!this.state.open)}
            tabIndex="0"
          />
          }
        </div>
        {this.state.open && (
          <div className="date-filter-popup-container">
            <form onSubmit={this.handleDateSubmit} autoComplete="off">
              <label>FROM:</label><br/>
              <DatePicker
                id="date-filter-From"
                selected={this.state.data.startDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => this.setStartDate(date)}
                selectsStart
                startDate={this.state.data.startDate}
                endDate={this.state.data.endDate}
                minDate={this.state.startRange}
                maxDate={
                  this.state.data.endDate
                    ? this.state.data.endDate
                    : this.state.endRange
                }
                className="date-filterText"
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                isClearable
                placeholderText="MM/DD/YYYY"
              /><br/>
              <label>TO:</label><br/>
              <DatePicker
                id="date-filter-To"
                selected={this.state.data.endDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => this.setEndDate(date)}
                selectsEnd
                startDate={this.state.data.startDate}
                endDate={this.state.data.endDate}
                minDate={
                  this.state.data.startDate
                    ? this.state.data.startDate
                    : this.state.startRange
                }
                maxDate={this.state.endRange}
                className="date-filterText"
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                isClearable
                placeholderText="MM/DD/YYYY"
              />
              <div className="date-filter-setbutton-container">
                <button id="Date-filter--apply" type="submit" className="b">
                  SET
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(DateFilter);
