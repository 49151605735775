import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./css/Table.css";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import Table from "react-bootstrap/Table";
import activeCheckmark from "./icons/active-true.svg";
import TableColumnFilter from "../common/tableColumnFilter";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChangeLogModal from "./ChangeLogModal";
import CouponPreviewModal from "./CouponPreviewModal";

export class CouponsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: {
        columns: [
          "published",
          "id",
          "couponName",
          "header",
          "priceHeader1",
          "category",
          "active",
          "edit",
          "preview"
        ],
        headers: [
          "published",
          "ID",
          "Name",
          "Header",
          "Price",
          "Category",
          "Active",
          "Actions",
          "Preview"
        ],
      },
      rows: [],
      filterValues: { text: undefined, country: "US", language: "EN" },
      selectedRow: null,
      pageNum: 1,
      currentColor: "",
      isAnyRowSelected: false,
      sortedFlag: false,
      activeColumnFilterData: {},
      publishColumnFilterData: {},
      categoryColumnFilterData: {},
      activeFilterStatus: false,
      publishFilterStatus: false,
      categoryFilterStatus: false,
      resetActiveFilter: undefined,
      resetPublishFilter: undefined,
      resetCategoryFilter: undefined,
      filterArray: [],
      couponId_Selected: "",
      createModalIsOpen: false,
      couponPreviewModalIsOpen: false,
    };
    this.handleSelectedCheckboxesForActive = this.handleSelectedCheckboxesForActive.bind(
      this
    );
    this.handleSelectedCheckboxesForPublish = this.handleSelectedCheckboxesForPublish.bind(
      this
    );
    this.handleSelectedCheckboxesForCategory = this.handleSelectedCheckboxesForCategory.bind(
      this
    );
  }
  //returns true if value of atleast any 1 filter has changed
  compareFilterObj(obj1, obj2) {
    return (
      obj1.country !== obj2.country ||
      obj1.language !== obj2.language ||
      obj1.text !== obj2.text
    );
  }
  handledashboardFilter(type, value){

    if(type==="Category"){
      let categoryData= this.state.categoryColumnFilterData;
      categoryData[value]=true;
      this.handleSelectedCheckboxesForCategory(categoryData);
    }
    if(type==="Active/Inactive"){
      let ActiveData= this.state.activeColumnFilterData;
      ActiveData[value]=true;
      this.handleSelectedCheckboxesForActive(ActiveData);
    }
    if(type==="Publish/Unpublish"){
      let PublishData= this.state.publishColumnFilterData;
      PublishData[value]=true;
      this.handleSelectedCheckboxesForPublish(PublishData);
    }
  }
  componentWillReceiveProps(nextprops) {
    //Sort logic for sorting acc to lastUpdate
    //new object used since prop must not be mutated(pass by reference)
    let sortedTableRows = Object.assign([], nextprops.tableRows);
    if(nextprops.dashboardFilter.type!="" && nextprops.dashboardFilter.type!="Programs" && nextprops.dashboardFilter.type!="Environmental"){
        let dashboardFiltertype= nextprops.dashboardFilter.type;
        let dashboardFiltervalue= nextprops.dashboardFilter.value;
        this.props.applyDashboardFilter({type:"",value:""});
        this.handledashboardFilter(dashboardFiltertype,dashboardFiltervalue);
    }
    if(nextprops.isResetFilterStatus){
      this.props.setResetFilterStatus(false);
      this.resetAll(["date","country","language","category","active","publish"],[],this.props.tableRows_initial);
      this.props.passData(this.props.tableRows_initial);
      
    }
    if (!this.state.sortedFlag) {
      sortedTableRows.sort((a, b) => {
        return Date.parse(b.lastUpdate) - Date.parse(a.lastUpdate);
      });
    }
    if (
      nextprops.pageNo !== this.state.pageNum ||
      this.compareFilterObj(nextprops.FilterResults, this.state.filterValues)
    ) {
      if (this.state.selectedRow) {
        let row = document.getElementById(this.state.selectedRow);
        if (row) {
          row.style.backgroundColor = this.state.currentColor;
        }
      }
      this.state.pageNum = nextprops.pageNo;
      this.setState({});
    }
    //Pagination logic
    //if any filter has changed
    if (
      this.compareFilterObj(nextprops.FilterResults, this.state.filterValues)
    ) {
      let startIndex = 1;
      let val = nextprops.numberOfEntriesPerPage;
      this.state.filterValues = nextprops.FilterResults;
      this.state.rows = sortedTableRows.slice(
        (startIndex - 1) * val,
        startIndex * val
      );
    }
    //if no filter changed
    else {
      let startIndex = nextprops.pageNo;
      let val = nextprops.numberOfEntriesPerPage;
      this.state.rows = sortedTableRows.slice(
        (startIndex - 1) * val,
        startIndex * val
      );
    }
    this.state.resetActiveFilter = nextprops.resetActiveFilter;
    this.state.resetPublishFilter = nextprops.resetPublishFilter;
    this.state.resetCategoryFilter = nextprops.resetCategoryFilter;
    if (!nextprops.activeFilterStatus) {
      this.state.activeColumnFilterData = this.setCheckBoxFilterData(
        nextprops.activeColumnFilterData
      );
    }
    if (nextprops.resetActiveFilter) {
      this.state.activeColumnFilterData = this.setCheckBoxFilterData(
        nextprops.activeColumnFilterData
      );
      this.props.passResetActiveFilter(false);
    }

    if (!nextprops.publishFilterStatus) {
      this.state.publishColumnFilterData = this.setCheckBoxFilterData(
        nextprops.publishColumnFilterData
      );
    }
    if (nextprops.resetPublishFilter) {
      this.state.publishColumnFilterData = this.setCheckBoxFilterData(
        nextprops.publishColumnFilterData
      );
      this.props.passResetPublishFilter(false);
    }
    //Category Filter
    //set Category filter state to deafult
    if (!nextprops.categoryFilterStatus) {
      this.state.categoryColumnFilterData = this.setCheckBoxFilterData(
        nextprops.categoryColumnFilterData
      );
    }

    //set Category filter state to deafult  and reset to false
    if (nextprops.resetCategoryFilter) {
      this.state.categoryColumnFilterData = this.setCheckBoxFilterData(
        nextprops.categoryColumnFilterData
      );
      this.props.passResetCategoryFilter(false);
    }
    if (nextprops.resetAllFilter) {
      this.props.passFilterArray([]);
      this.props.passResetAll(false, []);
    }
    this.dateFilterConditionChecker(nextprops);
    this.countryFilterConditionChecker(nextprops);
    this.languageFilterConditionChecker(nextprops);
    this.setState({});
  }
  dateFilterConditionChecker(nextprops) {
    if (nextprops.dateFilterStatus === true && nextprops.dateFilterHandler) {
      this.handlefilters("date", nextprops.dateFilterData);
      this.props.setDateFilterHandlerCall(false);
      this.props.sendPageNo(1);
    }
    if (nextprops.dateFilterStatus === false && nextprops.dateFilterHandler) {
      this.props.setDateFilterHandlerCall(false);
      this.handleNegativeFilter("date");
      this.props.sendPageNo(1);
    }
  }
  countryFilterConditionChecker(nextprops) {
    if (nextprops.countryFilterStatus === true && nextprops.countryFilterHandler) {
      this.handlefilters("country", nextprops.countryFilterData);
      this.props.setCountryFilterHandlerCall(false);
      this.props.sendPageNo(1);
    }
    if (nextprops.countryFilterStatus === false && nextprops.countryFilterHandler) {
      this.props.setCountryFilterHandlerCall(false);
      this.handleNegativeFilter("country");
      this.props.sendPageNo(1);
    }
  }

  languageFilterConditionChecker(nextprops) {
    if (nextprops.languageFilterStatus === true && nextprops.languageFilterHandler) {
      this.handlefilters("language", nextprops.languageFilterData);
      this.props.setLanguageFilterHandlerCall(false);
      this.props.sendPageNo(1);
    }
    if (nextprops.languageFilterStatus === false && nextprops.languageFilterHandler) {
      this.props.setLanguageFilterHandlerCall(false);
      this.handleNegativeFilter("language");
      this.props.sendPageNo(1);
    }
  }

  handleSelectedCheckboxesForActive(arr) {
    this.setState({ activeColumnFilterData: arr });
    let selectedcheckboxes_active = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes_active.length !== 0) {
      this.handlefilters("active", selectedcheckboxes_active);
    } else {
      if (this.props.activeFilterStatus) {
        this.handleNegativeFilter("active");
      }
    }
    this.props.sendPageNo(1);
  }

  handleSelectedCheckboxesForPublish(arr) {
    this.setState({ publishColumnFilterData: arr });
    let selectedcheckboxes_publish = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes_publish.length !== 0) {
      this.handlefilters("publish", selectedcheckboxes_publish);
    } else {
      if (this.props.publishFilterStatus) {
        this.handleNegativeFilter("publish");
      }
    }
    this.props.sendPageNo(1);
  }

  handleSelectedCheckboxesForCategory(arr) {
    this.setState({ categoryColumnFilterData: arr });
    let selectedcheckboxes_category = this.fetchSelectedCheckbox(arr);
    if (selectedcheckboxes_category.length !== 0) {
      this.handlefilters("category", selectedcheckboxes_category);
    } else {
      if (this.props.categoryFilterStatus) {
        this.handleNegativeFilter("category");
      }
    }
    this.props.sendPageNo(1);
  }
  handleNegativeFilter(value) {
    if (value === "active") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "active"
      ) {
        this.props.passActiveFilterStatus(false);
        this.props.passData(this.props.activeColumnTableEntries);
        this.props.passFilterArray(
          this.props.filterArray.slice(0, this.props.filterArray.length - 1)
        );
      } else {
        this.props.passActiveFilterStatus(false);
        this.props.passData(this.props.activeColumnTableEntries);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.activeColumnTableEntries
        );
      }
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        this.props.activeColumnTableEntries
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        this.props.activeColumnTableEntries
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        this.props.activeColumnTableEntries
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        this.props.activeColumnTableEntries
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        this.props.activeColumnTableEntries
      );
    }
    if (value === "publish") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "publish"
      ) {
        this.props.passPublishFilterStatus(false);
        this.props.passData(this.props.publishColumnTableEntries);
        this.props.passFilterArray(
          this.props.filterArray.slice(0, this.props.filterArray.length - 1)
        );
      } else {
        this.props.passPublishFilterStatus(false);
        this.props.passData(this.props.publishColumnTableEntries);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.publishColumnTableEntries
        );
      }
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        this.props.publishColumnTableEntries
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        this.props.publishColumnTableEntries
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        this.props.publishColumnTableEntries
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        this.props.publishColumnTableEntries
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        this.props.publishColumnTableEntries
      );
    }
    if (value === "category") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "category"
      ) {
        this.props.passCategoryFilterStatus(false);
        this.props.passData(this.props.categoryColumnTableEntries);
        let splicedArray = this.props.filterArray.slice(
          0,
          this.props.filterArray.length - 1
        );
        this.props.passFilterArray(splicedArray);
      } else {
        this.props.passCategoryFilterStatus(false);
        this.props.passData(this.props.categoryColumnTableEntries);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.categoryColumnTableEntries
        );
      }
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        this.props.categoryColumnTableEntries
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        this.props.categoryColumnTableEntries
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        this.props.categoryColumnTableEntries
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        this.props.categoryColumnTableEntries
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        this.props.categoryColumnTableEntries
      );
    }
    if (value === "date") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "date"
      ) {
        this.props.passDateFilterStatus(false);
        this.props.passData(this.props.dateFilterTableData);
        let splicedArray = this.props.filterArray.slice(
          0,
          this.props.filterArray.length - 1
        );
        this.props.passFilterArray(splicedArray);
      } else {
        this.props.passDateFilterStatus(false);
        this.props.passData(this.props.dateFilterTableData);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.dateFilterTableData
        );
      }
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        this.props.dateFilterTableData
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        this.props.dateFilterTableData
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        this.props.dateFilterTableData
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        this.props.dateFilterTableData
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        this.props.dateFilterTableData
      );
    }
    if (value === "country") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "country"
      ) {
        this.props.passCountryFilterStatus(false);
        this.props.passData(this.props.countryFilterTableData);
        let splicedArray = this.props.filterArray.slice(
          0,
          this.props.filterArray.length - 1
        );
        this.props.passFilterArray(splicedArray);
      } else {
        this.props.passCountryFilterStatus(false);
        this.props.passData(this.props.countryFilterTableData);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.countryFilterTableData
        );
      }
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        this.props.countryFilterTableData
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        this.props.countryFilterTableData
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        this.props.countryFilterTableData
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        this.props.countryFilterTableData
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        this.props.countryFilterTableData
      );
    }
    if (value === "language") {
      if (
        this.props.filterArray[this.props.filterArray.length - 1] === "language"
      ) {
        this.props.passLanguageFilterStatus(false);
        this.props.passData(this.props.languageFilterTableData);
        let splicedArray = this.props.filterArray.slice(
          0,
          this.props.filterArray.length - 1
        );
        this.props.passFilterArray(splicedArray);
      } else {
        this.props.passLanguageFilterStatus(false);
        this.props.passData(this.props.languageFilterTableData);
        let index = this.props.filterArray.indexOf(value);
        this.resetAll(
          this.props.filterArray.slice(index, this.props.filterArray.length),
          this.props.filterArray.slice(0, index),
          this.props.languageFilterTableData
        );
      }
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        this.props.languageFilterTableData
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        this.props.languageFilterTableData
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        this.props.languageFilterTableData
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        this.props.languageFilterTableData
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        this.props.languageFilterTableData
      );
    }
  }

  handlefilters(value, selectedcheckboxes) {
    if (this.props.filterArray.includes(value)) {
      this.handleFiltersCalledPreviousAlso(value, selectedcheckboxes);
    } else {
      this.handleFirstTimeCalledFilters(value, selectedcheckboxes);
    }
  }
  handleFiltersCalledPreviousAlso(value, selectedcheckboxes) {
    if (
      value === "active" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "active"
    ) {
      const resultCoupons = this.setActiveData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
    } else if (
      value === "category" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "category"
    ) {
      const resultCoupons = this.setCategoryData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
    } else if (
      value === "date" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "date"
    ) {
      const resultCoupons = this.setDateData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
    } else if (
      value === "country" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "country"
    ) {
      const resultCoupons = this.setCountryData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
    } else if (
      value === "language" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "language"
    ) {
      const resultCoupons = this.setLanguageData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
    }
    else if (
      value === "publish" &&
      this.props.filterArray[this.props.filterArray.length - 1] === "publish"
    ) {
      const resultCoupons = this.setPublishData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
    }
    else {
      this.handleFiltersCalledInBetween(value, selectedcheckboxes);
    }
  }
  handleFiltersCalledInBetween(value, selectedcheckboxes) {
    let index = this.props.filterArray.indexOf(value);
    if (value === "active") {
      const resultCoupons = this.setActiveData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
    if (value === "category") {
      const resultCoupons = this.setCategoryData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
    if (value === "date") {
      const resultCoupons = this.setDateData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
    if (value === "country") {
      const resultCoupons = this.setCountryData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
    if (value === "language") {
      const resultCoupons = this.setLanguageData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
    if (value === "publish") {
      const resultCoupons = this.setActiveData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetAll(
        this.props.filterArray.splice(index + 1, this.props.filterArray.length),
        this.props.filterArray.splice(0, index + 1),
        resultCoupons
      );
    }
  }
  handleFirstTimeCalledFilters(value, selectedcheckboxes) {
    if (value === "category") {
      const resultCoupons = this.setCategoryData(selectedcheckboxes);
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
    if (value === "active") {
      const resultCoupons = this.setActiveData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
    if (value === "date") {
      const resultCoupons = this.setDateData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
    if (value === "country") {
      const resultCoupons = this.setCountryData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
    if (value === "language") {
      const resultCoupons = this.setLanguageData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetPublishData(
        !this.props.filterArray.includes("publish"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
    if (value === "publish") {
      const resultCoupons = this.setPublishData(selectedcheckboxes);
      this.resetCategoryData(
        !this.props.filterArray.includes("category"),
        resultCoupons
      );
      this.resetActiveData(
        !this.props.filterArray.includes("active"),
        resultCoupons
      );
      this.resetDateData(
        !this.props.filterArray.includes("date"),
        resultCoupons
      );
      this.resetCountryData(
        !this.props.filterArray.includes("country"),
        resultCoupons
      );
      this.resetLanguageData(
        !this.props.filterArray.includes("language"),
        resultCoupons
      );
      let arr = this.props.filterArray;
      arr.push(value);
      this.props.passFilterArray(arr);
    }
  }
  setCheckBoxFilterData(item) {
    let checkboxFilterData = item.reduce(
      (options, option) => ({
        ...options,
        [option]: false,
      }),
      {}
    );
    return checkboxFilterData;
  }
  fetchSelectedCheckbox(arr) {
    let selectedcheckboxes = new Array();
    for (let [key, value] of Object.entries(arr)) {
      if (value) {
        selectedcheckboxes.push(key);
      }
    }
    return selectedcheckboxes;
  }
  resetActiveData(flag, resultCoupons) {
    if (flag) {
      this.props.passActiveColumnData(
        Array.from(
          new Set(
            resultCoupons.map((item) => (item.active ? "Active" : "Inactive"))
          )
        )
      );
      this.props.passActiveTableData(resultCoupons);
      this.props.passResetActiveFilter(true);
    }
  }
  resetPublishData(flag, resultCoupons) {
    if (flag) {
      this.props.passPublishColumnData(
        Array.from(
          new Set(
            resultCoupons.map((item) => (item.statusPublished ? "Publish" : "Unpublish"))
          )
        )
      );
      this.props.passPublishTableData(resultCoupons);
      this.props.passResetPublishFilter(true);
    }
  }
  resetCategoryData(flag, resultCoupons) {
    if (flag) {
      this.props.passCategoryColumnData(
        Array.from(new Set(resultCoupons.map((item) => item.category["name"])))
      );
      this.props.passCategoryTableData(resultCoupons);
      this.props.passResetCategoryFilter(true);
    }
  }
  resetDateData(flag, resultCoupons) {
    if (flag) {
      this.props.passDateData({ startDate: null, endDate: null });
      this.props.passDateFilterTableData(resultCoupons);
      this.props.passResetDateFilter(true);
    }
  }
  resetCountryData(flag, resultCoupons) {
    if (flag) {
      this.props.passCountryData({ countryCode: null/* , countryName: null */ });
      this.props.passCountryFilterTableData(resultCoupons);
      this.props.passResetCountryFilter(true);
    }
  }
  resetLanguageData(flag, resultCoupons) {
    if (flag) {
      this.props.passLanguageData({ languageCode: null/* , countryName: null */ });
      this.props.passLanguageFilterTableData(resultCoupons);
      this.props.passResetLanguageFilter(true);
    }
  }
  setActiveData(selectedcheckboxes) {
    this.props.passActiveFilterStatus(true);
    let abc = selectedcheckboxes.map((value) =>
      value === "Active" ? true : false
    );
    const resultCoupons = this.props.activeColumnTableEntries.filter((item) =>
      abc.includes(item.active)
    );
    this.props.passData(resultCoupons);
    this.props.passActiveFilterCheckBoxData(abc);
    return resultCoupons;
  }
  setPublishData(selectedcheckboxes) {
    this.props.passPublishFilterStatus(true);
    let abc = selectedcheckboxes.map((value) =>
      value === "Publish" ? true : false
    );
    const resultCoupons = this.props.publishColumnTableEntries.filter((item) =>
      abc.includes(item.statusPublished)
    );
    this.props.passData(resultCoupons);
    this.props.passPublishFilterCheckBoxData(abc);
    return resultCoupons;
  }
  setCategoryData(selectedcheckboxes) {
    this.props.passCategoryFilterStatus(true);
    const resultCoupons = this.props.categoryColumnTableEntries.filter((item) =>
      selectedcheckboxes.includes(item.category.name)
    );
    this.props.passData(resultCoupons);
    this.props.passCategoryFilterCheckBoxData(selectedcheckboxes);
    return resultCoupons;
  }
  setDateData(data) {
    let resultCoupons;
    if (data.startDate !== null && data.endDate !== null) {
      resultCoupons = this.props.dateFilterTableData.filter(
        (item) =>
          Date.parse(item.expires) >= Date.parse(data.startDate) &&
          Date.parse(item.expires) <= Date.parse(data.endDate)
      );
    }
    if (data.startDate !== null && data.endDate === null) {
      resultCoupons = this.props.dateFilterTableData.filter(
        (item) => Date.parse(item.expires) >= Date.parse(data.startDate)
      );
    }
    if (data.startDate === null && data.endDate !== null) {
      resultCoupons = this.props.dateFilterTableData.filter(
        (item) => Date.parse(item.expires) <= Date.parse(data.endDate)
      );
    }
    this.props.passData(resultCoupons);
    return resultCoupons;
  }
  setCountryData(data) {
    let resultCoupons;
    //console.log(this.props.countryFilterTableData);
    if (data !== null && data !== "Country") {
      resultCoupons = this.props.countryFilterTableData.filter(
        (item) => item.country === data
      );
    }
    else {
      resultCoupons = this.props.countryFilterTableData;
    }
    this.props.passData(resultCoupons);
    return resultCoupons;
  }
  setLanguageData(data) {
    let resultCoupons;
    //console.log(this.props.languageFilterTableData);
    if (data !== null && data !== "Language") {
      resultCoupons = this.props.languageFilterTableData.filter(
        (item) => item.language === data
      );
    }
    else {
      resultCoupons = this.props.languageFilterTableData;
    }
    this.props.passData(resultCoupons);
    return resultCoupons;
  }
  resetAll(arrayValuetoReset, updatedfilterArray, resultCoupons) {
    arrayValuetoReset.map((item) => {
      if (item === "active") {
        this.resetActiveData(true, resultCoupons);
      }
      if (item === "category") {
        this.resetCategoryData(true, resultCoupons);
      }
      if (item === "date") {
        this.resetDateData(true, resultCoupons);
      }
      if (item === "country") {
        this.resetCountryData(true, resultCoupons);
      }
      if (item === "language") {
        this.resetLanguageData(true, resultCoupons);
      }
      if (item === "publish") {
        this.resetPublishData(true, resultCoupons);
      }
    });
    this.props.passFilterArray(updatedfilterArray);
  }

  handleDescSorting(column) {
    this.state.sortedFlag = true;
    this.setState({});
    let sortedRows = Object.assign([], this.props.tableRows);
    if (column === "ID") {
      sortedRows.sort((a, b) => {
        return b.id.localeCompare(a.id);
      });
    } else if (column === "Category") {
      sortedRows.sort((a, b) => {
        return b.category.name.localeCompare(a.category.name);
      });
    } else {
      sortedRows.sort((a, b) => {
        return b.active - a.active;
      });
    }
    this.props.passData(sortedRows);
    this.props.sendPageNo(1);
  }

  handleAscSorting(column) {
    this.state.sortedFlag = true;
    this.setState({});
    let sortedRows = Object.assign([], this.props.tableRows);
    if (column === "ID") {
      sortedRows.sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
    } else if (column === "Category") {
      sortedRows.sort((a, b) => {
        return a.category.name.localeCompare(b.category.name);
      });
    } else {
      sortedRows.sort((a, b) => {
        return a.active - b.active;
      });
    }
    this.props.passData(sortedRows);
    this.props.sendPageNo(1);
  }
  handleModalIsOpen = (e) => {
    this.setState({
      createModalIsOpen: e,
      couponId_Selected: "",
    });
  };
  handleModalIsOpen_couponPreview = (e) => {
    this.setState({
      couponPreviewModalIsOpen: e,
    });
  };
  render() {
    const handleRowClick = (val) => {
      this.props.sendIsAnyRowSelected(true);
      this.props.passRow(val);
      let row = document.getElementById(val.id);
      this.state.currentColor = row ? row.style.backgroundColor : "#ffffff";
      this.setState({});
      let prevRow;
      if (this.state.selectedRow !== val.id) {
        if (row) {
          row.style.backgroundColor = "#bcd4ec";
        }
        if (this.state.selectedRow) {
          prevRow = document.getElementById(this.state.selectedRow);
          if (prevRow) {
            prevRow.style.backgroundColor = this.state.currentColor;
          }
        }
      }
      this.setState({ selectedRow: val.id, couponPreviewModalIsOpen: true });
    };
    const handleInfoClick = (val) => {
      this.setState({ couponId_Selected: val, createModalIsOpen: true });
    };

    const dispatchCouponIdToRedux = (CouponId) =>
      this.props.sendCouponId(CouponId);
    const dispatchCopyDataToRedux = (copyData) =>{
      this.props.copyCouponData(copyData);
      this.props.setCreateCouponModalOpen(true);
    }

    let dataColumns = this.state.Data.columns;
    let dataRows = this.state.rows;
    let tableHeaders = (
      <thead>
        <tr>
          {this.state.Data.headers.map((column) => {
            if (column === "published") {
              return (
                <th style={{ whiteSpace: "nowrap" }}>
                  Publish
                  <TableColumnFilter
                    optionData={this.state.publishColumnFilterData}
                    selectedCheckBoxes={this.handleSelectedCheckboxesForPublish}
                    filter="publish_filter"
                  ></TableColumnFilter>
                </th>
              );
            }
            if (column === "ID") {
              return (
                <th style={{ whiteSpace: "nowrap" }}>
                  {column}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_up"}
                      src="./../arrow-up.png"
                      alt="up_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleDescSorting(column)}
                    />
                  </button>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_down"}
                      src="./../arrow-down.png"
                      alt="down_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleAscSorting(column)}
                    />
                  </button>
                </th>
              );
            }
            if (column === "Category") {
              return (
                <th style={{ whiteSpace: "nowrap" }}>
                  {column}
                  <TableColumnFilter
                    optionData={this.state.categoryColumnFilterData}
                    selectedCheckBoxes={
                      this.handleSelectedCheckboxesForCategory
                    }
                    filter="category_filter"
                  ></TableColumnFilter>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_up"}
                      src="./../arrow-up.png"
                      alt="up_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleDescSorting(column)}
                    />
                  </button>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_down"}
                      src="./../arrow-down.png"
                      alt="down_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleAscSorting(column)}
                    />
                  </button>
                </th>
              );
            }
            if (column === "Active") {
              return (
                <th style={{ whiteSpace: "nowrap" }}>
                  {column}
                  <TableColumnFilter
                    optionData={this.state.activeColumnFilterData}
                    selectedCheckBoxes={this.handleSelectedCheckboxesForActive}
                    filter="active_filter"
                  ></TableColumnFilter>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_up"}
                      src="./../arrow-up.png"
                      alt="up_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleDescSorting(column)}
                    />
                  </button>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0",
                      height: "5px",
                    }}
                  >
                    <img
                      id={column + "_down"}
                      src="./../arrow-down.png"
                      alt="down_arrow"
                      style={{ height: "10px", width: "10px" }}
                      onClick={() => this.handleAscSorting(column)}
                    />
                  </button>
                </th>
              );
            }
            return <th>{column}</th>;
          })}
        </tr>
      </thead>
    );


    // let filteredData = (this.props.countryFilterData?.countryCode && this.props.countryFilterData?.countryCode != "Country") ? dataRows.filter((data) => data.country === this.props.countryFilterData?.countryCode) : dataRows;

    /* let filteredData = dataRows;

    if (this.props.countryFilterData?.countryCode && this.props.countryFilterData?.countryCode !== "Country") {
      filteredData = filteredData.filter((data) => data.country === this.props.countryFilterData.countryCode);
    }

    if (this.props.languageFilterData?.languageCode && this.props.languageFilterData?.languageCode !== "Language") {
      filteredData = filteredData.filter((data) => data.language === this.props.languageFilterData.languageCode);
    } */
    let decodeData = jwt.decode (Cookies.get("token"));
    let tableBody = dataRows.map((row) => {
      return (
        <tr
          id={row["id"]}
          // onClick={() => handleRowClick(row)}
        >
          {dataColumns.map(function (column) {
            if (column === "edit") {
              return (
                <td className="actionItems">
                  { (decodeData && (row['country'] === decodeData.market || decodeData.market == ''))  &&
                 <div>
                  <Link
                    to="/manageCoupons/editcoupon"
                    onClick={() => dispatchCouponIdToRedux(row["id"])}
                    activeClassName="active"
                  >
                    <OverlayTrigger
                      key="edit-top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-"right"`}>
                          <div>Edit Coupon</div>
                        </Tooltip>
                      }
                    >
                      <EditIcon
                        style={{ color: "#3469b3" }}
                        className="edit"
                        aria-hidden={false}
                      />
                    </OverlayTrigger>
                  </Link>
                    <OverlayTrigger
                      key="copy-top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-"right"`}>
                          <div>Copy Coupon</div>
                        </Tooltip>
                      }
                    >
                      <FileCopyIcon
                        style={{ color: "#3469b3" }}
                        className="copy"
                        onClick={() => dispatchCopyDataToRedux(row)}
                        aria-hidden={false}
                      />
                    </OverlayTrigger>
                    
                  <OverlayTrigger
                    key="logs-top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-"right"`}>
                        <div>Change Logs</div>
                      </Tooltip>
                    }
                  >
                    <InfoIcon
                      style={{ color: "#3469b3" }}
                      onClick={() => handleInfoClick(row["id"])}
                      className="info"
                      aria-hidden={false}
                    />
                  </OverlayTrigger>
                  </div>
                  }
                </td>
              );
            } else if (
              column === "active" &&
              (row[column] === "on" || row[column] === true)
            ) {
              return (
                <td>
                  <img src={activeCheckmark} alt="coupon-is-active" />
                </td>
              );
            } else if (
              column === "active" &&
              (row[column] === "" || row[column] === false)
            ) {
              return (
                <td>
                  <span id="coupon-is-inactive"></span>
                </td>
              );
            } else if (column === "category") {
              return row[column] === undefined ? (
                <td></td>
              ) : (
                <td>{row[column].name}</td>
              );
            }else if(column === "preview"){
              return (
                <td>
                  <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-"right"`}>
                          <div>Preview Coupon</div>
                        </Tooltip>
                      }
                    >
                      <VisibilityIcon
                        style={{ color: "#3469b3" }}
                        onClick={() => handleRowClick(row)}
                        className="info"
                        aria-hidden={false}
                      />
                    </OverlayTrigger>
                </td>
              );
            } 
            else if(column === "published"){
              if(row["statusPublished"]){
                return <td><span
                  style={{
                    backgroundColor: "#0c800c",
                    borderRadius: "20px",
                    padding: "0px 5px",
                    color: "white",
                    margin: "0 2px 2px 0",
                  }}
                >
                  P
                </span></td>
              }
              else{
                return <td><span
                    id="published_status"
                    style={{
                      backgroundColor: "#a72323",
                      borderRadius: "20px",
                      padding: "0px 5px",
                      color: "white",
                      margin: "0 2px 2px 0",
                    }}
                  >
                    UP
                  </span></td>
              }
            }
            else {
              return <td>{row[column]}</td>;
            }
          })}
        </tr>
      );
    });

    // Decorate with Bootstrap CSS
    return (
      <div className="coupon-table">
        <Table striped>
          {tableHeaders}
          <tbody>{tableBody}</tbody>
        </Table>{" "}
        <ChangeLogModal
          modalIsOpen={this.state.createModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          couponId={this.state.couponId_Selected}
        />
        <CouponPreviewModal
          modalIsOpen={this.state.couponPreviewModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen_couponPreview}
        />
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.dashboardReducer,
    ...states.previewCouponTabReducer,
  };
  return {
    
    tableRows: state.rows,
    pageNo: state.pageNo,
    activeColumnTableEntries: state.activeColumnTableEntries,
    publishColumnTableEntries: state.publishColumnTableEntries,
    categoryColumnTableEntries: state.categoryColumnTableEntries,
    numberOfEntriesPerPage: state.entriesPerPage,
    activeColumnFilterData: state.activeColumnData,
    publishColumnFilterData: state.publishColumnData,
    categoryColumnFilterData: state.categoryColumnData,
    resetActiveFilter: state.resetActiveFilter,
    resetPublishFilter: state.resetPublishFilter,
    resetCategoryFilter: state.resetCategoryFilter,
    resetAllFilter: state.resetAllFilter,
    resetAllData: state.resetAllData,
    activeFilterStatus: state.isactivefilterApplied,
    publishFilterStatus: state.ispublishfilterApplied,
    categoryFilterStatus: state.iscategoryfilterApplied,
    filterArray: state.filterArray,
    dateFilterTableData: state.dateFilterTableData,
    resetDateFilter: state.resetDateFilter,
    dateFilterData: state.dateFilterData,
    dateFilterStatus: state.dateFilterStatus,
    dateFilterHandler: state.dateFilterHandler,

    countryFilterTableData: state.countryFilterTableData,
    resetCountryFilter: state.resetCountryFilter,
    countryFilterData: state.countryFilterData,
    countryFilterStatus: state.countryFilterStatus,
    countryFilterHandler: state.countryFilterHandler,

    languageFilterTableData: state.languageFilterTableData,
    resetLanguageFilter: state.resetLanguageFilter,
    languageFilterData: state.languageFilterData,
    languageFilterStatus: state.languageFilterStatus,
    languageFilterHandler: state.languageFilterHandler,
    FilterResults: {
      text: state.textValue,
      country: state.countryValue,
      language: state.languageValue,
    },
    dashboardFilter:state.dashboardFilter,
    tableRows_initial:state.previewTab_entries,
    isResetFilterStatus:state.isResetFilterStatus
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passRow: (val) =>
      dispatch({
        type: "SET_SELECTED_ROW",
        data: val,
      }),
    sendCouponId: (val) =>
      dispatch({
        type: "SEND_COUPON_ID",
        couponId: val,
      }),
    copyCouponData: (val) =>
      dispatch({
        type: "COPY_COUPON_DATA",
        data: val,
      }),
    sendIsAnyRowSelected: (val) =>
      dispatch({
        type: "SEND_IS_ANY_ROW_SELECTED",
        isAnyRowSelected: val,
      }),
    passData: (array) =>
      dispatch({
        type: "SET_COUPON_DATA",
        data: array,
      }),
    sendPageNo: (val) =>
      dispatch({
        type: "SEND_PAGENUMBER",
        data: val,
      }),
    passActiveFilterStatus: (val) =>
      dispatch({
        type: "SET_ACTIVE_FILTER_STATUS",
        data: val,
      }),
    passPublishFilterStatus: (val) =>
      dispatch({
        type: "SET_PUBLISH_FILTER_STATUS",
        data: val,
      }),
    passActiveFilterCheckBoxData: (val) =>
      dispatch({
        type: "SET_ACTIVE_FILTER_CHECKBOX_DATA",
        data: val,
      }),
    passPublishFilterCheckBoxData: (val) =>
      dispatch({
        type: "SET_PUBLISH_FILTER_CHECKBOX_DATA",
        data: val,
      }),  
    passResetActiveFilter: (data) =>
      dispatch({
        type: "RESET_ACTIVE_FILTER",
        data: data,
      }),
    passResetPublishFilter: (data) =>
      dispatch({
        type: "RESET_PUBLISH_FILTER",
        data: data,
      }),
    passCategoryFilterStatus: (val) =>
      dispatch({
        type: "SET_CATEGORY_FILTER_STATUS",
        data: val,
      }),
    passCategoryFilterCheckBoxData: (val) =>
      dispatch({
        type: "SET_CATEGORY_FILTER_CHECKBOX_DATA",
        data: val,
      }),
    passResetCategoryFilter: (data) =>
      dispatch({
        type: "RESET_CATEGORY_FILTER",
        data: data,
      }),
    passCategoryTableData: (array) =>
      dispatch({
        type: "SET_CATEGORY_COLUMN_TABLE_DATA",
        data: array,
      }),
    passCategoryColumnData: (array) =>
      dispatch({
        type: "SET_CATEGORY_COLUMN_FILTER_DATA",
        data: array,
      }),
    passActiveTableData: (array) =>
      dispatch({
        type: "SET_ACTIVE_COLUMN_TABLE_DATA",
        data: array,
      }),
    passPublishTableData: (array) =>
      dispatch({
        type: "SET_PUBLISH_COLUMN_TABLE_DATA",
        data: array,
      }),  
    passActiveColumnData: (array) =>
      dispatch({
        type: "SET_ACTIVE_COLUMN_FILTER_DATA",
        data: array,
      }),
    passPublishColumnData: (array) =>
      dispatch({
        type: "SET_PUBLISH_COLUMN_FILTER_DATA",
        data: array,
      }),    
    passResetAll: (data, dataarray) =>
      dispatch({
        type: "RESET_ALL_FILTER",
        data: data,
        dataArray: dataarray,
      }),
    passFilterArray: (data) =>
      dispatch({
        type: "SET_FILTER_ARRAY",
        data: data,
      }),
    passDateFilterTableData: (data) =>
      dispatch({
        type: "SET_DATE_FILTER_TABLE_DATA",
        data: data,
      }),
    passResetDateFilter: (data) =>
      dispatch({
        type: "RESET_DATE_FILTER",
        data: data,
      }),
    passDateData: (array) =>
      dispatch({
        type: "SET_DATE_FILTER_DATA",
        data: array,
      }),
    passDateFilterStatus: (array) =>
      dispatch({
        type: "SET_DATE_FILTER_STATUS",
        data: array,
      }),
    setDateFilterHandlerCall: (value) =>
      dispatch({
        type: "SET_DATE_FILTER_HANDLERCALL",
        data: value,
      }),

    passCountryFilterTableData: (data) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_TABLE_DATA",
        data: data,
      }),
    passResetCountryFilter: (data) =>
      dispatch({
        type: "RESET_COUNTRY_FILTER",
        data: data,
      }),
    passCountryData: (array) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_DATA",
        data: array,
      }),
    passCountryFilterStatus: (array) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_STATUS",
        data: array,
      }),
    setCountryFilterHandlerCall: (value) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_HANDLERCALL",
        data: value,
      }),

    passLanguageFilterTableData: (data) =>
      dispatch({
        type: "SET_LANGUAGE_FILTER_TABLE_DATA",
        data: data,
      }),
    passResetLanguageFilter: (data) =>
      dispatch({
        type: "RESET_LANGUAGE_FILTER",
        data: data,
      }),
    passLanguageData: (array) =>
      dispatch({
        type: "SET_LANGUAGE_FILTER_DATA",
        data: array,
      }),
    passLanguageFilterStatus: (array) =>
      dispatch({
        type: "SET_LANGUAGE_FILTER_STATUS",
        data: array,
      }),
    setLanguageFilterHandlerCall: (value) =>
      dispatch({
        type: "SET_LANGUAGE_FILTER_HANDLERCALL",
        data: value,
      }),

      setCreateCouponModalOpen: (value) =>
      dispatch({
        type: "SET_CREATE_COUPON_MODAL_OPEN",
        data: value,
      }),
      applyDashboardFilter: (val) =>
      dispatch({
        type: "APPLY_DASHBOARD_FILTER",
        data: val,
      }),
      setResetFilterStatus: (value) =>
      dispatch({
        type: "RESET_FILTER_STATUS",
        data: value,
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponsTable);
