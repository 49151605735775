import React, { Component } from "react";
import "./couponHeader.css";
import { Tabs, Tab } from "react-bootstrap";
import CouponPreviewTab from "./../CouponsPreviewTab/couponsPreviewComponent";
import ScheduleEvent from "./../ScheduleTab/ScheduleEvent";
import CouponDashboard from "../Dashboard/CouponDashboard";
import CounponDetailTab from "./CounponDetailTab";
import { connect } from "react-redux";

export class Coupons extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "couponDetailTab",
      isCreateCouponModalOpen: false,
    };
  }

  componentWillReceiveProps(nextprops){
    if(this.state.key!="couponDetailTab" && nextprops.dashboardFilter.type!="" && nextprops.dashboardFilter.type!="Programs" && nextprops.dashboardFilter.type!="Environmental"){
      this.setState({key:"couponDetailTab"});
    }
    if(this.state.key!="couponDetailTab" && (nextprops.dashboardFilter.type == "Programs"|| nextprops.dashboardFilter.type == "Environmental")){
      this.setState({key:"couponPreviewTab"});
    }
  }
  render() {
    return (
      <div>
        <div className="container-fluid">
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={(key) => this.setState({ key: key })}
          >
            <Tab eventKey="couponDetailTab" title="Coupons Detail">
              <CounponDetailTab />
            </Tab>
            <Tab eventKey="couponPreviewTab" title="Coupons Preview">
              <CouponPreviewTab />
            </Tab>
            <Tab
              eventKey="scheduleEventTab"
              title="Schedule Event"
              unmountOnExit={true}
            >
              <ScheduleEvent />
            </Tab>
            <Tab
              eventKey="CouponDashboardTab"
              title="Dashboard"
              unmountOnExit={true}
            >
              <CouponDashboard />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (states) => {
  const state= {...states.reducer,...states.dashboardReducer}
  return {
    dashboardFilter:state.dashboardFilter
  };
};
export default connect(mapStateToProps, null)(Coupons);