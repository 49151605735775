import React, { Component } from "react";
import Axios from "../../service";
import { connect } from "react-redux";
import "./inputGroupStyle.css";
import { white } from "material-ui/styles/colors";
import { FaSearch } from "react-icons/fa";
import exportXLSX from "./../export/excelExporter";
import exportPDF from "./../export/pdfExporter";
import ExportPdfModal from "../export/exportPdfModal";
import DateFilterController from "./dateFilterController";
import BulkUpdateComp from "./BulkUpdateComp";
import venderExportXLSX from "../export/venderExcelExporter";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import CountrySelect from "./CountrySelect";
import LanguageSelect from "./LanguageSelect";

class InputGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: "US",
      languageValue: "EN",
      countries: ["United States", "Canada", "Puerto Rico", "Mexico"],
      languages: ["English", "Spanish", "French"],
      entries: [],
      entriesForExport: [],
      searchEntries: [],
      searchResult: false,
      textValue: "",
      map: {},
      stillSearching: false,
      errorMessage: false,
      isExportDisabled: true,
      isExportPdfModalopen: false,
      resetExportValue: "Export as",
      couponCount: "",
      totalRows: [],
      isResetFilterDisabled: true,
    };
    this.handleFind = this.handleFind.bind(this);
    this.handleExportChange = this.handleExportChange.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.setState({ couponCount: nextprops.totalRows.length });
    if (nextprops.totalRows.length === 0) {
      this.setState({
        errorMessage: true,
        isExportDisabled: true,
        totalRows: nextprops.totalRows,
      });
    } else {
      this.setState({
        errorMessage: false,
        isExportDisabled: false,
        totalRows: nextprops.totalRows,
      });
    }
    if (nextprops.restoreSuccessStatus) {
      this.props.passRestoreRefreshStatus(false);
      this.getCoupons();
      this.props.sendPageNo(1);
    }
    if (nextprops.refreshCouponTableStatus) {
      this.props.passRefreshCouponTable(false);
      this.getCoupons();
      this.props.sendPageNo(1);
    }
    if (nextprops.filterArray.length > 0) {
      this.setState({ isResetFilterDisabled: false });
    } else {
      this.setState({ isResetFilterDisabled: true });
    }
  }
  componentDidMount() {
    this.setState({ stillSearching: true });
    this.setState({ searchResult: false });
    this.getCoupons();
  }
  getCoupons() {
    Axios.get("/manageCoupons/api/coupons", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({ stillSearching: false });
        const responseData = response.data;
        let entries = [];
        responseData.forEach((item) => {
          entries.push(item);
        });
        this.setState({
          entries: entries,
          entriesForExport: entries,
          textValue: "",
        });
        this.props.passData(
          this.state.entries,
          this.state.countryValue,
          this.state.languageValue,
          this.state.textValue
        );
        this.props.passEntriesForPreview(this.state.entriesForExport);

        this.props.setFilterDataInitial(
          Array.from(
            new Set(
              this.state.entries.map((item) =>
                item.active ? "Active" : "Inactive"
              )
            )
          ),
          Array.from(
            new Set(
              this.state.entries.map((item) =>
                item.statusPublished ? "Publish" : "Unpublish"
              )
            )
          ),
          Array.from(
            new Set(this.state.entries.map((item) => item.category["name"]))
          ),
          this.state.entries,
          this.state.entries,
          this.state.entries,
          this.state.entries,
          this.state.entries,
          true,
          true,
          true,
          true,
          true,
          true,
          this.state.entries
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleTableRefresh = (value) => {
    if (value) {
      this.getCoupons();
    }
  };

  handleFind() {
    this.setState({ errorMessage: false, searchEntries: [] }, () => {
      this.state.searchResult = true;
      this.setState({});
      let response = this.state.entries;
      response.forEach((element) => {
        let obj = element;
        if (
          obj.id
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase()) ||
          obj.header
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase()) ||
          obj.couponName
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase())
        ) {
          this.state.searchEntries.push(obj);
        }
      });
      this.setState({});
      if (this.state.searchEntries.length === 0) {
        this.setState({ errorMessage: true });
      }
      this.props.passData(
        this.state.searchEntries,
        this.state.countryValue,
        this.state.languageValue,
        this.state.textValue
      );

      this.props.setFilterDataInitial(
        Array.from(
          new Set(
            this.state.searchEntries.map((item) =>
              item.active ? "Active" : "Inactive"
            )
          )
        ),
        Array.from(
          new Set(
            this.state.searchEntries.map((item) =>
              item.statusPublished ? "Publish" : "Unpublish"
            )
          )
        ),
        Array.from(
          new Set(this.state.searchEntries.map((item) => item.category["name"]))
        ),
        this.state.searchEntries,
        this.state.searchEntries,
        this.state.searchEntries,
        this.state.searchEntries,
        this.state.searchEntries,
        true,
        true,
        true,
        true,
        true,
        true,
        this.state.searchEntries
      );
    });
  }
  async handleExportChange(event) {

    let filteredData = this.state.totalRows;

    if (this.props.countryFilterData?.countryCode && this.props.countryFilterData?.countryCode !== "Country") {
      filteredData = filteredData.filter((data) => data.country === this.props.countryFilterData.countryCode);
    }

    if (this.props.languageFilterData?.languageCode && this.props.languageFilterData?.languageCode !== "Language") {
      filteredData = filteredData.filter((data) => data.language === this.props.languageFilterData.languageCode);
    }

    if (event.target.value === "excel") {
      exportXLSX(filteredData);
    }
    if (event.target.value === "venderExcel") {
      venderExportXLSX(filteredData);
    }
    if (event.target.value === "pdf") {
      this.setState({ isExportPdfModalopen: true });

      const pdf = await exportPDF(filteredData);
      pdf.save("MCL_Coupons");

      this.setState({ isExportPdfModalopen: false });
    }
    this.setState({
      resetExportValue: "Export as",
    });
  }
  async handleCountryChange(event) {
    // dispatch({
    //   type: "SET_COUNTRY_FILTER_STATUS",
    //   data: event.target.value,
    // })
  }

  render() {
    return (
      <div style={{ background: white }}>
        <div className="input-group-container input-field-font-coupon-style">
          <div>
            {this.state.stillSearching ? (
              <p>Searching...</p>
            ) : (
              <p>Coupons Found: {this.state.couponCount}</p>
            )}
          </div>

          <div className="py-2">
            <label for="find">Find: </label>
            <input
              id="find-input"
              className="input-field-coupon-style input-field-bottom-border-coupon-style px-2"
              type="text"
              size="38"
              maxlength="100"
              value={this.state.textValue}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleFind(event);
                }
              }}
              onChange={(event) => {
                this.setState({ textValue: event.target.value });
              }}
            />
            <FaSearch onClick={this.handleFind}></FaSearch>
          </div>
          <div className="py-2 input-group-fields-contianer">
            
            <CountrySelect/>
            <LanguageSelect/>

            <div className="px-2">
              <select
                id="export"
                className="input-field-coupon-style"
                style={{ width: "auto" }}
                onSelected={this.handleExportChange}
                onChange={this.handleExportChange}
                disabled={this.state.isExportDisabled}
                value={this.state.resetExportValue}
              >
                <option value="Export as" selected disabled>
                  Export as
                </option>
                ;
                <option
                  value="pdf"
                  title="Download all preview template coupons"
                >
                  PDF
                </option>
                <option value="excel" title="Download all coupon details">
                  Excel
                </option>
                <option
                  value="venderExcel"
                  title="Download Vender's coupons details"
                >
                  VenderExcel
                </option>
              </select>
            </div>
            <div className="py-2 px-2" title="Date Filter">
              <DateFilterController />
            </div>
            <div className="py-2 px-2" title="Update coupons in Bulk">
              <BulkUpdateComp refreshTable={this.handleTableRefresh} />
            </div>
            <div className="py-2 px-2">
              <button
                className="reset-filter-button"
                disabled={this.state.isResetFilterDisabled}
                onClick={() => {
                  this.props.setResetFilterStatus(true);
                  this.setState({ textValue: "" });
                }}
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>
        {this.state.errorMessage && (
          <div class="alert alert-danger" role="alert">
            Unable to find coupons, please try again with a different search
            criteria
          </div>
        )}
        <ExportPdfModal
          modalIsOpen={this.state.isExportPdfModalopen}
        ></ExportPdfModal>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.ChangeLogReducer,
    ...states.createCouponModalReducer
  };
  return {
    totalRows: state.rows,
    restoreSuccessStatus: state.restoreSuccessStatus,
    refreshCouponTableStatus: state.refreshCouponTableStatus,
    filterArray: state.filterArray,
    countryFilterData: state.countryFilterData,
    languageFilterData: state.languageFilterData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    passData: (array, country, language, text) =>
      dispatch({
        type: "SET_COUPON_DATA",
        data: array,
        countryValue: country,
        languageValue: language,
        textValue: text,
      }),
    setFilterDataInitial: (
      activeColumnData,
      publishColumnData,
      categoryColumnData,
      activeColumnTableData,
      publishColumnTableData,
      categoryColumnTableData,
      dateFilterTableData,
      countryFilterTableData,
      languageFilterTableData,
      resetActiveFilter,
      resetPublishFilter,
      resetCategoryFilter,
      resetDateFilter,
      resetCountryFilter,
      resetLanguageFilter,
      resetAllFilter,
      resetAllData,
    ) =>
      dispatch({
        type: "SET_FILTER_DATA_INITIAL",
        activeColumnData: activeColumnData,
        publishColumnData: publishColumnData,
        categoryColumnData: categoryColumnData,
        activeColumnTableData: activeColumnTableData,
        publishColumnTableData: publishColumnTableData,
        categoryColumnTableData: categoryColumnTableData,
        dateFilterTableData: dateFilterTableData,
        countryFilterTableData: countryFilterTableData,
        languageFilterTableData: languageFilterTableData,
        resetActiveFilter: resetActiveFilter,
        resetPublishFilter: resetPublishFilter,
        resetCategoryFilter: resetCategoryFilter,
        resetDateFilter: resetDateFilter,
        resetCountryFilter: resetCountryFilter,
        resetLanguageFilter: resetLanguageFilter,
        resetAllFilter: resetAllFilter,
        resetAllData: resetAllData,
      }),
    passEntriesForPreview: (array) =>
      dispatch({
        type: "SET_COUPON_DATA_FOR_PREVIEW_TAB",
        data: array,
      }),
    passRestoreRefreshStatus: (val) =>
      dispatch({
        type: "SET_RESTORE_STATUS",
        data: val,
      }),
    sendPageNo: (val) =>
      dispatch({
        type: "SEND_PAGENUMBER",
        data: val,
      }),
    passRefreshCouponTable: (val) =>
      dispatch({
        type: "SET_REFRESH_COUPON_TABLE_STATUS",
        data: val,
      }),
    setResetFilterStatus: (value) =>
      dispatch({
        type: "RESET_FILTER_STATUS",
        data: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InputGroup);
