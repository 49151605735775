import React, { Component } from "react";
import "./ScheduleEvent.css";
import { connect } from "react-redux";
import Axios from "../../service";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import NewEventModal from "./NewEventModal";
import { convertCouponArrayToCouponObjectArray, findCouponEvent } from "./util";
import ScheduleConfirmation from "./ScheduleConfirmation";
import SearchIcon from "@material-ui/icons/Search";
import Cookies from "js-cookie";

export class ScheduleEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createModalIsOpen: false,
      couponsList: [],
      isCreate: true,
      eventList: [],
      eventId: "",
      selectedCoupon: {},
      search_value: "",
      search_event_list: [],
      is_search_enabled: false,
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.refreshEvent) {
      this.getscheduleEvent();
      this.props.refreshEventList(false);
    }
  }
  handleFind = () => {
    this.setState({
      is_search_enabled: this.state.search_value.trim() !== "" ? true : false,
      search_event_list: findCouponEvent(
        this.state.search_value,
        this.state.eventList
      ),
    });
  };
  getcouponList() {
    Axios.get("/event-service/coupon-ids", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          couponsList: convertCouponArrayToCouponObjectArray(response.data),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getscheduleEvent() {
    Axios.get("/event-service/events", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState(
          {
            eventList: response.data,
            search_event_list: findCouponEvent(
              this.state.search_value,
              response.data
            ),
          },
          () => {
            this.props.setEventList(response.data);
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    this.getcouponList();
    this.getscheduleEvent();
  }
  handleModalIsOpen = (e) => {
    this.setState({
      createModalIsOpen: e,
    });
    this.getscheduleEvent();
  };
  render() {
    return (
      <div className="scheduleEvent">
        <div className="scheduleEvent__row">
          <div className="scheduleEvent__info">
            <input
              name="couponEvent-search"
              id="couponEvent-search"
              className="couponEvent__search"
              placeholder="Search Scheduled Coupons"
              onChange={(e) =>
                this.setState({ search_value: e.target.value.trim() })
              }
              value={this.state.search_value}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleFind(event);
                }
              }}
            ></input>
            <Button
              id="couponEvent-search-icon"
              className="couponEvent__searchIcon"
              variant="contained"
              color="primary"
              size="medium"
              onClick={this.handleFind}
            >
              <SearchIcon />
            </Button>
          </div>
          <div className="scheduleEvent__right">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              id="schedule__newEvent"
              className="scheduleEvent__newEventButton"
              starticon={<AddIcon />}
              onClick={() => {
                this.setState({ createModalIsOpen: true });
              }}
              title="Create new event"
            >
              <AddIcon />
              <span style={{ marginLeft: "5px" }}>NEW EVENT</span>
            </Button>
          </div>
        </div>
        <ScheduleConfirmation
          eventList={
            this.state.is_search_enabled
              ? this.state.search_event_list
              : this.state.eventList
          }
          isSearchEnabled={this.state.is_search_enabled}
        />
        <NewEventModal
          modalIsOpen={this.state.createModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          couponsList={this.state.couponsList}
          isCreate={this.state.isCreate}
          selectedCoupon={this.state.selectedCoupon}
          eventList={this.state.eventList}
          eventId={this.state.eventId}
        ></NewEventModal>
      </div>
    );
  }
}
const mapStateToProps = (states) => {
  return {
    refreshEvent: states.schedulerReducer.refreshEventList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setEventList: (value) =>
      dispatch({
        type: "SET_SCHEDULE_EVENT_LIST",
        data: value,
      }),
    refreshEventList: (value) =>
      dispatch({
        type: "REFRESH_SCHEDULE_EVENT_LIST",
        data: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEvent);
