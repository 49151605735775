import React, { Component } from "react";
import Pagination from "react-js-pagination";
import {connect} from "react-redux";
import './paginationStyle.css';
import { white } from "material-ui/styles/colors";
import './paginationStyle.css';

export class TablePagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilterVal: {},
            activePage: 1,
            entries:[],
            tableEntries:[]
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    compareFilterObj(obj1, obj2){
       return (obj1.country!==obj2.country || obj1.language!==obj2.language || obj1.text!==obj2.text);
    }
    componentWillReceiveProps(nextprops){
         if(this.compareFilterObj(nextprops.FilterResults, this.state.FilterVal)){
            this.setState({
                activePage: 1,
                FilterVal: nextprops.FilterResults
            });
            this.props.sendPageNo(1);
        }
        this.setState({activePage:nextprops.pageNo,tableEntries:nextprops.tableEntries});
    }

    handlePageChange(pageNumber) {
        if(pageNumber!==this.state.activePage){
        this.setState({activePage: pageNumber});
        this.props.sendPageNo(pageNumber);
    }
    };

    render() {

        const numOfEntries=this.props.numberOfEntriesPerPage;
        return (<div className="pagination-container">
    <Pagination activePage={this.state.activePage}  hideFirstLastPages={false}
                totalItemsCount={this.state.tableEntries.length}
                itemsCountPerPage={numOfEntries}
                pageRangeDisplayed={(((this.state.tableEntries.length/numOfEntries)+1)<4) ? (this.state.tableEntries.length/numOfEntries)+1: 4 }

                prevPageText="BACK"
                nextPageText="NEXT"
                onChange={this.handlePageChange}
                //default classes
                activeClass="page-link-active-li-container"
                linkClass="page-link-anchor-container"
                itemClass="page-link-li-container"
                activeLinkClass="page-link-active-anchor-container"
                //for back button
                itemClassPrev="page-li-prev"
                linkClassPrev="page-anchor-prev"
                //for next button
                itemClassNext="page-li-next"
                linkClassNext="page-anchor-next"
            />
        </div>);
    };
}

const mapStateToProps=(states) => {
    const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
    return {
        FilterResults: {text: state.textValue, country: state.countryValue, language: state.languageValue},
        tableEntries: state.rows,
        numberOfEntriesPerPage: state.entriesPerPage,
        pageNo:state.pageNo
    };
};

const mapDispatchToProps=dispatch =>{
    return{
        sendPageNo: (val) => dispatch({
            type: "SEND_PAGENUMBER",
            data: val
        })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);