import React from "react";
import "./inputGroupStyle.css";
import { connect } from "react-redux";

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        countryCode: null,
        //countryName: null,
      },
      // startRange: null,
      // endRange: null,
       countryFilterStatus: false,
       resetCountryValue: "",
    };
    this.handleCountryFilter = this.handleCountryFilter.bind(this);
    // this.handleDateFilter = this.handleDateFilter.bind(this);
  }
  handleCountryFilter(event) {
    console.log('++++', this.props)
    const countryCode = event.target.value;
    if (countryCode !== null) {
      this.props.passCountryData(countryCode);
      this.props.passCountryFilterStatus(true);
      this.setState({ data: {countryCode}, countryFilterStatus: true, resetCountryValue: countryCode });
    } else {
      this.props.passCountryData(countryCode);
      this.props.passCountryFilterStatus(false);
      this.setState({ data: {countryCode}, countryFilterStatus: false, resetCountryValue: countryCode });
    }
    this.props.setCountryFilterHandlerCall(true);
  }
  componentWillReceiveProps(nextprops) {
     if (nextprops.resetCountryFilter && nextprops.countryFilterTableData.length>0) {
       this.setState({
         data: { countryCode: null/* , countryName: null */ },
         countryFilterStatus: false,
         resetCountryValue: "Country"
       });
       this.props.passResetCountryFilter(false);
       this.props.passCountryFilterStatus(false);
     }
  }


  render() {
    return (
      <div className="px-2">
        <select
          id="export1"
          className="input-field-coupon-style"
          style={{ width: "auto" }}
          //onSelected={handleCountryChange}
          onChange={this.handleCountryFilter}
          // disabled={this.state.isExportDisabled}
          value={this.state.resetCountryValue}
        >
          <option value="Country" selected>
            Country
          </option>
          ;
          <option
            value="us"
            //title="Download all preview template coupons"
          >
            USA
          </option>
          <option
            value="ca"
            //title="Download all coupon details"
          >
            Canada
          </option>
          <option
            value="mx"
            //title="Download Vender's coupons details"
          >
            Mexico
          </option>
          <option
            value="pr"
            //title="Download Vender's coupons details"
          >
            Puerto Rico
          </option>
        </select>
      </div>
    );
  }
}
const mapStateToProps = (states) => {
  const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
  return {
    tableRows: state.rows,
    countryFilterTableData: state.countryFilterTableData,
    resetCountryFilter: state.resetCountryFilter,
    countryFilterStatus: state.countryFilterStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passData: (array) =>
       dispatch({
         type: "SET_COUPON_DATA",
         data: array,
       }),
    passCountryData: (array) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_DATA",
        data: array,
      }),
    passCountryFilterStatus: (array) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_STATUS",
        data: array,
      }),
    setCountryFilterHandlerCall: (value) =>
      dispatch({
        type: "SET_COUNTRY_FILTER_HANDLERCALL",
        data: value,
      }),
     passResetCountryFilter: (data) =>
       dispatch({
         type: "RESET_COUNTRY_FILTER",
         data: data,
       }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountrySelect);

