import React, { Component } from "react";
import CouponsTable from "./Table";
import { connect } from "react-redux";
import InputGroup from "./inputGroup";
import TablePagination from "./TablePagination";
import CreateCouponModal from "./createCoupon/CreateCouponModal";

export class CounponDetailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateCouponModalOpen: false,
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.createCouponModalOpenStatus) {
      this.props.setCreateCouponModalOpen(false);
      this.setState({ isCreateCouponModalOpen: true });
    }
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="create-coupon-button-container">
            <button
              className="create-coupon-button"
              onClick={() => this.setState({ isCreateCouponModalOpen: true })}
              title="Create new coupon"
            >
              CREATE NEW COUPON
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <InputGroup></InputGroup>
            <div className="table-container">
              <CouponsTable></CouponsTable>
              <div className="col-12 py-2">
                <TablePagination></TablePagination>
              </div>
            </div>
          </div>
        </div>
        <CreateCouponModal
          modalIsOpen={this.state.isCreateCouponModalOpen}
          modalIsOpenHandle={(a) =>
            this.setState({ isCreateCouponModalOpen: a })
          }
        ></CreateCouponModal>
      </>
    );
  }
}
const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.createCouponModalReducer,
  };
  return {
    createCouponModalOpenStatus: state.createCouponModalOpenStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCreateCouponModalOpen: (value) =>
      dispatch({
        type: "SET_CREATE_COUPON_MODAL_OPEN",
        data: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CounponDetailTab);
