import React, { Component, useState } from "react";
import Table from 'react-bootstrap/Table';
import '../couponsTab/css/Table.css';
import TodayStatus from "./todayStatus";
import ErrorModal from "./errorModal";
import NextExecution from "./NextExecution";
import Spinner from "../common/spinner";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default class SchedulerHistory extends Component {
  formatDateAndTime = dateArray => {
    return this.formatDate(dateArray) + " " + this.formatTime(dateArray);
  };

  formatDate = dateArray => {
    if(!dateArray || !dateArray.length)
     { return "";}
    else
     { return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];}

  };

  formatTime = dateArray => {
    if(!dateArray || !dateArray.length)
     { return "";}
    else
     { return this.checkValue(dateArray[3]) + ":" + this.checkValue(dateArray[4])  + ":" + this.checkValue(dateArray[5]);}
  };

  checkValue = value=>{
    return value?value:"00";
  }

  tableRow = data => {
    return (
      <tr>
        <td>{data.schedulerName}</td>
        <td>{data.triggerMode}</td>
        <td>{data.schedulerType}</td>
        <td>{this.formatDateAndTime(data.startTime)}</td>
        <td>{this.formatDateAndTime(data.endTime)}</td>
        {data.errors.length > 0?
        (
          <td>
            <ErrorModal msgarr={data.errors} />
          </td>
        ):<td>-</td>}
        <td
          style={{
            color:
              data.status === "FAILED"
                ? "crimson"
                : data.status === "SUCCESS"
                ? "seagreen"
                : "royalblue",fontWeight:"bold"
          }}
        >
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={
              <Tooltip id={`tooltip-"right"`}>
                <div>Passed Records: {data.passedRecords} <br></br> Failed Records: {data.failedRecords}</div>
              </Tooltip>
            }
          >
            <div variant="secondary">{data.status}</div>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  isTodayDataAvailable = data => {
    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let rowDate = this.formatDate(data);
    if (date === rowDate) {
      return true;
    } else {
      return false;
    }
  };

  getHistoryTable = ()=>{
    let jsonData = this.props.data;
    return (
      <div>
          <div className="row justify-content-center mt-3 scheduler-template-container" >
            <div className="col- scheduler-template-table" style={{overflow:"auto"}}>
              <Table className="table-striped table shadow-lg coupon-table" style={{backgroundColor:"white"}}>
                <thead className="table-head">
                  <tr>
                    <td>Name</td>
                    <td>Mode</td>
                    <td>Type</td>
                    <td>Start Time</td>
                    <td>End Time</td>
                    <td>Error Count</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map(data => {
                    return this.tableRow(data);
                  })}
                </tbody>
              </Table>
            </div>
            <div className="col- scheduler-template-status">
              <NextExecution
              schedulerName={this.props.schedulerName}
              schedulerType={this.props.schedulerType}
              schedulerDetails={this.props.schedulerDetails}
              ></NextExecution>
              <TodayStatus
                formatDateAndTime={this.formatDateAndTime}
                isTodayDataAvailable={this.isTodayDataAvailable(
                  jsonData[0].startTime
                )}
                schedulerName={this.props.schedulerName}
                firstData={jsonData[0]}
              />
            </div>
          </div>
        </div>
    );
  }

  getNoDataAvailableDisplay = ()=>{
    return (
      <div className="row justify-content-center mt-3">
        <div className="col-10 alert alert-danger" role="alert">
          Sorry no data available
        </div>
      </div>
    );
  }

  getLoadingSpinner = ()=>{
    return <Spinner value="Loading"/>;
  }

  getDisplay = ()=>{
    if(this.props.isLoading && Object.keys(this.props.data).length === 0){
      return this.getLoadingSpinner();
    }else if (Object.keys(this.props.data).length > 0){
      return this.getHistoryTable();
    }else{
      return this.getNoDataAvailableDisplay();
    }
  }

  render() {
    return this.getDisplay();
  }
}
