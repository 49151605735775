import Cookies from "js-cookie";
import XLSX from "xlsx";
import Axios from "../../service";

const exportXLSX = async (data) => {
  //Convert the data to XLSX format
  let excelData = await prepareExcelData(data);
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "MCL_Coupons");
  // generate XLSX file and send to client
  XLSX.writeFile(wb, "MCL_Coupons.xlsx");
  return ws;
};

const getCountryList = async () => {
  return await Axios.get("/manageCoupons/api/getCountryList", {
    headers: {
      Authorization: Cookies.get("token"),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

const getLanguageList = async () => {
  return await Axios.get("/manageCoupons/api/getLanguageList", {
    headers: {
      Authorization: Cookies.get("token"),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

let getCountryName = (countryList, countryCode) => {
  let countryName = "";
  if (Array.isArray(countryList) && countryList.length > 0) {
    for (const item of countryList) {
      if (item.code === countryCode) {
        countryName = item.countryName;
      }
    }
  }
  return countryName;
};

let getLanguageName = (languageList, languageCode) => {
  let languageName = "";
  if (Array.isArray(languageList) && languageList.length > 0) {
    for (const item of languageList) {
      if (item.code === languageCode) {
        languageName = item.language;
      }
    }
  }
  return languageName;
};

const addPriceDetails = (element, row) => {
  let priceDetails = {
    //Price 1
    "Price Header 1": element.priceHeader1,
    "Price 1 Required": element.price1Required === "on" ? "Y" : "N",
    "Price 1 Default Free": element.price1Free === "on" ? "Y" : "N",
    "Price 1 Post Price": element.postPrice1,
    "Price 1 Override": element.forcePrice1 === "on" ? "Y" : "N",
    "Price 1": element.priceOverride1,
    //Price 2
    "Price Header 2": element.priceHeader2,
    "Price 2 Required": element.price2Required === "on" ? "Y" : "N",
    "Price 2 Default Free": element.price2Free === "on" ? "Y" : "N",
    "Price 2 Post Price": element.postPrice2,
    "Price 2 Override": element.forcePrice2 === "on" ? "Y" : "N",
    "Price 2": element.priceOverride2,
    //Price 3
    "Price Header 3": element.priceHeader3,
    "Price 3 Required": element.price3Required === "on" ? "Y" : "N",
    "Price 3 Default Free": element.price3Free === "on" ? "Y" : "N",
    "Price 3 Post Price": element.postPrice3,
    "Price 3 Override": element.forcePrice3 === "on" ? "Y" : "N",
    "Price 3": element.priceOverride3,
    //Price 4
    "Price Header 4": element.priceHeader4,
    "Price 4 Required": element.price4Required === "on" ? "Y" : "N",
    "Price 4 Default Free": element.price4Free === "on" ? "Y" : "N",
    "Price 4 Post Price": element.postPrice4,
    "Price 4 Override": element.forcePrice4 === "on" ? "Y" : "N",
    "Price 4": element.priceOverride4,
  };
  return Object.assign(row, priceDetails);
};

//Convert the data to XLSX format
const prepareExcelData = async (data) => {
  let excelData = [];
  const countryList = await getCountryList();
  const languageList = await getLanguageList();
  data.forEach((element, index) => {
    let row = {
      "S.No.": index + 1,
      "Coupon Id": element.id,
      "Coupon Name": element.couponName,
      "Coupon Header": element.header,
      "Expiry Date": element.expires,
      Active: element.active !== undefined && element.active ? "Y" : "N",
      Published:
        element.statusPublished !== undefined && element.statusPublished
          ? "Y"
          : "N",
      Category: element.category.name,
      "Sort Order": element.sortOrder,
      Country: getCountryName(countryList, element.country),
      Language: getLanguageName(languageList, element.language),
      "Environmental Indicator":
        element.environmental !== null
          ? element.environmental.id === 0
            ? " "
            : element.environmental.indicator
          : " ",
      Programs: getProgramToString(element.programs),
    };
    row = addPriceDetails(element, row);
    row = Object.assign(row, {
      //Image
      Image: element.imageUrl,
      "Image Alt Text": element.imageAltText,
      //Description
      Includes: element.includes,
    });
    excelData.push(row);
  });
  return excelData;
};
export const getProgramToString = (element) => {
  if (element.length > 0) {
    let resultstring = element.reduce(
      (str, x) => str.concat(x.program + ","),
      ""
    );
    return resultstring.slice(0, resultstring.length - 1);
  } else {
    return " ";
  }
};

export default exportXLSX;
