const initialState = {
  rows: [],
  category_rows: [],
  dealer_rows: [],
  countryValue: "US",
  languageValue: "EN",
  textValue: undefined,
  pageNo: 1,
  Category_PageNo: 1,
  Dealer_PageNo: 1,
  entriesPerPage: 10,
  token: "",
  tab: "",
  updateCouponData_couponId: "illegalredirection",
  selectedRow: "",
  createCouponData: {
    createCoupon_couponID: "",
    createCoupon_country: "",
    createCoupon_lanuage: "",
    createCoupon_category: "",
    createCoupon_name: "",
    createCoupon_header: "",
    /*
                            TO DO price header
                            */
    createCoupon_include: "",
    createCoupon_image: "",
  },
  couponPreviewType: "",
  editCouponPreviewType: "HTML3",
  copyData: {}
};

export const reducer = (state = initialState, action) => {
  if (action.type === "SEND_COUPON_ID") {
    return {
      ...state,
      updateCouponData_couponId: action.couponId,
    };
  }
  if (action.type === "SET_COUPON_DATA") {
    return {
      ...state,
      rows: action.data,
      countryValue: action.countryValue,
      languageValue: action.languageValue,
      textValue: action.textValue,
    };
  }
  if (action.type === "SET_CATEGORY_DATA") {
    return {
      ...state,
      category_rows: action.data,
    };
  }
  if (action.type === "SET_DEALER_DATA") {
    return {
      ...state,
      dealer_rows: action.data,
    };
  }
  if (action.type === "SEND_PAGENUMBER") {
    return {
      ...state,
      pageNo: action.data,
    };
  }
  if (action.type === "Category_SEND_PAGENUMBER") {
    return {
      ...state,
      Category_PageNo: action.Category_Data,
    };
  }
  if (action.type === "DEALER_SEND_PAGENUMBER") {
    return {
      ...state,
      Dealer_PageNo: action.Dealer_Data,
    };
  }
  if (action.type === "SEND_TAB") {
    return {
      ...state,
      tab: action.data,
    };
  }
  if (action.type === "SET_CREATE_COUPON_FORM_DATA") {
    return {
      ...state,
      createCoupon_couponID: action.createCoupon_couponID,
      createCoupon_country: action.createCoupon_country,
      createCoupon_lanuage: action.createCoupon_lanuage,
      createCoupon_category: action.createCoupon_category,
      createCoupon_name: action.createCoupon_name,
      createCoupon_header: action.createCoupon_header,
      /*
         TO DO price header
         */
      createCoupon_include: action.createCoupon_include,
      createCoupon_image: action.createCoupon_image,
    };
  }
  if (action.type === "SET_SELECTED_ROW") {
    return {
      ...state,
      selectedRow: action.data,
    };
  }

  if (action.type === "SET_CURRENT_COUPON") {
    return {
      ...state,
      selectedRow: {
        id: action.data.couponID,
        couponName: action.data.name,
        header: action.data.header,
        country: action.data.country_code,
        language: action.data.language_code,
        includes: action.data.includes,
        imageUrl: action.data.imageUrl,
        approved: action.data.approved,
        couponType: action.data.couponType,
        forcePrice1: action.data.forcePrice1,
        forcePrice2: action.data.forcePrice2,
        forcePrice3: action.data.forcePrice3,
        forcePrice4: action.data.forcePrice4,
        lastUpdate: action.data.lastUpdate,
        parentCoupon: action.data.parentCoupon,
        postPrice1: action.data.postPrice1,
        postPrice2: action.data.postPrice2,
        postPrice3: action.data.postPrice3,
        postPrice4: action.data.postPrice4,
        price1Free: action.data.price1Free,
        price2Free: action.data.price2Free,
        price3Free: action.data.price3Free,
        price4Free: action.data.price4Free,
        priceHeader1: action.data.priceHeader1,
        priceHeader2: action.data.priceHeader2,
        priceHeader3: action.data.priceHeader3,
        priceHeader4: action.data.priceHeader4,
        price1Required: action.data.price1Required,
        price2Required: action.data.price2Required,
        price3Required: action.data.price3Required,
        price4Required: action.data.price4Required,
        priceOverride1: action.data.priceOverride1,
        priceOverride2: action.data.priceOverride2,
        priceOverride3: action.data.priceOverride3,
        priceOverride4: action.data.priceOverride4,
        specialPriceParent: action.data.specialPriceParent,
        sortOrder: action.data.sortOrder,
        active: action.data.active,
      },
    };
  }
  if (action.type === "SEND_IS_ANY_ROW_SELECTED") {
    return {
      ...state,
      isAnyRowSelected: action.isAnyRowSelected,
    };
  }
  if (action.type === "SET_COUPON_PREVIEW_TYPE") {
    return {
      ...state,
      couponPreviewType: action.data,
    };
  }
  if (action.type === "SET_EDIT_COUPON_PREVIEW_TYPE") {
    return {
      ...state,
      editCouponPreviewType: action.data,
    };
  }
  if (action.type === "COPY_COUPON_DATA") {
    return {
      ...state,
      copyData: action.data,
    };
  }
  return state;
};
export default reducer;
