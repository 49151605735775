import React from "react";
import "./inputGroupStyle.css";
import { connect } from "react-redux";

class LanguageSelect extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          data: {
            languageCode: null,
            //languageName: null,
          },
          // startRange: null,
          // endRange: null,
           languageFilterStatus: false,
           resetLanguageValue: "",
        };
        this.handleLanguageFilter = this.handleLanguageFilter.bind(this);
        // this.handleDateFilter = this.handleDateFilter.bind(this);
      }
      handleLanguageFilter(event) {
        console.log('++++', this.props)
        const languageCode = event.target.value;
        if (languageCode !== null) {
          this.props.passLanguageData(languageCode);
          this.props.passLanguageFilterStatus(true);
          this.setState({ data: {languageCode}, languageFilterStatus: true, resetLanguageValue: languageCode });
        } else {
          this.props.passLanguageData(languageCode);
          this.props.passLanguageFilterStatus(false);
          this.setState({ data: {languageCode}, languageFilterStatus: false, resetLanguageValue: languageCode });
        }
        this.props.setLanguageFilterHandlerCall(true);
      }
      componentWillReceiveProps(nextprops) {
        if (nextprops.resetLanguageFilter && nextprops.languageFilterTableData.length>0) {
          this.setState({
            data: { languageCode: null/* , countryName: null */ },
            languageFilterStatus: false,
            resetLanguageValue: "Language",
          });
          this.props.passResetLanguageFilter(false);
          this.props.passLanguageFilterStatus(false);
        }
     }
      render() {
        return (
          <div className="px-2">
            <select
              id="export2"
              className="input-field-coupon-style"
              style={{ width: "auto" }}
              //onSelected={handleCountryChange}
              onChange={this.handleLanguageFilter}
              // disabled={this.state.isExportDisabled}
              value={this.state.resetLanguageValue}
            >
              <option value="Language" selected>
                Language
              </option>
              ;
              <option
                value="en"
                //title="Download all preview template coupons"
              >
                English
              </option>
              <option
                value="es"
                //title="Download all coupon details"
              >
                Spanish
              </option>
              <option
                value="fr"
                //title="Download Vender's coupons details"
              >
                French
              </option>
            </select>
          </div>
        );
      }
}
const mapStateToProps = (states) => {
    const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
    return {
      tableRows: state.rows,
      languageFilterTableData: state.languageFilterTableData,
      resetLanguageFilter: state.resetLanguageFilter,
      languageFilterStatus: state.languageFilterStatus,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      passData: (array) =>
       dispatch({
         type: "SET_COUPON_DATA",
         data: array,
       }),
      passLanguageData: (array) =>
        dispatch({
          type: "SET_LANGUAGE_FILTER_DATA",
          data: array,
        }),
      passLanguageFilterStatus: (array) =>
        dispatch({
          type: "SET_LANGUAGE_FILTER_STATUS",
          data: array,
        }),
      setLanguageFilterHandlerCall: (value) =>
        dispatch({
          type: "SET_LANGUAGE_FILTER_HANDLERCALL",
          data: value,
        }),
      passResetLanguageFilter: (data) =>
        dispatch({
          type: "RESET_LANGUAGE_FILTER",
          data: data,
        }),
    };
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LanguageSelect);