import React from 'react';
import "./userDetail.css";
import { Tab, Nav } from 'react-bootstrap';
import ChangePassword from './changePassword';
import ProfilePage from './profilePage';
import SessionLog from './sessionLog';


const UserDetail = () => {
    
    return (
        <>
            <section>
                <div className="container-fluid profile-page">
                    <div className="row">
                        <div className="col-12">
                            <div className="profile-page-tab">

                                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                                    
                                    <Nav variant="pills" className="custom-tab">
                                        <Nav.Link eventKey="profile" className="nav-item">Your Profile</Nav.Link>

                                        <Nav.Link eventKey="session" className="nav-item">Session Logs</Nav.Link>

                                        <Nav.Link eventKey="chnagePassword" className="nav-item">Change Password</Nav.Link>

                                    </Nav>
                                        
                                    <Tab.Content>
                                        <Tab.Pane eventKey="profile" mountOnEnter unmountOnExit>
                                            <ProfilePage />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="session" mountOnEnter unmountOnExit>
                                            <SessionLog />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="chnagePassword" mountOnEnter unmountOnExit>
                                            <ChangePassword />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default UserDetail
