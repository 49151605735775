const initialState = {
    previewTab_entries:[]
  };

  export const previewCouponTabReducer = (state = initialState, action) => {

    if (action.type === "SET_COUPON_DATA_FOR_PREVIEW_TAB") {
      return {
        ...state,
        previewTab_entries: action.data,
      };
    }

    return state;
  };
  export default previewCouponTabReducer;
