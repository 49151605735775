import React, { Component } from "react";
import { connect } from "react-redux";
import "./updateCoupon.css";
import Axios from "../../service";
import history from "../history";
import ReactTooltip from "react-tooltip";
import PriceHeaderComponent from "../couponsTab/createCoupon/priceHeaderComponent";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import TextField from "@material-ui/core/TextField";
import MultiSelectDropDown from "./../common/multiSelectDropDown";
import { getUsername } from "../ScheduleTab/util";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

const {
  Aborter,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  ServiceURL,
  StorageURL,
  AnonymousCredential,
} = require("@azure/storage-blob");

export class UpdateCouponForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      languages: [],
      categories: [],
      CategoryEntries: [],
      LanguageEntries: [],
      CountryEntries: [],
      name: "",
      header: "",
      couponID: "",
      includes: "",
      program: "",
      environmentalIndicator: "",
      priceHeader1: "",
      price1Required: "",
      postPrice1: "",
      price1Free: "",
      forcePrice1: "",
      priceOverride1: "",
      priceHeader2: "",
      price2Required: "",
      postPrice2: "",
      price2Free: "",
      forcePrice2: "",
      priceOverride2: "",
      priceHeader3: "",
      price3Required: "",
      postPrice3: "",
      price3Free: "",
      forcePrice3: "",
      priceOverride3: "",
      priceHeader4: "",
      price4Required: "",
      postPrice4: "",
      price4Free: "",
      forcePrice4: "",
      priceOverride4: "",
      approved: "",
      couponType: "",
      lastUpdate: "",
      category_id: "",
      category_name: "",
      category_categoryOrder: "",
      language: {
        code: "",
        language: "",
      },
      language_code: "",
      language_language: "",
      country_code: "",
      country_country: "",
      parentCoupon: "",
      price1Coupon: "",
      price2Coupon: "",
      price3Coupon: "",
      price4Coupon: "",
      specialPriceParent: "",
      sortOrder: "0",
      image: "",
      imageUrl: "",
      imageAltText: " ",
      expires: "",
      active: false,
      saveOrPublishButtonClicked: "",
      isUploadSuccessful: "",
      publish_status: false,
      priceValidationFlag_1: false,
      priceValidationFlag_2: false,
      priceValidationFlag_3: false,
      priceValidationFlag_4: false,
      isPriceFieldDisplayed1: true,
      isPriceFieldDisplayed2: false,
      isPriceFieldDisplayed3: false,
      isPriceFieldDisplayed4: false,
      count_PriceFieldDisplayed: 1,
      expiryDate: "",
      environmental_indicators_list: [],
      envIndi_value: "BLANK",
      envIndi_id: 0,
      programs_list: [],
      programs_value: "BLANK",
      programs_id: 0,
      programsData: {},
      selectedPrograms: [],
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCouponIDChange = this.handleCouponIDChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleImageAltText = this.handleImageAltText.bind(this);
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handlesortOrder = this.handlesortOrder.bind(this);
    this.handleExpiryDate = this.handleExpiryDate.bind(this);
    this.handlePriceHeaderChange = this.handlePriceHeaderChange.bind(this);
    this.handlePriceRequiredChange = this.handlePriceRequiredChange.bind(this);
    this.handleDefaultFreeChange = this.handleDefaultFreeChange.bind(this);
    this.handlePostPriceChange = this.handlePostPriceChange.bind(this);
    this.handlePriceOverrideCheckboxChange =
      this.handlePriceOverrideCheckboxChange.bind(this);
    this.handlePriceOverrideChange = this.handlePriceOverrideChange.bind(this);
    this.handlePriceValidationFlag = this.handlePriceValidationFlag.bind(this);
    this.handlePriceFieldAdd = this.handlePriceFieldAdd.bind(this);
    this.handlePriceFieldSub = this.handlePriceFieldSub.bind(this);
    this.handleEnvironmentalIndicatorChange =
      this.handleEnvironmentalIndicatorChange.bind(this);
    this.handleProgramsChange = this.handleProgramsChange.bind(this);
  }
  
  // const classes = useStyles();
  componentWillMount() {
    this.setState(
      {
        couponID: this.props.couponId,
      },
      () => {
        //Call to get coupon details used to populate fields in UpdateCoupon page using getCouponByID
        Axios.get(`/manageCoupons/api/coupons/${this.state.couponID}`, {
          headers: {
            Authorization: Cookies.get("token"),
          },
        })
          .then((CouponResponse) => {
            if (CouponResponse.data.country === "us") {
              this.setState({ country_country: "United States" });
            } else if (CouponResponse.data.country === "ca") {
              this.setState({ country_country: "Canada" });
            } else if (CouponResponse.data.country === "mx") {
              this.setState({ country_country: "Mexico" });
            } else if (CouponResponse.data.country === "pr") {
              this.setState({ country_country: "Puerto Rico" });
            }

            this.setState(
              {
                name: CouponResponse.data.couponName,
                header: CouponResponse.data.header,

                price1Required: CouponResponse.data.price1Required,
                priceHeader1: CouponResponse.data.priceHeader1,
                postPrice1: CouponResponse.data.postPrice1,
                price1Free: CouponResponse.data.price1Free,
                forcePrice1: CouponResponse.data.forcePrice1,
                priceOverride1: CouponResponse.data.priceOverride1,

                price2Required: CouponResponse.data.price2Required,
                priceHeader2: CouponResponse.data.priceHeader2,
                postPrice2: CouponResponse.data.postPrice2,
                price2Free: CouponResponse.data.price2Free,
                forcePrice2: CouponResponse.data.forcePrice2,
                priceOverride2: CouponResponse.data.priceOverride2,

                price3Required: CouponResponse.data.price3Required,
                priceHeader3: CouponResponse.data.priceHeader3,
                postPrice3: CouponResponse.data.postPrice3,
                price3Free: CouponResponse.data.price3Free,
                forcePrice3: CouponResponse.data.forcePrice3,
                priceOverride3: CouponResponse.data.priceOverride3,

                price4Required: CouponResponse.data.price4Required,
                priceHeader4: CouponResponse.data.priceHeader4,
                postPrice4: CouponResponse.data.postPrice4,
                price4Free: CouponResponse.data.price4Free,
                forcePrice4: CouponResponse.data.forcePrice4,
                priceOverride4: CouponResponse.data.priceOverride4,

                includes: CouponResponse.data.includes,
                program: CouponResponse.data.program,
                environmentalIndicator:
                  CouponResponse.data.environmentalIndicator,
                approved: CouponResponse.data.approved,
                couponType: CouponResponse.data.couponType,
                lastUpdate: CouponResponse.data.lastUpdate,

                parentCoupon: CouponResponse.data.parentCoupon,
                price1Coupon: CouponResponse.data.price1Coupon,
                price2Coupon: CouponResponse.data.price2Coupon,
                price3Coupon: CouponResponse.data.price3Coupon,
                price4Coupon: CouponResponse.data.price4Coupon,

                specialPriceParent: CouponResponse.data.specialPriceParent,
                sortOrder: CouponResponse.data.sortOrder,
                imageUrl: CouponResponse.data.imageUrl,
                imageAltText: CouponResponse.data.imageAltText,
                expires: CouponResponse.data.expires,
                country: CouponResponse.data.country,
                country_code: CouponResponse.data.country,
                language_code: CouponResponse.data.language,
                category_id: CouponResponse.data.category["id"],
                category_name: CouponResponse.data.category["name"],
                category_categoryOrder:
                  CouponResponse.data.category["categoryOrder"],
                active: CouponResponse.data.active,
                blobName: "",
                isUploadSuccessful: "",
                publish_status: CouponResponse.data.statusPublished,
                expiryDate: this.dateFormatter(CouponResponse.data.expires),
                isPriceFieldDisplayed2: this.checkPriceFieldDisplay(
                  CouponResponse.data.priceHeader2,
                  CouponResponse.data.price2Required,
                  CouponResponse.data.price2Free,
                  CouponResponse.data.postPrice2,
                  CouponResponse.data.forcePrice2,
                  CouponResponse.data.priceOverride2
                ),
                isPriceFieldDisplayed3: this.checkPriceFieldDisplay(
                  CouponResponse.data.priceHeader3,
                  CouponResponse.data.price3Required,
                  CouponResponse.data.price3Free,
                  CouponResponse.data.postPrice3,
                  CouponResponse.data.forcePrice3,
                  CouponResponse.data.priceOverride3
                ),
                isPriceFieldDisplayed4: this.checkPriceFieldDisplay(
                  CouponResponse.data.priceHeader4,
                  CouponResponse.data.price4Required,
                  CouponResponse.data.price4Free,
                  CouponResponse.data.postPrice4,
                  CouponResponse.data.forcePrice4,
                  CouponResponse.data.priceOverride4
                ),
                envIndi_id:
                  CouponResponse.data.environmental === null
                    ? this.state.envIndi_id
                    : CouponResponse.data.environmental.id,
                envIndi_value:
                  CouponResponse.data.environmental === null
                    ? this.state.envIndi_value
                    : CouponResponse.data.environmental.indicator,
                selectedPrograms: CouponResponse.data.programs,
              },
              this.getPrograms
            );
            this.handlePriceFieldDisplay();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      
    );

    Axios.get("/manageCoupons/api/getCategoryList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((CategoryResponse) => {
        CategoryResponse.data.map((res) => {
          this.state.CategoryEntries.push(res);
        });
        this.setState({ categories: this.state.CategoryEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/getLanguageList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((LanguageResponse) => {
        LanguageResponse.data.map((res) => {
          this.state.LanguageEntries.push(res);
        });
        this.setState({ languages: this.state.LanguageEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/getCountryList", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((CountryResponse) => {
        CountryResponse.data.map((res) => {
          this.state.CountryEntries.push(res);
        });
        this.setState({ countries: this.state.CountryEntries });
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get("/manageCoupons/api/environmentals", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState({ environmental_indicators_list: arr });
      })
      .catch((error) => {
        console.log(error);
      });
    //axios all for programs
  }
  getPrograms() {
    Axios.get("/manageCoupons/api/programs", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        let arr = [];
        response.data.map((res) => {
          arr.push(res);
        });
        this.setState(
          {
            programs_list: arr,
            programsData: this.setCheckBoxProgramData(arr),
          },
          this.setSelectedPrograms
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  setCheckBoxProgramData(item) {
    let checkboxProgramData = item.reduce(
      (options, option) => ({
        ...options,
        [option.program]: false,
      }),
      {}
    );
    return checkboxProgramData;
  }
  handlePriceFieldDisplay() {
    if (this.state.isPriceFieldDisplayed4 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: true,
        isPriceFieldDisplayed4: true,
        count_PriceFieldDisplayed: 4,
      });
    } else if (this.state.isPriceFieldDisplayed3 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: true,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 3,
      });
    } else if (this.state.isPriceFieldDisplayed2 === true) {
      this.setState({
        isPriceFieldDisplayed2: true,
        isPriceFieldDisplayed3: false,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 2,
      });
    } else {
      this.setState({
        isPriceFieldDisplayed2: false,
        isPriceFieldDisplayed3: false,
        isPriceFieldDisplayed4: false,
        count_PriceFieldDisplayed: 1,
      });
    }
  }
  dateFormatter(dateArray) {
    if (dateArray !== "") {
      dateArray = dateArray.split("/");
      return dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
    } else {
      return dateArray;
    }
  }
  dateFormatterAddSlash(dateArray) {
    if (dateArray !== "") {
      dateArray = dateArray.split("-");
      return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
    } else {
      return dateArray;
    }
  }
  handleCountryChange(event) {
    if (event.target.value === "us") {
      this.setState({ country_code: "us", country_country: "United States" });
    } else if (event.target.value === "ca") {
      this.setState({ country_code: "ca", country_country: "Canada" });
    }
    else if (event.target.value === "mx") {
      this.setState({ country_code: "mx", country_country: "Mexico" });
    }
    else if (event.target.value === "pr") {
      this.setState({ country_code: "pr", country_country: "Puerto Rico" });
    }
  }
  handleLanguageChange(event) {
    this.state.languages.map((language) => {
      if (language.code === event.target.value) {
        this.setState({
          language_language: language.language,
          language_code: language.code,
        });
      }
    });
  }
  handleCategoryChange(event) {
    this.state.categories.map((category) => {
      if (category.id == event.target.value) {
        this.setState({
          category_id: category.id,
          category_name: category.name,
          category_categoryOrder: category.categoryOrder,
        });
        if(category.name !== "Featured Service"){
          this.setState({
            image: " ",
            imageURL: " ",
            imageAltText: " "
          })
        }
      }
      
    });
  }
  handleEnvironmentalIndicatorChange(event) {
    this.state.environmental_indicators_list.map((env) => {
      if (env.id == event.target.value) {
        this.setState({
          envIndi_id: env.id,
          envIndi_value: env.indicator,
        });
      }
    });
  }
  handleCouponIDChange(event) {
    this.setState({ couponID: event.target.value });
  }
  handlesortOrder(event) {
    this.setState({ sortOrder: event.target.value });
  }
  handleNameChange(event) {
    //to prevent a string containing only whitespaces being accepted by required fields
    if (event.target.value.trim() === "") {
      this.setState({ name: "" });
    } else {
      this.setState({ name: event.target.value });
    }
  }
  handleExpiryDate(event) {
    this.setState({ expiryDate: event.target.value });
  }
  handleHeaderChange(event) {
    this.setState({ header: event.target.value });
  }

  handleImageAltText(event) {
    //to prevent a string containing only whitespaces being accepted by required fields
    if (event.target.value.trim() === "") {
      this.setState({ imageAltText: " " });
    } else {
      this.setState({ imageAltText: event.target.value });
    }
  }
  //no need to bind handler methods written from this point onwards(ES6 compliant)
  handleActiveChange = (event) => {
    if (event.target.checked) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  };

  handleIncludesChange = (event) => {
    //to prevent a string containing only whitespaces being accepted by required fields
    if (event.target.value.trim() === "") {
      this.setState({ includes: "" });
    } else {
      this.setState({ includes: event.target.value });
    }
  };

  handlePriceHeaderChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceHeader1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceHeader2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceHeader3: valuefromchild });
    } else {
      this.setState({ priceHeader4: valuefromchild });
    }
  }

  handlePriceRequiredChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ price1Required: valuefromchild });
    } else if (index === "2") {
      this.setState({ price2Required: valuefromchild });
    } else if (index === "3") {
      this.setState({ price3Required: valuefromchild });
    } else {
      this.setState({ price4Required: valuefromchild });
    }
  }

  handleDefaultFreeChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ price1Free: valuefromchild });
    } else if (index === "2") {
      this.setState({ price2Free: valuefromchild });
    } else if (index === "3") {
      this.setState({ price3Free: valuefromchild });
    } else {
      this.setState({ price4Free: valuefromchild });
    }
  }

  handlePostPriceChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ postPrice1: valuefromchild });
    } else if (index === "2") {
      this.setState({ postPrice2: valuefromchild });
    } else if (index === "3") {
      this.setState({ postPrice3: valuefromchild });
    } else {
      this.setState({ postPrice4: valuefromchild });
    }
  }

  handlePriceOverrideCheckboxChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ forcePrice1: valuefromchild });
    } else if (index === "2") {
      this.setState({ forcePrice2: valuefromchild });
    } else if (index === "3") {
      this.setState({ forcePrice3: valuefromchild });
    } else {
      this.setState({ forcePrice4: valuefromchild });
    }
  }
  handlePriceOverrideChange(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceOverride1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceOverride2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceOverride3: valuefromchild });
    } else {
      this.setState({ priceOverride4: valuefromchild });
    }
  }
  handlePriceValidationFlag(valuefromchild, index) {
    if (index === "1") {
      this.setState({ priceValidationFlag_1: valuefromchild });
    } else if (index === "2") {
      this.setState({ priceValidationFlag_2: valuefromchild });
    } else if (index === "3") {
      this.setState({ priceValidationFlag_3: valuefromchild });
    } else {
      this.setState({ priceValidationFlag_4: valuefromchild });
    }
  }

  handleUploadImage = async (event) => {
    const anonymousCredential = new AnonymousCredential();
    const pipeline = StorageURL.newPipeline(anonymousCredential);
    const serviceURL = new ServiceURL(
      // "https://mclcouponstorage.blob.core.windows.net/?sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2019-12-31T18:29:59Z&st=2019-07-28T20:16:54Z&spr=https,http&sig=TTgRWHUBwEbRy3QFBbFodiMLt%2FG7divPfjuEKFebkRw%3D",
      "https://" + Cookies.get("blobUrl") + "/" + Cookies.get("blobToken"),
      pipeline
    );

    //delete image before uploading new one
    const containerName = "coupon-images";
    const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
    if (event.target.files[0] !== undefined) {
      if (this.state.imageUrl !== null) {
        const currentfileName = this.state.imageUrl;
        var blobName = currentfileName;

        var blobURL = BlobURL.fromContainerURL(containerURL, blobName);
        var blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
        blockBlobURL.delete(Aborter.none);
      }
      var content = new Blob([event.target.files[0]], {
        type: [
          "image/PNG",
          "image/png",
          "image/JPEG",
          "image/jpeg",
          "image/JPG",
          "image/jpg",
        ],
      });
      var blobName = event.target.files[0].name;
      this.state.blobName = blobName;
      var blobURL = BlobURL.fromContainerURL(containerURL, blobName);
      var blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
      await blockBlobURL
        .upload(Aborter.none, content, content.size)
        .then((response) => {
          blockBlobURL.setHTTPHeaders(Aborter.none, {
            blobContentType: "image/jpeg",
          });
          this.state.isUploadSuccessful = response;
          var imgURL = this.state.blobName;
          this.setState({ image: "Uploaded Image : " + this.state.blobName });
          this.setState({ imageUrl: imgURL });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ image: "unable to upload" });
        });
    }
  };
  submitcall(url, thisOutsideAxios) {
    Axios.put(
      url,
      {
        id: this.state.couponID,
        couponName: this.state.name,
        header: this.state.header,
        category: {
          name: this.state.category_name,
          id: this.state.category_id,
          categoryOrder: this.state.category_categoryOrder,
        },
        country: this.state.country_code,
        language: this.state.language_code,
        includes: this.state.includes,
        imageUrl: this.state.imageUrl,
        imageAltText: this.state.imageAltText,
        approved: this.state.approved,
        couponType: this.state.couponType,
        forcePrice1: this.state.forcePrice1,
        forcePrice2: this.state.forcePrice2,
        forcePrice3: this.state.forcePrice3,
        forcePrice4: this.state.forcePrice4,
        parentCoupon: this.state.parentCoupon,
        postPrice1: this.state.postPrice1,
        postPrice2: this.state.postPrice2,
        postPrice3: this.state.postPrice3,
        postPrice4: this.state.postPrice4,
        price1Free: this.state.price1Free,
        price2Free: this.state.price2Free,
        price3Free: this.state.price3Free,
        price4Free: this.state.price4Free,
        priceHeader1: this.state.priceHeader1,
        priceHeader2: this.state.priceHeader2,
        priceHeader3: this.state.priceHeader3,
        priceHeader4: this.state.priceHeader4,
        price1Required: this.state.price1Required,
        price2Required: this.state.price2Required,
        price3Required: this.state.price3Required,
        price4Required: this.state.price4Required,
        priceOverride1: this.state.priceOverride1,
        priceOverride2: this.state.priceOverride2,
        priceOverride3: this.state.priceOverride3,
        priceOverride4: this.state.priceOverride4,
        specialPriceParent: this.state.specialPriceParent,
        sortOrder: this.state.sortOrder,
        active: this.state.active,
        isCouponPublished: this.state.publish_status,
        expires: this.dateFormatterAddSlash(this.state.expiryDate),
        environmental: {
          id: this.state.envIndi_id,
          indicator: this.state.envIndi_value,
        },
        programs: this.getSelectedPrograms(
          this.state.programs_list,
          this.state.programsData
        ),
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          username: getUsername(),
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then(function () {
        thisOutsideAxios.props.passCurrentCouponFromUpdate(
          thisOutsideAxios.state
        );
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.state.saveOrPublishButtonClicked === "publish") {
      history.push("/manageCoupons/coupons");
    }
  }
  //logic when SAVE button is clicked
  saveOrPublishCoupon = (event) => {
    event.preventDefault();
    var url;

    if (this.state.saveOrPublishButtonClicked === "save") {
      url = "/manageCoupons/api/" + this.state.couponID;
      this.setState({ publish_status: false });
    } else {
      url = `/manageCoupons/api/publishCoupon/${this.state.couponID}`;
      this.setState({ publish_status: true });
    }
    var thisOutsideAxios = this;
    this.submitcall(url, thisOutsideAxios);
  };
  handleProgramsChange(event) {
    this.setState({ programsData: event });
  }
  getSelectedPrograms(programs_list, programsData) {
    return programs_list.filter((ab) => programsData[ab.program] === true);
  }
  setSelectedPrograms() {
    this.state.selectedPrograms.map((ab) => {
      this.state.programsData[ab.program] = true;
    });
    this.setState({});
  }
  checkPriceFieldDisplay(
    priceHeader,
    priceRequired,
    priceFree,
    postPrice,
    forcePrice,
    priceOverride
  ) {
    if (
      priceHeader.trim() !== "" ||
      priceRequired.trim() !== "" ||
      priceFree.trim() !== "" ||
      postPrice.trim() !== "" ||
      forcePrice.trim() !== "" ||
      priceOverride.trim() !== ""
    ) {
      this.state.count_PriceFieldDisplayed =
        this.state.count_PriceFieldDisplayed + 1;
      return true;
    } else {
      return false;
    }
  }
  checkPriceField(
    priceHeader,
    priceRequired,
    priceFree,
    postPrice,
    forcePrice,
    priceOverride
  ) {
    if (
      priceHeader.trim() !== "" ||
      priceRequired.trim() !== "" ||
      priceFree.trim() !== "" ||
      postPrice.trim() !== "" ||
      forcePrice.trim() !== "" ||
      priceOverride.trim() !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  handlePriceFieldAdd() {
    if (this.state.count_PriceFieldDisplayed < 4) {
      this.state.count_PriceFieldDisplayed =
        this.state.count_PriceFieldDisplayed + 1;
      if (this.state.count_PriceFieldDisplayed === 4) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: true,
          isPriceFieldDisplayed4: true,
        });
      }
      if (this.state.count_PriceFieldDisplayed === 3) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: true,
          isPriceFieldDisplayed4: false,
        });
      }
      if (this.state.count_PriceFieldDisplayed === 2) {
        this.setState({
          isPriceFieldDisplayed2: true,
          isPriceFieldDisplayed3: false,
          isPriceFieldDisplayed4: false,
        });
      }
    }
  }
  priceFieldSub_4_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader4,
        this.state.price4Required,
        this.state.price4Free,
        this.state.postPrice4,
        this.state.forcePrice4,
        this.state.priceOverride4
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 4 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed4: false,
          priceHeader4: "",
          price4Required: "",
          price4Free: "",
          postPrice4: "",
          priceOverride4: "",
          forcePrice4: "",
          priceValidationFlag_4: false,
          count_PriceFieldDisplayed: 3,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed4: false,
        priceHeader4: "",
        price4Required: "",
        price4Free: "",
        postPrice4: "",
        priceOverride4: "",
        forcePrice4: "",
        priceValidationFlag_4: false,
        count_PriceFieldDisplayed: 3,
      });
    }
  }
  priceFieldSub_3_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader3,
        this.state.price3Required,
        this.state.price3Free,
        this.state.postPrice3,
        this.state.forcePrice3,
        this.state.priceOverride3
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 3 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed3: false,
          priceHeader3: "",
          price3Required: "",
          price3Free: "",
          postPrice3: "",
          priceOverride3: "",
          forcePrice3: "",
          priceValidationFlag_3: false,
          count_PriceFieldDisplayed: 2,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed3: false,
        priceHeader3: "",
        price3Required: "",
        price3Free: "",
        postPrice3: "",
        priceOverride3: "",
        forcePrice3: "",
        priceValidationFlag_3: false,
        count_PriceFieldDisplayed: 2,
      });
    }
  }
  priceFieldSub_2_Display() {
    if (
      this.checkPriceField(
        this.state.priceHeader2,
        this.state.price2Required,
        this.state.price2Free,
        this.state.postPrice2,
        this.state.forcePrice2,
        this.state.priceOverride2
      )
    ) {
      if (
        window.confirm(
          `You will lose the price Header 2 data with this action.Proceed?`
        )
      ) {
        this.setState({
          isPriceFieldDisplayed2: false,
          priceHeader2: "",
          price2Required: "",
          price2Free: "",
          postPrice2: "",
          priceOverride2: "",
          forcePrice2: "",
          priceValidationFlag_2: false,
          count_PriceFieldDisplayed: 1,
        });
      }
    } else {
      this.setState({
        isPriceFieldDisplayed2: false,
        priceHeader2: "",
        price2Required: "",
        price2Free: "",
        postPrice2: "",
        priceOverride2: "",
        forcePrice2: "",
        priceValidationFlag_2: false,
        count_PriceFieldDisplayed: 1,
      });
    }
  }
  handlePriceFieldSub() {
    if (this.state.count_PriceFieldDisplayed > 1) {
      if (this.state.count_PriceFieldDisplayed === 4) {
        this.priceFieldSub_4_Display();
      }
      if (this.state.count_PriceFieldDisplayed === 3) {
        this.priceFieldSub_3_Display();
      }
      if (this.state.count_PriceFieldDisplayed === 2) {
        this.priceFieldSub_2_Display();
      }
    }
  }
  setEnvIndicatorValue(env) {
    if (env.id === 0) {
      return "Select";
    } else {
      return env.indicator;
    }
  }
  render() {
    let decodeData = jwt.decode (Cookies.get("token")) || {};
    this.props.sendIsAnyRowSelectedToRedux(false);
    if (this.state.isUploadSuccessful) {
      this.props.sendImageUrlAfterSuccessfulUploadToRedux(
        this.state.isUploadSuccessful._response.request.url
      );
      var currentfileName =
        this.state.isUploadSuccessful._response.request.url.split("/");
      currentfileName = currentfileName[4].split("?");
      currentfileName = currentfileName[0];
    }

    this.props.passCurrentCouponFromUpdate(this.state);
    //variable to store the name of the button clicked while submitting the form(SAVE or PUBLISH)
    const setSaveOrPublishButtonClicked = (val) => {
      this.state.saveOrPublishButtonClicked = val;
      this.setState({});
    };
    return (
      <div>
        <div className="form-style">
          <form onSubmit={(event) => this.saveOrPublishCoupon(event)}>
            <div className="create-coupon-block">
              <span className="create-coupon"> UPDATE COUPON</span>
            </div>
            <div>
              {/* <AddCircleOutlineOutlinedIcon color="inherit" fontSize="large"/> */}
              <div className="form-row">
                <div className="form-column">
                  <span className="field-header">
                    COUPON ID<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <br />
                  <input
                    id="couponid-input"
                    className="rectangle-copy"
                    style={{ background: "white" }}
                    name="couponId"
                    onChange={this.handleCouponIDChange}
                    value={this.state.couponID}
                    required
                    readOnly
                  ></input>
                </div>
                {/* keep ACTIVE checkbox checked if it is true in response */}

                <div className="form-column-check">
                  <span className="price-header">ACTIVE</span>
                  <br />
                  <input
                    type="checkbox"
                    id="active-input"
                    className="check-box-style active-or-not"
                    name="active"
                    checked={this.state.active == "1" ? true : false}
                    onChange={this.handleActiveChange}
                  ></input>
                </div>
                <div className="form-column">
                  <span className="field-header">
                    SORT ORDER<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <br />
                  <input
                    id="sortorder-input"
                    pattern="^[0-9][0-9]*$"
                    className="sort-order"
                    data-tip="Tip: Accepts only positive integer number"
                    maxLength="11"
                    name="sortOrder"
                    value={this.state.sortOrder}
                    onChange={this.handlesortOrder}
                    required
                  ></input>
                </div>
                <div className="form-column">
                  <span className="name">EXPIRE DATE</span>
                  <br />
                  <input
                    type="date"
                    className="expire-date"
                    id="expiry-date"
                    name="expiry-date"
                    value={this.state.expiryDate}
                    onChange={this.handleExpiryDate}
                  ></input>
                </div>
                <div className="form-column">
                  <span className="field-header">PROGRAMS</span>
                  <br />
                  <MultiSelectDropDown
                    optionData={this.state.programsData}
                    selectedCheckBoxes={this.handleProgramsChange}
                    filter="category_filter"
                  ></MultiSelectDropDown>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <span className="field-header">COUNTRY</span>
                  <br />
                  <select
                    id="country-input"
                    className="rectangle-copy"
                    onChange={this.handleCountryChange}
                    value={decodeData.market || this.state.country_code}
                  >
                    {/* Setting the 'value' as above makes sure default selected value will be the current value in API response */}
                    {this.state.countries.map((country) => {
                      return (
                        <option
                          className="lt"
                          value={country.code}
                          key={country.code}
                          disabled={decodeData.market === '' ? false : decodeData.market !== country.code ? true : false}
                        >
                          {country.countryName}
                        </option>
                      );
                    })}
                    {/* <option className="lt" value={this.state.country} key={this.state.country}>{this.state.country}</option> */}
                  </select>
                </div>
                <div className="form-column">
                  <span className="field-header">LANGUAGE</span>
                  <br />
                  <select
                    id="language-selector"
                    className="rectangle-copy"
                    value={this.state.language_code}
                    onChange={this.handleLanguageChange}
                  >
                    {this.state.languages.map((language) => {
                      return (
                        <option value={language.code}>
                          {language.language}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-column">
                  <span className="field-header">
                    CATEGORY<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <br />
                  <select
                    id="category-selector"
                    className="rectangle-copy"
                    value={this.state.category_id}
                    onChange={this.handleCategoryChange}
                  >
                    {this.state.categories.map((category) => {
                      return (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-column mg-tp-m12">
                  <span className="price-header">
                    ENVIRONMENTAL
                    <br />
                    INDICATOR
                  </span>
                  <br />
                  <select
                    className="rectangle-copy"
                    id="id-environmental_indicator"
                    key={this.state.envIndi_id}
                    value={this.state.envIndi_id}
                    onChange={this.handleEnvironmentalIndicatorChange}
                  >
                    {this.state.environmental_indicators_list.map((env) => {
                      return (
                        <option value={env.id} key={env.id}>
                          {this.setEnvIndicatorValue(env)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <span className="name">
                    NAME<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <br />
                  <input
                    id="name-input"
                    className="inputfield"
                    name="Name"
                    onChange={this.handleNameChange}
                    value={this.state.name}
                    required
                  ></input>
                </div>
                <div className="form-column">
                  <span className="field-header">HEADER</span>
                  <br />
                  <input
                    id="header-input"
                    className="inputfield"
                    name="Header"
                    onChange={this.handleHeaderChange}
                    value={this.state.header}
                  ></input>
                </div>
              </div>
              <div className="price-field-header-wrapper">
                <span className="price-field-header">PRICING FIELDS</span>
                <AddCircleOutlineOutlinedIcon
                  className={
                    this.state.count_PriceFieldDisplayed === 4
                      ? "add-icon-disable"
                      : "add-icon-enable"
                  }
                  onClick={this.handlePriceFieldAdd}
                />
                <RemoveCircleOutlineOutlinedIcon
                  className={
                    this.state.count_PriceFieldDisplayed === 1
                      ? "remove-icon-disable"
                      : "remove-icon-enable"
                  }
                  onClick={this.handlePriceFieldSub}
                />
              </div>
              {this.state.isPriceFieldDisplayed1 && (
                <PriceHeaderComponent
                  value="1"
                  priceHeaderProp={this.handlePriceHeaderChange}
                  priceRequiredProp={this.handlePriceRequiredChange}
                  defaultFreeProp={this.handleDefaultFreeChange}
                  postPriceProp={this.handlePostPriceChange}
                  priceOverrideCheckboxProp={
                    this.handlePriceOverrideCheckboxChange
                  }
                  priceOverrideProps={this.handlePriceOverrideChange}
                  priceValidationFlagProps={this.handlePriceValidationFlag}
                  priceHeaderValue={this.state.priceHeader1}
                  priceRequiredValue={this.state.price1Required}
                  priceFreeValue={this.state.price1Free}
                  postPriceValue={this.state.postPrice1}
                  priceOverride={this.state.forcePrice1}
                  priceOverrideValue={this.state.priceOverride1}
                />
              )}
              {this.state.isPriceFieldDisplayed2 && (
                <PriceHeaderComponent
                  value="2"
                  priceHeaderProp={this.handlePriceHeaderChange}
                  priceRequiredProp={this.handlePriceRequiredChange}
                  defaultFreeProp={this.handleDefaultFreeChange}
                  postPriceProp={this.handlePostPriceChange}
                  priceOverrideCheckboxProp={
                    this.handlePriceOverrideCheckboxChange
                  }
                  priceOverrideProps={this.handlePriceOverrideChange}
                  priceValidationFlagProps={this.handlePriceValidationFlag}
                  priceHeaderValue={this.state.priceHeader2}
                  priceRequiredValue={this.state.price2Required}
                  priceFreeValue={this.state.price2Free}
                  postPriceValue={this.state.postPrice2}
                  priceOverride={this.state.forcePrice2}
                  priceOverrideValue={this.state.priceOverride2}
                />
              )}
              {this.state.isPriceFieldDisplayed3 && (
                <PriceHeaderComponent
                  value="3"
                  priceHeaderProp={this.handlePriceHeaderChange}
                  priceRequiredProp={this.handlePriceRequiredChange}
                  defaultFreeProp={this.handleDefaultFreeChange}
                  postPriceProp={this.handlePostPriceChange}
                  priceOverrideCheckboxProp={
                    this.handlePriceOverrideCheckboxChange
                  }
                  priceOverrideProps={this.handlePriceOverrideChange}
                  priceValidationFlagProps={this.handlePriceValidationFlag}
                  priceHeaderValue={this.state.priceHeader3}
                  priceRequiredValue={this.state.price3Required}
                  priceFreeValue={this.state.price3Free}
                  postPriceValue={this.state.postPrice3}
                  priceOverride={this.state.forcePrice3}
                  priceOverrideValue={this.state.priceOverride3}
                />
              )}
              {this.state.isPriceFieldDisplayed4 && (
                <PriceHeaderComponent
                  value="4"
                  priceHeaderProp={this.handlePriceHeaderChange}
                  priceRequiredProp={this.handlePriceRequiredChange}
                  defaultFreeProp={this.handleDefaultFreeChange}
                  postPriceProp={this.handlePostPriceChange}
                  priceOverrideCheckboxProp={
                    this.handlePriceOverrideCheckboxChange
                  }
                  priceOverrideProps={this.handlePriceOverrideChange}
                  priceValidationFlagProps={this.handlePriceValidationFlag}
                  priceHeaderValue={this.state.priceHeader4}
                  priceRequiredValue={this.state.price4Required}
                  priceFreeValue={this.state.price4Free}
                  postPriceValue={this.state.postPrice4}
                  priceOverride={this.state.forcePrice4}
                  priceOverrideValue={this.state.priceOverride4}
                />
              )}
              <div className="form-row">
                <div className="form-column">
                  <span className="field-header">
                    INCLUDES<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <br />
                  <TextField
                    id="include-input"
                    multiline
                    rowsMax="8"
                    onChange={this.handleIncludesChange}
                    value={this.state.includes}
                    className="text-area"
                    required
                    variant="outlined"
                  />
                  {/* <textarea id="include-input" className="text-area" name="include_textarea" required value={this.state.includes} onChange={this.handleIncludesChange}></textarea> */}
                </div>
              </div>
              <div className={this.state.category_name == "Featured Service" ? "show-div" : "hide-div"}>
                <div className="form-row">
                  <div className="form-column">
                    <span className="field-header">IMAGE ALT TEXT</span>
                    <br />
                    <input
                      className="inputfield"
                      id="id-imageAlt"
                      name="image-alt"
                      data-tip="This field has a limit of 100 characters"
                      onChange={this.handleImageAltText}
                      value={this.state.imageAltText}
                      maxLength="100"
                    ></input>
                  </div>
                </div>
                <div className="form-row" style={{ display: "flex" }}>
                  {this.handleLanguageChange}
                  {/* the following is an if-else-if JSX statement */}
                  {this.state.imageUrl === null ||
                  this.state.imageUrl === "" ||
                  this.state.imageUrl === " " ? (
                    <img
                      src={this.state.category_name == "Featured Service" ? (
                        "https://" +
                        Cookies.get("blobUrl") +
                        "/coupon-images/" +
                        this.state.imageUrl) : ("")
                      }
                      Style="max-height:50px; max-width:50px; display:none"
                      alt="not uploaded yet"
                    ></img>
                  ) : (
                    [
                      this.state.isUploadSuccessful != "" ? (
                        <img
                          src={
                            this.state.isUploadSuccessful._response.request.url
                          }
                          Style="max-height:50px; max-width:50px"
                          alt="cannot be displayed"
                        ></img>
                      ) : (
                        <img
                          src={
                            this.state.category_name == "Featured Service" ? (
                              "https://" +
                              Cookies.get("blobUrl") +
                              "/coupon-images/" +
                              this.state.imageUrl) : ("")
                          }
                          Style="max-height:50px; max-width:50px"
                          alt="cannot be displayed"
                        ></img>
                      ),
                    ]
                  )}
                  <div className="form-column">
                    <div className="upload-btn-wrapper">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="image-upload"
                        onChange={this.handleUploadImage}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                      />

                      {this.state.imageUrl === null ||
                      this.state.imageUrl === "" ||
                      this.state.imageUrl === " " ? (
                        <span
                          className="btn"
                          style={{ padding: 0 }}
                          onClick={() => this.fileInput.click()}
                        >
                          UPLOAD
                        </span>
                      ) : (
                        <span
                          className="btn"
                          style={{ padding: 0 }}
                          onClick={() => this.fileInput.click()}
                        >
                          REPLACE
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <span className="field-header">{this.state.image}</span>
                  </div>
                </div>
              </div>
              {this.state.publish_status === false ? (
                <div className="form-row">
                  <span className="not-published-warning">
                    Coupon not published yet
                  </span>
                </div>
              ) : (
                <div className="form-row">
                  <span
                    className="not-published-warning"
                    style={{ display: "none" }}
                  >
                    Coupon not published yet
                  </span>
                </div>
              )}

              <div className="form-row">
                <div className="form-column">
                  <button
                    className="register-now"
                    type="submit"
                    value="PUBLISH"
                    onClick={() => setSaveOrPublishButtonClicked("publish")}
                  >
                    PUBLISH
                  </button>
                </div>
                <div className="form-column">
                  <button
                    className="sign-in-register"
                    type="submit"
                    value="SUBMIT"
                    onClick={() => setSaveOrPublishButtonClicked("save")}
                  >
                    SAVE
                  </button>
                </div>
                <div className="form-column">
                  <a
                    className="cancel-button"
                    href="javascript:void(0);"
                    onClick={() => {
                      history.push("/manageCoupons/coupons");
                    }}
                  >
                    CANCEL
                  </a>
                </div>
              </div>
            </div>
          </form>
          <ReactTooltip />
        </div>
        <div className="special-code"></div>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    priceHeader: state.priceHeader,
    couponId: state.updateCouponData_couponId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passCurrentCouponFromUpdate: (val) =>
      dispatch({
        type: "SET_CURRENT_COUPON",
        data: val,
      }),
    sendImageUrlAfterSuccessfulUploadToRedux: (val) =>
      dispatch({
        type: "SET_IMAGE_URL_AFTER_SUCCESSFUL_UPLOAD_TO_REDUX",
        data: val,
      }),
    sendIsAnyRowSelectedToRedux: (val) =>
      dispatch({
        type: "SEND_IS_ANY_ROW_SELECTED",
        data: val,
      }),
    sendIsSaveOrPublishSuccessfulToRedux: (val) =>
      dispatch({
        type: "SET_IS_SAVE_OR_PUBLISH_SUCCESSFUL_TO_REDUX",
        data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCouponForm);
