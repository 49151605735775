import React, { Component } from "react";
import Chart from "react-google-charts";
import Axios from "../../service";
import Spinner from "../common/spinner";
import { connect } from "react-redux";
import Cookies from "js-cookie";

export class ActiveChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }
  componentDidMount() {
    this.fetchApi();
  }
  fetchApi() {
    Axios.get("/metric/api/coupon/active-metric", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processRowData(data) {
    let result = [];
    result.push(["Coupon", "Count"]);
    result.push(["Active", data.number_of_active_coupons]);
    result.push(["Inactive", data.number_of_inactive_coupons]);
    return result;
  }

  render() {
    return (
      <div>
        {this.state.rowData.length === 0 ? (
          <div
            style={{
              height: "250px",
              width: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Spinner value="Loading" />{" "}
          </div>
        ) : (
          <Chart
            width={"400px"}
            height={"250px"}
            chartType="PieChart"
            loader={
              <div
                style={{
                  height: "250px",
                  width: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Spinner value="Loading" />{" "}
              </div>
            }
            data={this.processRowData(this.state.rowData)}
            options={{
              title: "Active/Inactive Coupons",
              pieHole: 0.4,
              pieSliceText: "value",
            }}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const { row } = selectedItem;
                    this.props.applyDashboardFilter({
                      type: "Active/Inactive",
                      value: this.processRowData(this.state.rowData)[
                        row + 1
                      ][0],
                    });
                  }
                },
              },
            ]}
            //   rootProps={{ "data-testid": "1" }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyDashboardFilter: (val) =>
      dispatch({
        type: "APPLY_DASHBOARD_FILTER",
        data: val,
      }),
  };
};
export default connect(null, mapDispatchToProps)(ActiveChart);
