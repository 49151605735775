import React, { Component } from 'react';
import { connect } from 'react-redux';
import DealerCouponPURLTemplate from './dealerCouponPURLTemplate';

export class DealerCouponHTML6Template extends Component {
     constructor(props){
         super(props);
     }
    render(){
      if(this.props.isAnyRowSelected===true)
      {
        return (

          <DealerCouponPURLTemplate header={this.props.header}
          includes={this.props.includes}
          id={this.props.id}
          expires={this.props.expires}
          priceHeader1={this.props.priceHeader1}
          priceHeader2={this.props.priceHeader2}
          priceHeader3={this.props.priceHeader3}
          priceHeader4={this.props.priceHeader4}
          postPrice1={this.props.postPrice1}
          postPrice2={this.props.postPrice2}
          postPrice3={this.props.postPrice3}
          postPrice4={this.props.postPrice4}
          priceOverride1={this.props.priceOverride1}
          priceOverride2={this.props.priceOverride2}
          priceOverride3={this.props.priceOverride3}
          priceOverride4={this.props.priceOverride4}
          ></DealerCouponPURLTemplate>
        );}
        else
        {
          return(<div></div>);
        }
    }
}

const mapStateToProps = (states) => {
  const state= {...states.reducer,...states.filterReducer, ...states.resetFilterReducer}
  return {
    isAnyRowSelected:state.isAnyRowSelected
  };
};


export default connect(mapStateToProps)(DealerCouponHTML6Template);
