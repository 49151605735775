import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "../../service";
import { getUsername } from "../ScheduleTab/util";
import { VpnKey, LockOpen, Lock } from '@material-ui/icons';

const ChangePassword = () => {
    let history= useHistory();
    const [password, setPassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [message, setMessage] = useState({
        successMessage:"",
        errorMessage: "",
        compareMessage: "",
    });
    
    const { currentPassword, newPassword, confirmPassword } = password;

    const onInputChange = e => {
        setPassword({...password, [e.target.name]: e.target.value})
        setMessage({ errorMessage:"" });
    };

    const ChangePasswordSubmit = async (e) => {
        e.preventDefault();
        if(newPassword === confirmPassword){
            await Axios.post("/manageCoupons/api/profile/changePassword", password, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allowed-Origin": "*",
                        username: getUsername(),
                        Authorization: Cookies.get("token"),
                    },
                }   
            ).then((response) => {
                if(response.status == 200){
                    setMessage({
                        successMessage: "Password change sucessfully",
                    });
                    setTimeout(() =>{
                        history.push("/manageCoupons/login");
                    }, 3000)
                }
            }).catch((error)=>{
                if(error.response.status == 406){
                    setMessage({
                        errorMessage: "Current password is not correct",
                    });
                }
            });
            
        }
        
        else{
            setMessage({
                compareMessage: "Confirm password does Not match"
            })
            setPassword({
                currentPassword: password.currentPassword,
                newPassword: "",
                confirmPassword: "",
            })
            
        }
    };
    
    
    return (
        <>
            <div className="col-12 col-lg-8">
                {message.successMessage && 
                    <div class="alert alert-success alert1 mb-2" role="alert">{message.successMessage}</div>
                }
                <form onSubmit={ChangePasswordSubmit}>
                    <div className="form-group row">

                        <div className="input-group mb-2">
                            <label htmlFor="currentPassword" aria-label="currentPassword" className="col-12 col-md-4 col-form-label">Current Password</label>
                            <div className="input-group-prepend">
                                <div className="input-group-text"><VpnKey/></div>
                            </div>
                            <input type="password" id="currentPassword" name="currentPassword" className="form-control custom-form-control" value={currentPassword} onChange={(e) => onInputChange(e)} placeholder="Current Password" required/>

                            <div className="col-12 col-md-8 offset-md-4 error-main-div mt-2">
                                {message.errorMessage &&
                                    <div class="alert alert-danger alert1" role="alert">{message.errorMessage}</div>
                                }
                            </div>
                            
                        </div>
                            
                    </div>

                    <div className="form-group row">
                        <div className="input-group mb-3">
                            <label htmlFor="newPassword" aria-label="newPassword" className="col-12 col-md-4 col-form-label">New Password</label>
                            <div className="input-group-prepend">
                                <div className="input-group-text"><LockOpen /></div>
                            </div>
                            <input type="password" id="newPassword" name="newPassword" className="form-control custom-form-control" value={newPassword} onChange={(e) => onInputChange(e)} placeholder="New Password" required />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="input-group mb-2">
                            <label htmlFor="confirmPassword" aria-label="confirmPassword" className="col-12 col-md-4 col-form-label">Confirm Password</label>
                            <div className="input-group-prepend">
                                <div className="input-group-text"><Lock /></div>
                            </div>
                            <input type="password" id="confirmPassword" name="confirmPassword" className="form-control custom-form-control" value={confirmPassword} onChange={(e) => onInputChange(e)} placeholder="Confirm Password" required />
                            <div className="col-12 col-md-8 offset-md-4 error-main-div mt-2">
                                {
                                    confirmPassword != "" ? (newPassword === confirmPassword ? 
                                        "" : 
                                            (
                                                <div class="alert alert-warning" role="alert">Confirm password does not match</div> 
                                            )
                                        ) :
                                        ""
                                }
                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="form-group row">
                        
                        <div className="col-12 text-center">
                            <button aria-label="Submit" className="btn btn-primary submit-btn custom"> Change Password </button>
                        </div>
                    </div>
                </form>
            </div>
            
        </>
    )
}

export default ChangePassword
