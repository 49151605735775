import React from "react";
import "./dealerPreviewTab.css";
import MailIcon from "@material-ui/icons/Mail";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import LanguageIcon from "@material-ui/icons/Language";

export default function DealerCard(props) {
  return (
    <div
      className="dealerCard"
      key={props.dealerDetails.dealerId}
      id={props.dealerDetails.dealerId}
    >
      <div className="dealerCard__row">
        <p className="dealerCard__id" title="Dealer's Id">#{props.dealerDetails.dealerId}</p>
        {props.dealerDetails.dealerWebsiteUrl && (
          <a
            className="dealerCard__url"
            href={props.dealerDetails.dealerWebsiteUrl}
            title={"visit our site: " + props.dealerDetails.dealerWebsiteUrl}
            target="_blank"
          >
            <LanguageIcon fontSize="medium" color="primary" />
          </a>
        )}
        {props.dealerDetails.phone1 && (
          <a
            className="dealerCard__phone1"
            href={"tel:" + props.dealerDetails.phone1}
            title={"Call us at : " + props.dealerDetails.phone1}
          >
            <PhoneEnabledIcon fontSize="medium" color="primary" />
          </a>
        )}
        {props.dealerDetails.dealerEmail && (
          <a
            className="dealerCard__mail"
            href={"mailto:" + props.dealerDetails.dealerEmail}
            title={"Mail us at: " + props.dealerDetails.dealerEmail}
          >
            <MailIcon fontSize="medium" color="primary" />
          </a>
        )}
      </div>
      <h6 className="dealerCard__name" title="Dealer's Name">{props.dealerDetails.dealerName}</h6>
      <p className="dealerCard__address" title="Dealer's Address">
        {props.dealerDetails.dealerAddress}
        {props.dealerDetails.dealerCity && (
          <span className="dealerCard__city">
            , {props.dealerDetails.dealerCity}
          </span>
        )}
        {props.dealerDetails.dealerState && (
          <span className="dealerCard__state">
            , {props.dealerDetails.dealerState}
          </span>
        )}
        {props.dealerDetails.dealerZip && (
          <span className="dealerCard__zip">
            {" "}
            - {props.dealerDetails.dealerZip}
          </span>
        )}
        <span>.</span>
      </p>
      {props.dealerDetails.phone2 && (
        <p className="dealerCard__phone2">
          Phone (Alternative):
          <span className="alt_phone"> {props.dealerDetails.phone2}</span>
        </p>
      )}
      <div className="dealerCard_optedFor">
        <p className="opt_for">
          Opted For:
          {props.dealerDetails.premiumRevolutionParticipant && (
            <span className="participant_tag">Premium Revolution</span>
          )}
          {props.dealerDetails.nationalOffersIncluded && (
            <span className="participant_tag">National Offers</span>
          )}
          {props.dealerDetails.platinumRevolutionParticipant && (
            <span className="participant_tag">Platinum Revolution</span>
          )}
        </p>
      </div>
    </div>
  );
}
