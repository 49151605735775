import React, { Component } from "react";
import Checkbox from "./checkbox";
import "./tableColumnFilter.css";
import onClickOutside from "react-onclickoutside";

export class TableColumnFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxes: {},
      selectAllBoxes: false,
      open: false,
      setOpen: false,
      filterImageSource: "./../filter_icon.png",
    };
  }
  handleClickOutside = (evt) => {
    this.setState({ open: false, checkboxes: this.props.optionData });
  };
  toggle = () => {
    this.setState({ setOpen: !this.state.open, open: !this.state.open });
    if (this.state.open) {
      this.setState({
        checkboxes: this.props.optionData,
      });
    }
  };
  componentDidMount(){
    this.setState({
      checkboxes: this.props.optionData,
    });
  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      checkboxes: nextprops.optionData,
    });

    let selectedcheckboxes = new Array();
    for (let [key, value] of Object.entries(nextprops.optionData)) {
      if (value) {
        selectedcheckboxes.push(key);
      }
    }
    if (selectedcheckboxes.length !== 0) {
      this.setState({ filterImageSource: "./../filter_icon_filled.png" });
    } else {
      this.setState({ filterImageSource: "./../filter_icon.png" });
    }
  }
  selectAllCheckboxes = (isSelected) => {
    Object.keys(this.state.checkboxes).forEach((checkbox) => {
      this.setState((prevState) => ({
        checkboxes: {
          ...prevState.checkboxes,
          [checkbox]: isSelected,
        },
      }));
    });
  };

  selectAll = () => this.selectAllCheckboxes(true);

  deselectAll = () => this.selectAllCheckboxes(false);

  handleCheckboxChange = (changeEvent) => {
    const { name } = changeEvent.target;

    this.setState((prevState) => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name],
      },
    }));
  };

  handleFormSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    this.props.selectedCheckBoxes(this.state.checkboxes);
    this.toggle(!this.state.open);
  };

  createCheckbox = (option) => (
    <Checkbox
      label={option}
      isSelected={this.state.checkboxes[option]}
      onCheckboxChange={this.handleCheckboxChange}
      key={option}
    />
  );

  createCheckboxes = () =>
    Object.keys(this.props.optionData).map(this.createCheckbox);

  render() {
    return (
      <div className="dd-wrapper">
        <div
          className="dd-header__action"
          tabIndex={0}
          role="button"
          onKeyPress={() => this.toggle(!this.state.open)}
          onClick={() => this.toggle(!this.state.open)}
        >
          <p>
            <img
              id={this.props.filter}
              src={this.state.filterImageSource}
              alt="filter_icon"
            />
          </p>
        </div>
        {this.state.open && (
          <div className="dropdown-list">
            <form onSubmit={this.handleFormSubmit}>
              <div className="dropdown-list_fix_top">
                <button
                  id="filter_clearAll"
                  type="button"
                  onClick={this.deselectAll}
                >
                  Clear All
                </button>
                <button
                  id="filter_selectAll"
                  type="button"
                  onClick={this.selectAll}
                >
                  Select All
                </button>
              </div>
              <div className="dropdown-list-content">
                {this.createCheckboxes()}
              </div>

              <div className="dropdown-list_fix_bottom">
                <button id="filter_apply" type="submit">
                  Apply
                </button>
                <button
                  id="filter_cancel"
                  onClick={() => this.toggle(!this.state.open)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(TableColumnFilter);
