import Cookies from 'js-cookie';
import React, { Component } from 'react';
import PriceHeader from './priceHeader';

export default class DealerCouponTemplateCore extends Component {
     constructor(props){
         super(props);
     }
     getCouponMarkup(markup) {
      return {__html: markup};
    }
    render(){
        return (
            <div>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
            <table cellPadding={0} cellSpacing={0}>
              <tbody><tr><td bgcolor="#FFFFFF"
              style={{borderTop: '#000000 dashed 4px', borderLeft: '#000000 dashed 4px', borderRight: '#000000 dashed 4px', borderBottom: '#000000 dashed 4px',
              backgroundColor: '#ffffff'}}>
                    {/* coupon Template HTML_3-2 */}
                    <table height={300} width={271} style={{width: '271px', verticalAlign: 'top', height: '300px', backgroundColor: '#FFFFFF'}} cellPadding={0}
                    cellSpacing={1} align="center">
                      <tbody><tr height={270}>
                          <td valign="middle" height={270}>
                            <table align="center" width="100%" valign="middle">
                              <tbody>
                                <tr>
                                  <td style={{fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '20px'}} valign="top" align="center">
                                    <center>
                                      <font face="Arial" color="#0067B1" dangerouslySetInnerHTML={this.getCouponMarkup(this.props.header)} />
                                    </center>
                                  </td>
                                </tr>
                                {this.props.priceHeader1 ? (
                                <tr>
                                  <td valign="top" style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "11pt"}} align="center">
                                    <table id="table1" style={{emptyCells: "show", tableLayout: "fixed"}}>
                                    <PriceHeader priceHeader={this.props.priceHeader1} postPrice={this.props.postPrice1} priceOverrideValue={this.props.priceOverride1}/>
                                    {this.props.priceHeader2 &&
                                      <PriceHeader priceHeader={this.props.priceHeader2} postPrice={this.props.postPrice2} priceOverrideValue={this.props.priceOverride2}/>
                                    }
                                    {this.props.priceHeader3 &&
                                      <PriceHeader priceHeader={this.props.priceHeader3} postPrice={this.props.postPrice3} priceOverrideValue={this.props.priceOverride3}/>
                                    }
                                    {this.props.priceHeader4 &&
                                      <PriceHeader priceHeader={this.props.priceHeader4} postPrice={this.props.postPrice4} priceOverrideValue={this.props.priceOverride4}/>
                                    }
                                    </table>
                                  </td>
                                </tr>
                                ) : (
                                <tr>
                                  <td style={{fontFamily: 'Arial,Helvetica,sans-serif', fontSize: '9pt'}} align="center">
                                    <table cellPadding={0} cellSpacing={0} border={0} style={{fontFamily: 'Arial,Helvetica,sans-serif', fontSize: '9pt', width: '100%'}}>
                                      <tbody><tr style={{fontWeight: 'bold'}} valign="middle">
                                          <td style={{fontFamily: 'Arial, sans-serif', fontSize: '44px', fontWeight: 'bold'}} align="center" width="100%">
                                            <font face="Arial" color="#0067B1">{this.props.priceOverride1!==""? this.props.priceOverride1:"$XX"}</font>
                                            <font face="Arial" color="#0067B1" style={{fontSize:'28px'}}> {this.props.postPrice1}</font>
                                          </td>
                                        </tr>
                                      </tbody></table>
                                  </td>
                                </tr>
                                )
                                }
                                <tr>
                                  <td style={{color: '#000000', fontFamily: 'Arial, sans-serif', fontSize: '9px'}}>
                                    <center>
                                      <font face="Arial" dangerouslySetInnerHTML={this.getCouponMarkup(this.props.includes)} />
                                    </center>
                                  </td>
                                </tr>
                              </tbody></table>
                          </td>
                        </tr>
                        <tr height={30} style={{height: '10%'}}>
                          <td>
                            <table width="100%" height={30} border={0}>
                              <tbody><tr>
                                  <td style={{fontFamily: 'Arial, sans-serif', fontSize: '9px', fontWeight: 'bold', textAlign: 'left'}} width="52%">
                                    <font face="Arial">
                                      <b>{"OFFER EXPIRES"+ this.props.expires}</b>
                                    </font>
                                  </td>
                                  <td align="right" style={{fontFamily: 'Arial, sans-serif', fontSize: '9px'}}>
                                    <font face="Arial">
                                    </font>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="70%" style={{fontFamily: 'Arial, sans-serif', fontSize: '9px', fontWeight: 'bold', textAlign: 'left'}}>
                                    {'{'}{'{'}dealer name{'}'}{'}'}
                                    <div style={{fontFamily: 'Arial, sans-serif', fontSize: '9px', textAlign: 'left'}}>
                                      {'{'}{'{'}dealer phone{'}'}{'}'}
                                    </div>
                                    <sup className="includes">{"27122-"+this.props.id+"OL0-h3-t2bc--us-en"}</sup>
                                  </td>
                                  <td align="right">
                                    <img width={100} src={"https://"+Cookies.get("blobUrl")+"/coupon-images/mopar-icon.jpg"} alt="Mopar Logo" />
                                  </td>
                                </tr></tbody></table>
                          </td>
                        </tr>
                        {/* <tr height={30} style={{height: '10%'}}>
                          <td><center><img src={"https://"+(window.env.BLOB_STORAGE_URL)+"/coupon-images/MoparCoupon_Barcode_Final.jpg"}
                          alt="Mopar Barcode" height="30px" width="100px" /> </center></td>
                        </tr> */}
                      </tbody></table>
                    {/*/td*/}
                  </td>
                </tr>
              </tbody></table>
              </div>
        );
    }
}

