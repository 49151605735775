import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import Cookies from "js-cookie";
import Axios from "../../service";

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState([]);
    const getUserInfo = async() => {
        const allInfo = await Axios.get("/manageCoupons/api/profile", {headers: {
            Authorization: Cookies.get("token"),
          },});
        setUserInfo(allInfo.data);
    }
    useEffect(() => {
        getUserInfo();
    },[]) 
    return (
        <>            
            <div className="row">
                <div className="col-12 col-sm-4 col-lg-3 avatar-sec">
                    <Avatar name={userInfo.firstName} />
                </div>

                <div className="col-10 col-sm-8 col-lg-9 offset-1 offset-sm-0 profile-info">
                    <p> <span>User Name:</span> {userInfo.userName}</p>
                    <p> <span>First Name:</span> {userInfo.firstName}</p>
                    <p> <span>Last Name:</span> {userInfo.lastName}</p>
                    <p> <span>Email:</span> {userInfo.email}</p>
                </div>
            </div>
        </>
    )
}

export default ProfilePage
