import React, { Component } from "react";
import DealerTable from "./DealerTable";
import "./dealerTable.css";
import { Tabs, Tab } from "react-bootstrap";
import DealerPreviewTab from "./DealerPreviewTab";
import Axios from "../../service";
import { connect } from "react-redux";
import Cookies from "js-cookie";
export class DealersList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "dealerDetailTab",
      DealerEntries: [],
    };
  }
  componentDidMount() {
    this.fetchDealer();
  }
  fetchDealer() {
    Axios.get("/manageCoupons/api/dealers", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((DealerResponse) => {
        // this.setState({stillSearching: false});
        DealerResponse.data.map((res) => {
          this.state.DealerEntries.push(res);
        });
        this.props.passData(DealerResponse.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <div className="container-fluid">
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={(key) => this.setState({ key: key })}
          >
            <Tab eventKey="dealerDetailTab" title="Dealer Detail">
              <DealerDetailTab dealerEntries={this.state.DealerEntries} />
            </Tab>
            <Tab eventKey="dealerPreviewTab" title="Dealer Preview">
              <DealerPreviewTab dealerEntries={this.state.DealerEntries} />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    passData: (array) =>
      dispatch({
        type: "SET_DEALER_DATA",
        data: array,
      }),
  };
};

export default connect(null, mapDispatchToProps)(DealersList);

export const DealerDetailTab = (props) => {
  return (
    <div>
      <div className="container-fluid-dealers">
        <div className="row dealer-center-align">
          <div className="dealer-align-center">
            <DealerTable dealerEntries={props.dealerEntries}></DealerTable>
          </div>
        </div>
      </div>
    </div>
  );
};
