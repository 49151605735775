import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "../../service";
import Cookies from "js-cookie";
import "./loginHeader.css";
import "./loginStyle.css";
import history from "../history";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      helperTextUsername: "",
      helperTextPassword: "",
      errorUsername: false,
      errorPassword: false,
      invalidAttempt: false,
    };
    this.validateUsername = this.validateUsername.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.setToken = this.setToken.bind(this);
  }
  validateUsername(event) {
    if (event.target.value == "") {
      this.setState({
        helperTextUsername: "Enter Username",
        errorUsername: true,
      });
    } else {
      this.setState({
        username: event.target.value,
        helperTextUsername: "",
        errorUsername: false,
      });
    }
  }
  validatePassword(event) {
    if (event.target.value != "") {
      this.setState({
        password: event.target.value,
        helperTextPassword: "",
        errorPassword: false,
      });
    } else {
      this.setState({
        helperTextPassword: "Enter Password",
        errorPassword: true,
      });
    }
  }

  validateForm(event) {
    if (this.state.username == "") {
      this.setState({
        helperTextUsername: "Enter Username",
        errorUsername: true,
      });
    }
    if (this.state.password == "") {
      this.setState({
        helperTextPassword: "Enter Password",
        errorPassword: true,
      });
    } else {
      let CryptoJS = require("crypto-js");
      let ciphertext_password = CryptoJS.AES.encrypt(
        this.state.password,
        "@$rd156783)TYnjkey_%#"
      );
      let ciphertext_username = CryptoJS.AES.encrypt(
        this.state.username,
        "@$rf15)=83GghvH$njk_%#"
      );
      localStorage.setItem("username_mcl", ciphertext_username);
      Axios.post(
        "/manageCoupons/api/login",
        {
          userName: this.state.username,
          password: this.state.password,
        },
        {
          headers: {
            Authorization: Cookies.get("token"),
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            const token = response.headers["authorization"];
            this.setToken(token);
            Axios.get("/manageCoupons/api/az", {
              headers: {
                Authorization: Cookies.get("token"),
              },
            }).then((response) => {
              this.setBlobUtils(response.data);
            });
            this.props.sendTab("coupons");
            history.push("/manageCoupons/coupons");
          } else if (response.status === 404 || response.status === 500) {
            Cookies.remove("token");
            this.setState({ invalidAttempt: true });
            window.localStorage.removeItem("username_mcl");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            Cookies.remove("token");
            this.setState({ invalidAttempt: true });
            window.localStorage.removeItem("username_mcl");
          }
        });
    }
  }
  setToken = (idToken) => {
    // Saves user token to localStorage
    Cookies.set("token", idToken);
  };

  setBlobUtils = ({ blobStorageUrl, blobStorageSasToken }) => {
    Cookies.set("blobUrl", blobStorageUrl);
    Cookies.set("blobToken", blobStorageSasToken);
  };

  render() {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="greeting-section">
            <div className="heading-container">
              <h3 className="welcome">Welcome To</h3>
            </div>
            <div className="heading-container-tile">
              <h5> Chrysler Dynamic Offer Engine</h5>
              <h6>Management Console</h6>
            </div>
            <div className="brand-logo">
              <img src="../logo-fca.png" alt="fca-brand-logo"></img>
            </div>
          </div>
          <div className="login-section">
            <div className="login-header-title">
              <h3>Log In</h3>
            </div>
            <div className="login-form-section">
              {this.state.invalidAttempt && (
                <div className="invalid-attempt-container">
                  Invalid username or password
                </div>
              )}

              <form className="login-form-inline">
                <div className="input-block">
                  <label htmlFor="username" className="login-label">
                    Username
                  </label>
                  <TextField
                    className="text-input-username"
                    id="username"
                    name="username"
                    autoComplete="username"
                    variant="outlined"
                    autoFocus
                    size="small"
                    helperText={this.state.helperTextUsername}
                    onChange={(event) => this.validateUsername(event)}
                    error={this.state.errorUsername}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        this.validateForm(event);
                      }
                    }}
                  />
                </div>

                <div className="input-block">
                  <label htmlFor="password" className="login-label">
                    Password
                  </label>
                  <TextField
                    className="text-input-password"
                    name="password"
                    type="password"
                    id="password"
                    variant="outlined"
                    size="small"
                    autoComplete="current-password"
                    onChange={(event) => this.validatePassword(event)}
                    helperText={this.state.helperTextPassword}
                    error={this.state.errorPassword}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        this.validateForm(event);
                      }
                    }}
                  />
                </div>
              </form>
              <div className="login-button-container">
                {/* <button
                  id="login-submit-button"
                  onClick={(event) => this.validateForm(event)}
                >
                  LOGIN
                </button> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="login-submit"
                  id="login-submit-button"
                  onClick={(event) => this.validateForm(event)}
                >
                  LOGIN
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendTab: (val) =>
      dispatch({
        type: "SEND_TAB",
        data: val,
      }),
  };
};
export default connect(null, mapDispatchToProps)(SignIn);
