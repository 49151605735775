import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "./ChangeLogModal.css";
import RestoreReviewModal from "./RestoreReviewModal";
import { connect } from "react-redux";

export class ChangeLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionDisable: {},
      selectedVersionList: {},
      compareButtonDisable: true,
      tableData: [],
      restoreModalIsOpen: false,
      restoreData: {},
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.tableData.length > 0 && this.state.tableData.length === 0) {
      this.setState({ tableData: nextprops.tableData });
      let disableData = nextprops.tableData.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
      }, {});
      let checkBoxData = nextprops.tableData.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
      }, {});
      this.setState({
        versionDisable: disableData,
        selectedVersionList: checkBoxData,
      });
    }
  }
  handleRowSelectChange(ev) {
    let temp = this.state.selectedVersionList;
    temp[ev.target.name] = ev.target.checked;
    this.setState({ selectedVersionList: temp }, () => {
      let filteredByKey = Object.keys(
        Object.fromEntries(
          Object.entries(this.state.selectedVersionList).filter(
            ([key, value]) => value === true
          )
        )
      );
      if (filteredByKey.length > 1) {
        let tempdisable = this.state.versionDisable;
        for (let property in tempdisable) {
          tempdisable[property] = true;
        }
        filteredByKey.map((selectedCouponID) => {
          tempdisable[selectedCouponID] = false;
          return tempdisable;
        });
        this.setState({
          versionDisable: tempdisable,
          compareButtonDisable: false,
        });
        this.props.compareDisableStatus(false, filteredByKey);
      } else {
        let tempdisable = this.state.versionDisable;
        for (let property in tempdisable) {
          tempdisable[property] = false;
        }
        this.setState({
          versionDisable: tempdisable,
          compareButtonDisable: true,
        });
        this.props.compareDisableStatus(true, []);
      }
    });
  }
  handleRestore = (ev) => {
    this.setState({
      restoreModalIsOpen: true,
      restoreData: ev.data,
    });
  };
  handleModalIsOpen = (e) => {
    this.setState({
      restoreModalIsOpen: e,
      restoreData: {},
    });
  };
  handleRestoreSuccess = (e) => {
    if (e) {
      this.props.restoreSuccess(e);
      this.props.passRestoreRefreshStatus(true);
    }
  };
  render() {
    const props = this.props;
    let version_count = this.props.tableData.length;
    return (
      <>
        <div className="changeLogTable_container">
          {props.tableData.length > 0 ? (
            <Table
              id="changelog_table"
              className="table table-striped table-lg text-nowrap changeLogTable"
              cellSpacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  ></th>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  >
                    Version
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  >
                    Username
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  >
                    Action{" "}
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  >
                    Time
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      backgroundColor: "white",
                      top: "0",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {props.tableData.map((ev, index) => (
                  <tr id={ev.id} key={ev.id}>
                    <td>
                      <input
                        type="checkbox"
                        id={`selectCoupon_${ev.id}`}
                        name={ev.id}
                        disabled={this.state.versionDisable[ev.id]}
                        onChange={this.handleRowSelectChange.bind(this)}
                      />
                    </td>
                    <td>V{version_count--}</td>
                    <td>{ev.userId}</td>
                    <td>{ev.action}</td>
                    <td>
                      {ev.actionAt && ev.actionAt.split("T")[0]}{" "}
                      {ev.actionAt.split("T")[1].split(".")[0]}
                    </td>
                    {index === 1 || index === 2 ? (
                      <td>
                        <button id={"retore_button_"+ev.id} className="changeLog__restorebutton" onClick={() => this.handleRestore(ev)}>
                          Restore
                        </button>
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No Change Logs Available</p>
          )}
        </div>
        <RestoreReviewModal
          modalIsOpen={this.state.restoreModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          restoreData={this.state.restoreData}
          restoreStatus={this.handleRestoreSuccess}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passRestoreRefreshStatus: (val) =>
      dispatch({
        type: "SET_RESTORE_STATUS",
        data: val,
      }),
  };
};

export default connect(null, mapDispatchToProps)(ChangeLogTable);
