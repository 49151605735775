import React, { Component } from "react";
import "./createCoupon.css";
import ReactTooltip from 'react-tooltip';

export default class PriceHeaderComponent extends Component {
    constructor(props){
      super(props);
      this.state={
        priceIsDisabled:true,
        priceIsRequired:true,
        priceOverRideValue:"",
        priceRequiredMark:"",
        priceValidationFlag:false
      };
    }
    componentWillReceiveProps(nextprops){
      if(nextprops.priceOverride==="on"){
        this.setState({priceIsDisabled:false,
        priceIsRequired:true,
        priceRequiredMark:'*',
        priceValidationFlag:true});
      }
      }
    handlePriceHeaderChange(event){
      this.props.priceHeaderProp(event.target.value, this.props.value);
    }
    handlePriceRequiredChange(event){
      if(event.target.checked){
        this.props.priceRequiredProp("on", this.props.value);

      }
      else{
        this.props.priceRequiredProp(" ", this.props.value);
      }
    }
    handleDefaultFreeChange(event){
      if(event.target.checked){
        this.props.defaultFreeProp("on", this.props.value);
      }
      else{
        this.props.defaultFreeProp(" ", this.props.value);
      }
    }
    handlePostPriceChange(event){
      this.props.postPriceProp(event.target.value, this.props.value);
    }
    handlePriceOverrideChange(event){
      this.setState({priceOverRideValue:event.target.value});
      this.props.priceOverrideProps(event.target.value, this.props.value);
      if(event.target.value!==""){
        this.props.priceValidationFlagProps(false,this.props.value);
        this.setState({priceValidationFlag:false});
      }
      else{
        this.props.priceValidationFlagProps(true,this.props.value);
        this.setState({priceValidationFlag:true});
      }

    }
    handlePriceOverrideCheckboxChange(event){
      if(event.target.checked){
        this.props.priceOverrideCheckboxProp("on", this.props.value);
        this.props.priceValidationFlagProps(true,this.props.value);
        this.setState({priceIsDisabled:false,
                       priceIsRequired:true,
                       priceRequiredMark:'*',
                       priceValidationFlag:true});
      }
      else{
        this.props.priceOverrideCheckboxProp(" ", this.props.value);
        this.props.priceOverrideProps("", this.props.value);
        this.props.priceValidationFlagProps(false,this.props.value);
        this.setState({
          priceIsDisabled:true,
          priceIsRequired:false,
          priceOverRideValue:"",
          priceRequiredMark:"",
          priceValidationFlag:false});
      }
    }
    render() {
        return (
          <div className="form-row">
          <div className="form-column-style">
            <span className="price-header" >PRICE HEADER {this.props.value}</span>
            <br/>
            <input  className='inputfield' id={`id-price_header${this.props.value}`}
            name= {`price_header${this.props.value}`} onChange={this.handlePriceHeaderChange.bind(this)} value={this.props.priceHeaderValue} >
            </input>
          </div>
          <div className="form-column-check">
            <span className="price-header" >PRICE<br/> REQUIRED</span>
            <br/>
            <input  type ="checkbox" style={{backgroundColor:"white"}} className='check-box-style' id={`id-price_required${this.props.value}`}
            name={`price_required${this.props.value}`}
             onChange={this.handlePriceRequiredChange.bind(this)} checked={this.props.priceRequiredValue === "on"? true:false}>
            </input>
          </div>
          <div className="form-column-check ">
            <span className="price-header" >DEFAULT<br/>FREE</span>
            <br/>
            <input  type ="checkbox" className='check-box-style' id={`id-default_Price${this.props.value}`} name={`default_Price${this.props.value}`}
              onChange={this.handleDefaultFreeChange.bind(this)} checked={this.props.priceFreeValue === "on"? true:false}
             >
            </input>
          </div>
          <div className="form-column-style">
            <span className="price-header" >POST PRICE</span>
            <br/>
            <input  className='post-price-field' id={`id-post_Price${this.props.value}`} name= {`post_Price${this.props.value}`}
            onChange={this.handlePostPriceChange.bind(this)} value={this.props.postPriceValue}></input>
          </div>
          <div className="form-column-check">
          <span className="price-header" >PRICE<br/>OVERRIDE</span>
            <br/>
          <input  type ="checkbox" className='check-box-style'
          id={`id-price_override_check${this.props.value}`}
          name={`price_override_check${this.props.value}`}
          onChange={this.handlePriceOverrideCheckboxChange.bind(this)}
          data-tip="Tip: Once checked, Price Value is mandatory to provide" checked={this.props.priceOverride === "on"? true:false}>
            </input>
          </div>
          <div className="form-column-style">
            <span className="price-header" >PRICE<sup style={{color:'red'}}>{this.state.priceRequiredMark}</sup></span>
            <br/>
            <input  className='post-price-field'
            name= {`price_override${this.props.value}`}
            id={`id-price_override${this.props.value}`}
            onChange={this.handlePriceOverrideChange.bind(this)}
            disabled={this.state.priceIsDisabled}
            required={this.state.priceIsRequired}
            value={this.props.priceOverrideValue}>
            </input>
          </div>
          <ReactTooltip />
        </div>
        );
    }
}
