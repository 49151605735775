import React, { Component } from "react";
import Coupons from "./components/couponsTab/coupons";
import history from "./components/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import SignIn from "./components/login/login";
import NavBar from "./components/navigationPanel/NavBar";
import Header from "./components/header/Header";
import CreateCoupon from "./components/couponsTab/createCoupon/createCoupon";
import "./App.css";
import UpdateCoupon from "./components/updateCoupon/updateCoupon";
import UserDetail from "./components/UserDetail/userDetail"
import Scheduler from "./components/scheduler/scheduler";
import PrivateRoute from "./components/privateRoute";
import IdleTimer from "react-idle-timer";
import CategoryList from "./components/Category/CategoryList";
import DealerList from "./components/dealers/DealerList";
import Cookies from "js-cookie";

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null; 
  }
  
  time_out = 1000 * 15 * 60;
  render() {
    return (
      <div className="app">
        <Router history={history}>
          <Switch>
            <Route exact path="/manageCoupons/login" component={SignIn}></Route>

            <PrivateRoute
              exact
              path="/manageCoupons/controlCenter"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <Scheduler></Scheduler>
                </div>
              )}
            />
            <PrivateRoute
              exact
              path="/"
              render={() => {
                return <Redirect to="/manageCoupons/coupons" />;
              }}
            />
            <PrivateRoute
              exact
              path="/manageCoupons/coupons"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <Coupons></Coupons>
                </div>
              )}
            />
            <PrivateRoute
              path="/manageCoupons/editcoupon"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <UpdateCoupon></UpdateCoupon>
                </div>
              )}
            />
            <PrivateRoute
              exact
              path="/manageCoupons/dealers"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <DealerList></DealerList>
                </div>
              )}
            />
            <PrivateRoute
              exact
              path="/manageCoupons/categories"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <CategoryList></CategoryList>
                </div>
              )}
            />
            <PrivateRoute
              exact
              path="/manageCoupons/createcoupons"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    //  onActive={this.onActive}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <NavBar></NavBar>
                  <CreateCoupon></CreateCoupon>
                </div>
              )}
            />

            <PrivateRoute
              exact
              path="/manageCoupons/userdetail"
              render={() => (
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    //  onActive={this.onActive}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={this.time_out}
                  />
                  <Header></Header>
                  <UserDetail></UserDetail>
                </div>
              )}
            />
            
          </Switch>
        </Router>
      </div>
    );
  }
  onIdle = () => {
    localStorage.removeItem("username_mcl");
    Cookies.remove("token");
    Cookies.remove("blobUrl");
    Cookies.remove("blobToken");
    alert("Signed-out due to inactivity!");
    history.replace("/manageCoupons/login");
  };
}
export default App;
