import React, { Component } from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import CreateCoupon from "./createCoupon";
import { connect } from "react-redux";
export class CreateCouponModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "test") {
      Modal.setAppElement("#root");
    }
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.createCouponModalStatus) {
      this.props.setCreateCouponModalClose(false);
      this.closeModal();
    }
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
    this.props.modalIsOpenHandle(false);
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          // style={customStyles}
          className="create-coupon-modal"
          contentLabel="Create Coupon Modal"
        >
          <div className="changelog__headerSection">
            <h3>Create Coupon Modal</h3>
            <CloseIcon
              id="close_modal_icon"
              fontSize="large"
              onClick={this.closeModal}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CreateCoupon />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
    ...states.createCouponModalReducer,
  };
  return {
    copyData: state.copyData,
    createCouponModalStatus: state.createCouponModalStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCreateCouponModalClose: (val) =>
      dispatch({
        type: "CREATE_COUPON_MODAL_STATUS",
        data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCouponModal);
