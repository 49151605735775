import React, { Component } from "react";
import Modal from "react-modal";
import PreviewCoupon from "../previewCoupon/previewCoupon";
import CloseIcon from "@material-ui/icons/Close";

export default class CouponPreviewModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      isCouponComparisonEnabled:false
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "test") {
      Modal.setAppElement("#root");
    }
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
      isCouponComparisonEnabled:false
    });
    this.props.modalIsOpenHandle(false);
  }
  handleComparisonCoupon=(ev)=>{
    this.setState({isCouponComparisonEnabled: ev.target.checked});
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          // style={customStyles}
          className="coupon-preview-modal"
          contentLabel="Coupon Preview Modal"
        >
          <div className="changelog__headerSection">
            <h3>Preview Coupon</h3>
            <CloseIcon fontSize="medium" onClick={this.closeModal} />
          </div>
          <div className="comparisonSection">
            <input type="checkbox" name="couponComparison_checkbox" id="couponComparison_checkbox_id" onChange={this.handleComparisonCoupon}/>
            <label>Compare with other coupon</label>
          </div>
          <div>
            <PreviewCoupon isCouponComparisonEnabled={this.state.isCouponComparisonEnabled}/>
          </div>
        </Modal>
      </div>
    );
  }
}
