import React, { Component } from "react";
import Modal from "react-modal";
import Axios from "../../service";
import ChangeLogTable from "./ChangeLogTable";
import CloseIcon from "@material-ui/icons/Close";
import ComparisonSection from "./ComparisonSection";
import "./ChangeLogModal.css";
import Cookies from "js-cookie";
export default class ChangeLogModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      couponLogList: [],
      couponId: "",
      compareDisableStatus: true,
      couponData1: {},
      couponData2: {},
      comparedData: [],
      version1: "",
      version2: "",
      iscomparedNull: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "test") {
      Modal.setAppElement("#root");
    }
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.couponId !== "")
      this.setState(
        { couponId: nextprops.couponId },
        this.fetchLogsCall(nextprops.couponId)
      );
  }
  handleCompareDisableStatus = (status, versionIds) => {
    this.setState({ compareDisableStatus: status });
    this.fetchDataforVersions(versionIds);
  };
  handleRestoreSuccess = (e) => {
    if (e) {
      this.closeModal();
    }
  };
  fetchDataforVersions(versionIds) {
    if (versionIds.length > 0) {
      let couponData1 = this.getCouponData(
        versionIds[0],
        this.state.couponLogList
      );
      let version_actual = this.getVersion(
        versionIds[0],
        this.state.couponLogList
      );

      let couponData2 = this.getCouponData(
        versionIds[1],
        this.state.couponLogList
      );
      let version_modified = this.getVersion(
        versionIds[1],
        this.state.couponLogList
      );

      this.setState(
        {
          couponData1: couponData1,
          couponData2: couponData2,
          version1: version_actual,
          version2: version_modified,
        },
        () => this.getCompareDifference()
      );
    } else {
      this.setState({
        couponData1: {},
        couponData2: {},
        version1: "",
        version2: "",
        comparedData: [],
        iscomparedNull: false,
      });
    }
  }
  getCompareDifference() {
    Axios.post(
      "/manageCoupons/api/coupon-activity-logs/compare",
      {
        coupon_1: this.state.couponData1,
        coupon_2: this.state.couponData2,
      },
      {
        headers: {
          Authorization: Cookies.get("token"),
        },
      }
    )
      .then((response) => {
        this.setState({ comparedData: response.data });
        if (response.data.length > 0) {
          this.setState({ iscomparedNull: false });
        } else {
          this.setState({ iscomparedNull: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCouponData(versionId, couponLogList) {
    let couponData = {};
    couponLogList.map((obj) => {
      if (obj.id == versionId) {
        couponData = obj.data;
      }
    });
    return couponData;
  }
  getVersion(id, couponLogList) {
    let versionid = couponLogList.findIndex((element) => element.id == id);
    return couponLogList.length - versionid;
  }
  fetchLogsCall(ab) {
    Axios.get(`/manageCoupons/api/coupon-activity-logs/${ab}`, {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          couponLogList: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
      couponLogList: [],
      couponId: "",
      compareDisableStatus: true,
      couponData1: {},
      couponData2: {},
      comparedData: [],
      iscomparedNull: false,
    });
    this.props.modalIsOpenHandle(false);
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          // style={customStyles}
          className="changelog-modal"
          contentLabel="Bulk Update Modal"
        >
          <div className="changelog__headerSection">
            <h3>
              Change Logs <span>- &#91;{this.props.couponId}&#93;</span>
            </h3>
            <CloseIcon
              id="close_modal_icon"
              fontSize="large"
              onClick={this.closeModal}
            />
          </div>
          <div className="changelog-table-container">
            <ChangeLogTable
              tableData={this.state.couponLogList}
              compareDisableStatus={this.handleCompareDisableStatus}
              restoreSuccess={this.handleRestoreSuccess}
            />
            <ComparisonSection
              compareData={this.state.comparedData}
              iscomparedNull={this.state.iscomparedNull}
              versions={{
                version1: this.state.version1,
                version2: this.state.version2,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
