import React from 'react';
import './Header.css';
import Logout from "../logout/logout";
import ProfileIcon from './profileIcon';


const Header = () => {
  let CryptoJS = require("crypto-js");
 
  let ciphertext_username = localStorage.getItem("username_mcl");
  let plaintext_username="";
  if(ciphertext_username){
      let bytes_username = CryptoJS.AES.decrypt(ciphertext_username.toString(), "@$rf15)=83GghvH$njk_%#");
      plaintext_username = bytes_username.toString(CryptoJS.enc.Utf8);
  }
  
  return(
    
    <div className="persistent-header">
      <div className="persistent-header-container">
       
        <div id="persistent-header-title" className="persistent-header-title">Chrysler Dynamic Offer Engine</div>
        <div id="persistent-header-subtitle" className="persistent-header-subtitle">Management Console</div>
      </div>
      <div id= "header-greeting-container">        
        
          <ProfileIcon />
          <Logout></Logout>        
      </div>
      
    </div>

  );
};

export default Header;