import React, { Component } from 'react';
import './Specialcodes.css';
class Specialcodes extends Component {

getSpecialCodes(){
    const specialCodes = {
        '&Bull;': '.',
        '&Reg;': '®',
        '&Mdash;': '-',
        '&Sup1;': '1',
        '#COUPONCODE#': 'id',
        '#TITLE1#': 'Header',
        '#PRICETEXT1#':	'PriceHeader_HTML1',
        '#PRICE1#': 'price1',
        '#PRICETEXT2#': 'PriceHeader_HTML2',
        '#PRICE2#': 'price2',
        '#PRICETEXT3#': 'PriceHeader_HTML3',
        '#PRICE3#': 'price3',
        '#MAINTEXT1#': 'Includes_HTML',
        '#EXPIRES#': 'expires',
        '#PRIMARYCOLOR#': 'brand.primarycolor',
        '#LOGO#': 'logosub',
        '#PRIMARYFONT#': 'brand.primaryfont',
        '#PROGRAM#': 'program',
        '#DEALERNAME#': 'dealername',
        '#DEALERTAG#': 'dealertag',
        '#PHONE1#': 'phone1',
        '#PHONE2#': 'phone2',
        '#ENVIRONMENTAL#': 'environmentalmessage',
        '#SEASON#': 'season',
        '#SEASONDATE#': 'seasondate'
}
    return (<div className="special-codes">
        {Object.keys(specialCodes).map(key=>(
            <p>
            <div className="special-code-key">{key}</div>
            <div className="special-code-separator">:</div>
            <div className="special-code-value">{specialCodes[key]}</div>
            </p>
        ))
        }
    </div>);
}

render() {
        return (
            <div className="special-codes-container">
                    <p className="special-codes-header">Special Codes</p>
                    {this.getSpecialCodes()}
               </div>
        );
    }

}

export default Specialcodes;
