import React, { Component } from "react";
import { connect } from "react-redux";
import "./categoryTable.css";
import Table from "react-bootstrap/Table";
import axios from "axios";
import CreateCategoryModal from "./createCategoryModal";
import EditCategoryModal from "./editCategoryModal";
import CategoryPagination from "./categoryPagination";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Cookies from "js-cookie";

export class CategoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_rows: [],
      selectedRow: null,
      pageNum: 1,
      categories: [],
      categoryID: "",
      categoryName: "",
      categoryOrder: "",
      CategoryEntries: [],
      createModalIsOpen: false,
      editModalIsOpen: false,
      textValue: "",
      errorMessage: false,
      searchEntries: [],
      searchResult: false,
    };
    this.handlecreateCategory = this.handlecreateCategory.bind(this);
    this.handleModalIsOpen = this.handleModalIsOpen.bind(this);
    this.fetchCategory = this.fetchCategory.bind(this);
    this.handleCategoryListRefresh = this.handleCategoryListRefresh.bind(this);
    this.handleEditModalIsOpen = this.handleEditModalIsOpen.bind(this);
    this.handleEditcategory = this.handleEditcategory.bind(this);
    this.handleAscSorting = this.handleAscSorting.bind(this);
    this.handleDescSorting = this.handleDescSorting.bind(this);
    this.handleFind = this.handleFind.bind(this);
  }
  componentWillMount() {
    this.fetchCategory();
  }
  fetchCategory() {
    axios
      .get(
        "https://" + window.env.ENV_URL + "/manageCoupons/api/getCategoryList",
        {
          headers: {
            "Access-Control-Allowed-Origin": "*",
            Authorization: Cookies.get("token"),
          },
        }
      )
      .then((CategoryResponse) => {
        CategoryResponse.data.map((res) => {
          this.state.CategoryEntries.push(res);
        });
        this.setState({
          categories: this.state.CategoryEntries,
          categoryName: this.state.CategoryEntries[0].name,
          categoryOrder: this.state.CategoryEntries[0].categoryOrder,
          categoryID: this.state.CategoryEntries[0].id,
        });
        this.props.passData(this.state.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillReceiveProps(nextprops) {
    this.setState({ category_rows: nextprops.tableRows });
    let startIndex = nextprops.pageNo;
    let val = nextprops.numberOfEntriesPerPage;
    this.state.categories = nextprops.tableRows.slice(
      (startIndex - 1) * val,
      startIndex * val
    );
    this.setState({});
  }
  handlecreateCategory() {
    this.setState({
      createModalIsOpen: true,
    });
  }
  handleEditcategory() {
    this.setState({
      editModalIsOpen: true,
    });
  }
  handleModalIsOpen(e) {
    this.setState({
      createModalIsOpen: e,
    });
  }
  handleEditModalIsOpen(e) {
    this.setState({
      editModalIsOpen: e,
    });
  }
  handleCategoryListRefresh(e) {
    this.setState({ CategoryEntries: [] });
    if (e) {
      this.fetchCategory();
    }
  }
  handleDescSorting() {
    var sortedTableRows = Object.assign([], this.state.category_rows);
    sortedTableRows.sort((a, b) => {
      return b.categoryOrder - a.categoryOrder;
    });
    this.props.passData(sortedTableRows);
    this.props.sendPageNo(1);
  }
  handleAscSorting() {
    var sortedTableRows = Object.assign([], this.state.category_rows);
    sortedTableRows.sort((a, b) => {
      return a.categoryOrder - b.categoryOrder;
    });
    this.props.passData(sortedTableRows);
    this.props.sendPageNo(1);
  }
  handleFind = () => {
    this.setState({ errorMessage: false, searchEntries: [] }, () => {
      this.state.searchResult = true;
      this.setState({});
      let response = this.state.CategoryEntries;
      response.forEach((element) => {
        let obj = element;
        if (
          ("" + obj.categoryOrder).includes(this.state.textValue) ||
          obj.name
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase())
        ) {
          this.state.searchEntries.push(obj);
        }
      });
      this.setState({});
      if (this.state.searchEntries.length === 0) {
        this.setState({ errorMessage: true });
      }
      this.props.passData(this.state.searchEntries);
      this.props.sendPageNo(1);
    });
  };
  render() {
    const handleEditCategoryValue = (id, name, order) => {
      this.setState({
        categoryName: name,
        categoryOrder: order,
        categoryID: id,
      });
      this.handleEditcategory();
    };
    let tableHeaders = (
      <thead>
        <tr>
          <th style={{ width: "383px" }}>Name</th>
          <th style={{ display: "flex" }}>
            Order
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                id="order_up"
                src="./../arrow-up.png"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={this.handleDescSorting}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                id="order_down"
                src="./../arrow-down.png"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={this.handleAscSorting}
              />
            </button>
          </th>
          <th></th>
        </tr>
      </thead>
    );
    let tableBody = this.state.categories.map((cat) => {
      return (
        <tr key={cat["id"]}>
          <td style={{ wordWrap: "break-word" }}>{cat["name"]}</td>
          <td>{cat["categoryOrder"]}</td>
          <td>
            <Link
              onClick={() =>
                handleEditCategoryValue(
                  cat["id"],
                  cat["name"],
                  cat["categoryOrder"]
                )
              }
              title="Edit Category"
            >
              Edit
            </Link>
          </td>
        </tr>
      );
    });

    // Decorate with Bootstrap CSS
    return (
      <div className="category-table">
        <div className="catgory-table-header-top">
          {this.state.searchResult ? (
            <p className="category-count">
              Categories Found: {this.state.searchEntries.length}
            </p>
          ) : (
            <p className="category-count">
              Categories Found: {this.state.CategoryEntries.length}
            </p>
          )}
          <div className="category-find-comp">
            <label htmlFor="find" className="find-label">
              Find:{" "}
            </label>
            <input
              className="input-field-coupon-style input-field-bottom-border-coupon-style px-2"
              style={{ width: "50%" }}
              type="text"
              size="38"
              maxLength="100"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleFind(event);
                }
              }}
              onChange={(event) => {
                this.setState({ textValue: event.target.value });
              }}
            />
            <FaSearch
              style={{ color: "white" }}
              onClick={this.handleFind}
            ></FaSearch>
          </div>
          <button
            id="create-category-button-id"
            className="create-category-button-style"
            onClick={this.handlecreateCategory}
            title="Create new Category"
          >
            <span className="create-category-cta-style">+CREATE CATEGORY</span>
          </button>
        </div>
        <div className="category-table-container">
          <Table striped className="category-table-layout">
            {tableHeaders}
            <tbody>{tableBody}</tbody>
          </Table>
        </div>
        {this.state.errorMessage && (
          <div class="alert alert-danger" role="alert">
            Unable to find category, please try again with a different search
            criteria
          </div>
        )}

        <CategoryPagination></CategoryPagination>
        <EditCategoryModal
          modalIsOpen={this.state.editModalIsOpen}
          modalIsOpenHandle={this.handleEditModalIsOpen}
          categoryListRefresh={this.handleCategoryListRefresh}
          categoryID={this.state.categoryID}
          categoryName={this.state.categoryName}
          categoryOrder={this.state.categoryOrder}
        ></EditCategoryModal>
        <CreateCategoryModal
          modalIsOpen={this.state.createModalIsOpen}
          modalIsOpenHandle={this.handleModalIsOpen}
          categoryListRefresh={this.handleCategoryListRefresh}
        ></CreateCategoryModal>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    tableRows: state.category_rows,
    pageNo: state.Category_PageNo,
    numberOfEntriesPerPage: state.entriesPerPage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passData: (array) =>
      dispatch({
        type: "SET_CATEGORY_DATA",
        data: array,
      }),
    sendPageNo: (val) =>
      dispatch({
        type: "Category_SEND_PAGENUMBER",
        Category_Data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
