import React, { Component } from "react";
import NationalCouponTemplateCore from "./nationalCouponTemplateCore";
import DealerCouponPURLTemplate from "./dealerCouponPURLTemplate";
import "./../CouponsPreviewTab/couponCard.css";
import {CardInfo} from "./../CouponsPreviewTab/couponCard";

export default class TemplateCouponsForPDF extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let pdftemplate = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {this.props.couponsjson.map((item) => {
          return (
            <div id={item.id} style={{ margin: "8px" }}>
              <CardInfo entry={item}/>
              {item.category.name.toUpperCase().localeCompare("Featured Service".toUpperCase()) ? (
                <DealerCouponPURLTemplate
                  header={item.header}
                  priceHeader1={item.priceHeader1}
                  priceHeader2={item.priceHeader2}
                  priceHeader3={item.priceHeader3}
                  priceHeader4={item.priceHeader4}
                  includes={item.includes}
                  id={item.id}
                  expires={item.expires}
                  postPrice1={item.postPrice1}
                  postPrice2={item.postPrice2}
                  postPrice3={item.postPrice3}
                  postPrice4={item.postPrice4}
                  priceOverride1={item.priceOverride1}
                  priceOverride2={item.priceOverride2}
                  priceOverride3={item.priceOverride3}
                  priceOverride4={item.priceOverride4}
                  templateFor="PDF"
                ></DealerCouponPURLTemplate>
              ) : (
                <NationalCouponTemplateCore
                  header={item.header}
                  includes={item.includes}
                  imageUrl={item.imageUrl}
                  id={item.id}
                  expires={item.expires}
                ></NationalCouponTemplateCore>
              )}
            </div>
          );
        })}
      </div>
    );
    return (
      <div
        id="previewcouponPDF"
        style={{
          height: "1080px",
          width: this.props.isNationalOffer === "true" ? "750px" : "950px",
        }}
      >
        {pdftemplate}
      </div>
    );
  }
}
