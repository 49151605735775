import React, { Component } from "react";
import DealerCard from "./DealerCard";
import "./dealerPreviewTab.css";
import { Button } from "@material-ui/core";
import Spinner from "./../common/spinner";
import { connect } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { white } from "material-ui/styles/colors";

export class DealerPreviewTab extends Component {
  constructor() {
    super();
    this.state = {
      contentSlot: 1,
      dealerEntries: [],
      errorMessage: false,
      searchEntries: [],
      searchResult: false,
      textValue: "",
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.tableRows.length !== 0) {
      this.setState({ dealerEntries: nextprops.dealerEntries });
    }
  }
  handleShowMore() {
    this.setState({ contentSlot: this.state.contentSlot + 1 });
  }
  handleFind = () => {
    this.setState(
      { errorMessage: false, searchEntries: [], contentSlot: 1 },
      () => {
        this.state.searchResult = true;
        this.setState({});
        let response = this.state.dealerEntries;
        response.forEach((element) => {
          let obj = element;
          if (
            obj.dealerId.includes(this.state.textValue) ||
            obj.dealerName
              .trim()
              .toLowerCase()
              .includes(this.state.textValue.trim().toLowerCase())
          ) {
            this.state.searchEntries.push(obj);
          }
        });
        this.setState({});
        if (this.state.searchEntries.length === 0) {
          this.setState({ errorMessage: true });
        }
        //   this.props.passData(this.state.searchEntries);
        //   this.props.sendPageNo(1);
      }
    );
  };
  setShowMoreStatus(dealerRows){
      return (this.state.searchResult ?
      this.state.searchEntries.length === dealerRows.length
                    ? true
                    : false
      :
      this.state.dealerEntries.length === dealerRows.length
                    ? true
                    : false);

  }

  render() {
    let dealerRows = this.state.searchResult
      ? this.state.searchEntries.slice(0, this.state.contentSlot * 20)
      : this.state.dealerEntries.slice(0, this.state.contentSlot * 20);
    return (
      <div>
        {this.state.dealerEntries.length !== 0 ? (
          <>
            <div className="dealer-preview-header-top">
              <div style={{
              color: white,
              marginLeft: "20px",
              marginTop: "15px",
              fontFamily: "TitlingGothicFB Narrow",
              fontSize: "15px",
            }}>
                <p>
                  Dealers Found:{" "}
                  {this.state.searchResult
                    ? this.state.searchEntries.length
                    : this.state.dealerEntries.length}
                </p>
              </div>
              <div className="dealer-preview-find-comp">
                <label for="find" class="find-label">
                  Find:{" "}
                </label>
                <input
                  className="input-field-coupon-style dealer-input-field input-field-bottom-border-coupon-style px-2"
                  type="text"
                  size="38"
                  maxlength="100"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      this.handleFind(event);
                    }
                  }}
                  onChange={(event) => {
                    this.setState({ textValue: event.target.value });
                  }}
                />
                <FaSearch
                  style={{ color: "white" }}
                  onClick={this.handleFind}
                ></FaSearch>
              </div>
            </div>
            <div className="dealers_cardSection">
              {dealerRows.map((item) => (
                <DealerCard dealerDetails={item} />
              ))}
            </div>
            {this.state.errorMessage && (
          <div class="alert alert-danger" role="alert">
            Unable to find dealers, please try again with a different search
            criteria
          </div>
        )}
            <div className="dealers_showMore">
              <Button
                id="show_more"
                className="showMore_button"
                onClick={() => this.handleShowMore()}
                disabled={
                  this.setShowMoreStatus(dealerRows)
                }
                color="primary"
                variant="contained"
              >
                Show More
              </Button>
            </div>
          </>
        ) : (
          <div className="spinner">
            <Spinner value="Loading" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    tableRows: state.dealer_rows,
  };
};
export default connect(mapStateToProps, null)(DealerPreviewTab);
