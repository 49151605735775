const createCouponModalState = {
  refreshCouponTableStatus: false,
  createCouponModalStatus: false,
  createCouponModalOpenStatus: false,
};

const createCouponModalReducer = (state = createCouponModalState, action) => {
  if (action.type === "SET_REFRESH_COUPON_TABLE_STATUS") {
    return {
      ...state,
      refreshCouponTableStatus: action.data,
    };
  }
  if (action.type === "CREATE_COUPON_MODAL_STATUS") {
    return {
      ...state,
      createCouponModalStatus: action.data,
    };
  }
  if (action.type === "SET_CREATE_COUPON_MODAL_OPEN") {
    return {
      ...state,
      createCouponModalOpenStatus: action.data,
    };
  }
  return state;
};
export default createCouponModalReducer;
