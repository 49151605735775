import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "../../service";
import DealerCouponTemplate from "./dealerCouponTemplate";
import NationalCouponTemplate from "./nationalCouponTemplate";
import DealerCouponHTML6Template from "./dealerCouponHTML6Template";
import Cookies from "js-cookie";

export class TemplateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponData: { category: { name: "" } },
      couponPreviewType: "",
    };
  }

  componentDidMount() {
    this.fetchingCouponData(this.props);
  }
  componentWillReceiveProps(nextprops) {
    this.fetchingCouponData(nextprops);
  }
  fetchingCouponData(nextprops) {
    if (nextprops.selectedCoupon) {
      this.setState({ couponPreviewType: nextprops.couponPreviewType });
      let id = nextprops.comparisonCouponId
        ? nextprops.comparisonCouponId
        : nextprops.selectedCoupon.id;
      Axios.get(`/manageCoupons/api/coupons/${id}`, {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.state.couponData = response.data;
            let headerStr = this.state.couponData.header;
            this.state.couponData.header = headerStr;

            let includeStr = this.state.couponData.includes;

            this.state.couponData.includes = includeStr;

            this.setState({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {this.state.couponPreviewType.localeCompare("HTML6") ? (
          this.state.couponData.category.name
            .toUpperCase()
            .localeCompare("Featured Service".toUpperCase()) ? (
            <DealerCouponTemplate
              header={this.state.couponData.header}
              priceHeader1={this.state.couponData.priceHeader1}
              priceHeader2={this.state.couponData.priceHeader2}
              priceHeader3={this.state.couponData.priceHeader3}
              priceHeader4={this.state.couponData.priceHeader4}
              includes={this.state.couponData.includes}
              id={this.state.couponData.id}
              expires={this.state.couponData.expires}
              postPrice1={this.state.couponData.postPrice1}
              postPrice2={this.state.couponData.postPrice2}
              postPrice3={this.state.couponData.postPrice3}
              postPrice4={this.state.couponData.postPrice4}
              priceOverride1={this.state.couponData.priceOverride1}
              priceOverride2={this.state.couponData.priceOverride2}
              priceOverride3={this.state.couponData.priceOverride3}
              priceOverride4={this.state.couponData.priceOverride4}
            ></DealerCouponTemplate>
          ) : (
            <NationalCouponTemplate
              header={this.state.couponData.header}
              includes={this.state.couponData.includes}
              id={this.state.couponData.id}
              imageUrl={this.state.couponData.imageUrl}
              expires={this.state.couponData.expires}
              imageAltText={this.state.couponData.imageAltText}
            ></NationalCouponTemplate>
          )
        ) : this.state.couponData.category.name
            .toUpperCase()
            .localeCompare("Featured Service".toUpperCase()) ? (
          <DealerCouponHTML6Template
            header={this.state.couponData.header}
            // includes={this.state.couponData.includes}
            priceHeader1={this.state.couponData.priceHeader1}
            priceHeader2={this.state.couponData.priceHeader2}
            priceHeader3={this.state.couponData.priceHeader3}
            priceHeader4={this.state.couponData.priceHeader4}
            includes={this.state.couponData.includes}
            id={this.state.couponData.id}
            expires={this.state.couponData.expires}
            postPrice1={this.state.couponData.postPrice1}
            postPrice2={this.state.couponData.postPrice2}
            postPrice3={this.state.couponData.postPrice3}
            postPrice4={this.state.couponData.postPrice4}
            priceOverride1={this.state.couponData.priceOverride1}
            priceOverride2={this.state.couponData.priceOverride2}
            priceOverride3={this.state.couponData.priceOverride3}
            priceOverride4={this.state.couponData.priceOverride4}
          ></DealerCouponHTML6Template>
        ) : (
          <NationalCouponTemplate
            header={this.state.couponData.header}
            includes={this.state.couponData.includes}
            id={this.state.couponData.id}
            imageUrl={this.state.couponData.imageUrl}
            expires={this.state.couponData.expires}
            imageAltText={this.state.couponData.imageAltText}
          ></NationalCouponTemplate>
        )}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    selectedCoupon: state.selectedRow,
    couponPreviewType: state.couponPreviewType,
  };
};
export default connect(mapStateToProps)(TemplateCoupon);
