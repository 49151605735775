import React from "react";
import CouponCard from "./couponCard";
import "./couponsPreviewContainer.css";
const CouponsPreviewContainer = (props) => {
  const couponcardList = props.entries.map((item) => (
    <CouponCard entry={item} label={props.label} />
  ));
  return (
    <div className="coupon-preview-wrapper">
      <div>
        <span className="create-preview-label"> {props.label}</span>
      </div>
      <div className="coupon-list-container">{couponcardList}</div>
    </div>
  );
};
export default CouponsPreviewContainer;
