import React, { Component } from "react";
import { connect } from "react-redux";
import "./dealerTable.css";
import Table from "react-bootstrap/Table";
import DealerPagination from "./dealerPagination";
import { FaSearch } from "react-icons/fa";
import { white } from "material-ui/styles/colors";
import activeCheckmark from "../couponsTab/icons/active-true.svg";

export class DealerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer_rows: [],
      selectedRow: null,
      pageNum: 1,
      dealers: [],
      dealerID: "",
      dealerName: "",
      DealerEntries: [],
      createModalIsOpen: false,
      editModalIsOpen: false,
      searchEntries: [],
      errorMessage: false,
      textValue: "",
      stillSearching: false,
      searchResult: false,
    };
    // this.fetchDealer= this.fetchDealer.bind(this);
    this.handleAscSorting = this.handleAscSorting.bind(this);
    this.handleDescSorting = this.handleDescSorting.bind(this);
  }
  componentDidMount() {
    this.setState({ stillSearching: true });
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.tableRows.length !== 0) {
      this.setState({
        stillSearching: false,
        DealerEntries: nextprops.dealerEntries,
      });
    }
    this.setState({ dealer_rows: nextprops.tableRows });
    let startIndex = nextprops.pageNo;
    let val = nextprops.numberOfEntriesPerPage;
    this.state.dealers = nextprops.tableRows.slice(
      (startIndex - 1) * val,
      startIndex * val
    );
    this.setState({});
  }

  handleDescSorting(column) {
    this.setState({ sortedFlag: true });
    let sortedRows = Object.assign([], this.props.tableRows);
    if (column === "Dealer ID") {
      sortedRows.sort((a, b) => {
        return b.dealerId.localeCompare(a.dealerId);
      });
    } else if (column === "Dealer Name") {
      sortedRows.sort((a, b) => {
        return b.dealerName.localeCompare(a.dealerName);
      });
    } else if (column === "National Offers?") {
      sortedRows.sort((a, b) => {
        return b.nationalOffersIncluded - a.nationalOffersIncluded;
      });
    } else if (column === "Platinum Revolution?") {
      sortedRows.sort((a, b) => {
        return (
          b.platinumRevolutionParticipant - a.platinumRevolutionParticipant
        );
      });
    } else if (column === "Premium Revolution?") {
      sortedRows.sort((a, b) => {
        return b.premiumRevolutionParticipant - a.premiumRevolutionParticipant;
      });
    }
    this.props.passData(sortedRows);
    this.props.sendPageNo(1);
  }

  handleAscSorting(column) {
    this.setState({ sortedFlag: true });
    let sortedRows = Object.assign([], this.props.tableRows);
    if (column === "Dealer ID") {
      sortedRows.sort((a, b) => {
        return a.dealerId.localeCompare(b.dealerId);
      });
    } else if (column === "Dealer Name") {
      sortedRows.sort((a, b) => {
        return a.dealerName.localeCompare(b.dealerName);
      });
    } else if (column === "National Offers?") {
      sortedRows.sort((a, b) => {
        return a.nationalOffersIncluded - b.nationalOffersIncluded;
      });
    } else if (column === "Platinum Revolution?") {
      sortedRows.sort((a, b) => {
        return (
          a.platinumRevolutionParticipant - b.platinumRevolutionParticipant
        );
      });
    } else if (column === "Premium Revolution?") {
      sortedRows.sort((a, b) => {
        return a.premiumRevolutionParticipant - b.premiumRevolutionParticipant;
      });
    }
    this.props.passData(sortedRows);
    this.props.sendPageNo(1);
  }

  handleFind = () => {
    this.setState({ errorMessage: false, searchEntries: [] }, () => {
      this.state.searchResult = true;
      this.setState({});
      let response = this.state.DealerEntries;
      response.forEach((element) => {
        let obj = element;
        if (
          obj.dealerId.includes(this.state.textValue) ||
          obj.dealerName
            .trim()
            .toLowerCase()
            .includes(this.state.textValue.trim().toLowerCase())
        ) {
          this.state.searchEntries.push(obj);
        }
      });
      this.setState({});
      if (this.state.searchEntries.length === 0) {
        this.setState({ errorMessage: true });
      }
      this.props.passData(this.state.searchEntries);
      this.props.sendPageNo(1);
    });
  };
  render() {
    let tableHeaders = (
      <thead>
        <tr>
          <th style={{ whiteSpace: "nowrap" }}>
            Dealer ID
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-up.png"
                id="arrow-up-id"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleDescSorting("Dealer ID")}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-down.png"
                id="arrow-down-id"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleAscSorting("Dealer ID")}
              />
            </button>
          </th>
          <th>
            Dealer Name
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-up.png"
                id="arrow-up-name"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleDescSorting("Dealer Name")}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-down.png"
                id="arrow-down-name"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleAscSorting("Dealer Name")}
              />
            </button>
          </th>
          <th style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            National Offers?
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-up.png"
                id="arrow-up-nationalOffers"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleDescSorting("National Offers?")}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-down.png"
                id="arrow-down-nationalOffers"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleAscSorting("National Offers?")}
              />
            </button>
          </th>
          <th style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            Platinum Revolution?
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-up.png"
                id="arrow-up-platinumRevolution"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleDescSorting("Platinum Revolution?")}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-down.png"
                id="arrow-down-platinumRevolution"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleAscSorting("Platinum Revolution?")}
              />
            </button>
          </th>
          <th style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            Premium Revolution?
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-up.png"
                id="arrow-up-premiumRevolution"
                alt="up_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleDescSorting("Premium Revolution?")}
              />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                padding: "0",
                height: "5px",
              }}
            >
              <img
                src="./../arrow-down.png"
                id="arrow-down-premiumRevolution"
                alt="down_arrow"
                style={{ height: "10px", width: "10px" }}
                onClick={() => this.handleAscSorting("Premium Revolution?")}
              />
            </button>
          </th>
        </tr>
      </thead>
    );
    let tableBody = this.state.dealers.map((del) => {
      return (
        <tr key={del["dealerId"]}>
          <td>{del["dealerId"]}</td>
          <td>{del["dealerName"]}</td>
          <td align="center">
            {del["nationalOffersIncluded"] ? (
              <img src={activeCheckmark} alt="coupon-is-active" />
            ) : (
              ""
            )}
          </td>
          <td align="center">
            {del["platinumRevolutionParticipant"] ? (
              <img src={activeCheckmark} alt="coupon-is-active" />
            ) : (
              ""
            )}
          </td>
          <td align="center">
            {del["premiumRevolutionParticipant"] ? (
              <img src={activeCheckmark} alt="coupon-is-active" />
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });

    // Decorate with Bootstrap CSS
    return (
      <div className="dealer-table">
        <div className="dealer-table-header-top">
          <div
            style={{
              color: white,
              marginLeft: "20px",
              marginTop: "15px",
              fontFamily: "TitlingGothicFB Narrow",
              fontSize: "15px",
            }}
          >
            {this.state.stillSearching ? (
              <p>Searching...</p>
            ) : this.state.searchResult ? (
              <p>Dealers Found: {this.state.searchEntries.length}</p>
            ) : (
              <p>Dealers Found: {this.state.DealerEntries.length}</p>
            )}
          </div>
          <div className="dealer-find-comp">
            <label for="find" class="find-label">
              Find:{" "}
            </label>
            <input
              className="input-field-coupon-style dealer-input-field input-field-bottom-border-coupon-style px-2"
              type="text"
              size="38"
              maxlength="100"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleFind(event);
                }
              }}
              onChange={(event) => {
                this.setState({ textValue: event.target.value });
              }}
            />
            <FaSearch
              style={{ color: "white" }}
              onClick={this.handleFind}
            ></FaSearch>
          </div>
          {/* <button className="create-category-button-style" onClick={this.handlecreateCategory}><span className="create-category-cta-style">+CREATE CATEGORY</span></button> */}
        </div>
        <div className="dealer-table-component">
        <Table striped >
          {tableHeaders}
          <tbody>{tableBody}</tbody>
        </Table>
        </div>
        {this.state.errorMessage && (
          <div class="alert alert-danger" role="alert">
            Unable to find dealers, please try again with a different search
            criteria
          </div>
        )}
        <DealerPagination></DealerPagination>
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    tableRows: state.dealer_rows,
    pageNo: state.Dealer_PageNo,
    numberOfEntriesPerPage: state.entriesPerPage,
    FilterResults: {
      text: state.textValue,
      country: state.countryValue,
      language: state.languageValue,
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passRow: (val) =>
      dispatch({
        type: "SET_SELECTED_ROW",
        data: val,
      }),
    passData: (array) =>
      dispatch({
        type: "SET_DEALER_DATA",
        data: array,
      }),
    sendPageNo: (val) =>
      dispatch({
        type: "DEALER_SEND_PAGENUMBER",
        Dealer_Data: val,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerTable);
