import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { createStore } from "redux";
import rootReducer from "./store/index";
import { Provider } from "react-redux";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const store = createStore(rootReducer);

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.env.APPINSIGHTS_INSTRUMENTATIONKEY,
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
if (window.location.protocol === "http:") {
  window.location =
    "https://" + window.location.hostname + window.location.pathname;
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
