import Cookies from "js-cookie";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Axios from "../service";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: "pending",
      token: Cookies.get("token"),
    };
  }
  componentDidMount() {
    this.validateToken();
  }
  componentWillReceiveProps() {
    this.validateToken();
  }
  validateToken() {
    if (this.state.token) {
      Axios.get("/manageCoupons/api/validateToken", {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({ isLogin: "success" });
          } else {
            this.setState({ isLogin: "failure" });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({ isLogin: "failure" });
        });
    } else {
      this.setState({ isLogin: "failure" });
    }
  }
  render() {
    const { component: Comp, render, ...rest } = this.props;
    if (this.state.isLogin === "pending") {
      return <div></div>;
    } else {
      if (Comp) {
        return (
          // Show the component only when the user is logged in
          // Otherwise, redirect the user to /login page
          <Route
            {...rest}
            render={(props) =>
              this.state.isLogin === "success" ? (
                <Comp {...props} />
              ) : (
                <Redirect to="/manageCoupons/login" />
              )
            }
          />
        );
      } else {
        return this.state.isLogin === "success" ? (
          <Route render={render} />
        ) : (
          <Redirect to="/manageCoupons/login" />
        );
      }
    }
  }
}

export default PrivateRoute;
