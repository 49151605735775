import React, { Component } from "react";

export default class Spinner extends Component {

render(){
    return(
        <div className="d-flex justify-content-center">
            <div>
            <strong>{this.props.value} </strong>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">{this.props.value}...</span>
            </div>
        </div>
        );
        }
}