import React, { Component } from "react";
import "./ChangeLogModal.css";
import { Table } from "react-bootstrap";

export default class ComparisonSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compareData: [],
      version1: "",
      version2: "",
      iscomparedNull:false
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.compareData.length > 0) {
      this.setState({
        compareData: nextprops.compareData,
        version1: nextprops.versions.version1,
        version2: nextprops.versions.version2,
        iscomparedNull:nextprops.iscomparedNull
      });
    } else {
      this.setState({ compareData: [], version1: "", version2: "",iscomparedNull:nextprops.iscomparedNull });
    }
  }
  getEnvironmentalValue(item) {
    return this.tableRowisplay(
      item.label,
      item.version_1.indicator,
      item.version_2.indicator
    );
  }
  getCategoryValue(item) {
    return this.tableRowisplay(
      item.label,
      item.version_1.name,
      item.version_2.name
    );
  }
  getProgramsValue(item) {
    return this.tableRowisplay(
      item.label,
      this.getProgramString(item.version_1),
      this.getProgramString(item.version_2)
    );
  }
  getProgramString(version) {
    let resultString = version.reduce((acc, item) => {
      acc = acc + "," + item.program;
      return acc;
    }, "");
    return resultString.substring(1, resultString.length);
  }
  getLanguageValue(item) {
    if (item === "en") {
      return "English";
    }
    if (item === "es") {
      return "Spanish";
    }
    if (item === "fr") {
      return "French";
    }
  }
  getCountryValue(item) {
    if (item === "ca") {
      return "Canada";
    }
    if (item === "us") {
      return "United States";
    }
    if (item === "mx") {
      return "Mexico";
    }
    if (item === "pr") {
      return "Puerto Rico";
    }
  }
  getlangaugeDisplay(item) {
    return this.tableRowisplay(
      item.label,
      this.getLanguageValue(item.version_1),
      this.getLanguageValue(item.version_2)
    );
  }
  tableRowisplay(label, version1Data, version2Data) {
    return (
      <tr className="field_section" key={label}>
        <td className="field_header">{label}</td>
        <td className="fieldData actual"> {version1Data.toString()}</td>
        <td className="fieldData modified">{version2Data.toString()}</td>
      </tr>
    );
  }
  getCountryDisplay(item) {
    return this.tableRowisplay(
      item.label,
      this.getCountryValue(item.version_1),
      this.getCountryValue(item.version_2)
    );
  }
  render() {
    return (
      <div className="comaprisonSection_container">
        {this.state.compareData.length === 0 ? (this.state.iscomparedNull? (
          <div className="comparisonSection_noResponseSection">
            <h3 className="comparison_header">Comparison Section</h3>
            The two versions are identical!
          </div>
        ):
        (
          <div className="comparisonSection_noResponseSection">
            <h3 className="comparison_header">Comparison Section</h3>
            Select any 2 versions for comparison
          </div>
        ) ): (
          <div className="comparisonSection_responseSection">
            <h3 className="comparison_header">Comparison Section</h3>
            <Table>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Version {this.state.version1}</th>
                  <th>Version {this.state.version2}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.compareData.map((item) => {
                  if (item.label === "environmental") {
                    return this.getEnvironmentalValue(item);
                  } else if (item.label === "category") {
                    return this.getCategoryValue(item);
                  } else if (item.label === "programs") {
                    return this.getProgramsValue(item);
                  } else if (item.label === "Language") {
                    return this.getlangaugeDisplay(item);
                  } else if (item.label === "Country") {
                    return this.getCountryDisplay(item);
                  } else {
                    return this.tableRowisplay(
                      item.label,
                      item.version_1,
                      item.version_2
                    );
                  }
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}
