import React, { Component } from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import { Table } from "react-bootstrap";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import "./RestoreReviewModal.css";
import { getUsername } from "../ScheduleTab/util.js";
import Axios from "../../service";
import Cookies from "js-cookie";
export default class RestoreReviewModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      isRestoreDisabled: false,
      errorStatus: false,
      errorMessage: "",
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "test") {
      Modal.setAppElement("#root");
    }
  }
  closeModal() {
    this.setState({
      modalIsOpen: false,
      isRestoreDisabled: false,
      errorStatus: false,
      errorMessage: "",
    });
    this.props.modalIsOpenHandle(false);
  }
  getEnvironmentalValue(item) {
    return this.tableRowisplay(item[0], item[1].indicator);
  }
  getCategoryValue(item) {
    return this.tableRowisplay(item[0], item[1].name);
  }
  getProgramsValue(item) {
    return this.tableRowisplay(item[0], this.getProgramString(item[1]));
  }
  getProgramString(version) {
    let resultString = version.reduce((acc, item) => {
      acc = acc + "," + item.program;
      return acc;
    }, "");
    return resultString.substring(1, resultString.length);
  }
  getLanguageValue(item) {
    if (item === "en") {
      return "English";
    }
    if (item === "es") {
      return "Spanish";
    }
    if (item === "fr") {
      return "French";
    }
  }
  getCountryValue(item) {
    if (item === "ca") {
      return "Canada";
    }
    if (item === "us") {
      return "United States";
    }
    if (item === "mx") {
      return "Mexico";
    }
    if (item === "pr") {
      return "Puerto Rico";
    }
  }
  getlangaugeDisplay(item) {
    return this.tableRowisplay(item[0], this.getLanguageValue(item[1]));
  }
  tableRowisplay(label, data) {
    return (
      <tr className="field_section" key={label}>
        <td className="field_header">{label}</td>
        <td className="fieldData modified">
          {" "}
          {data === null ? data : data.toString()}
        </td>
      </tr>
    );
  }
  getCountryDisplay(item) {
    return this.tableRowisplay(item[0], this.getCountryValue(item[1]));
  }
  handleRestoreButton = () => {
    this.setState({ isRestoreDisabled: true });
    this.apicall();
  };
  apicall() {
    this.setState({ errorStatus: false, errorMessage: "" });
    let url = "/manageCoupons/api/" + this.props.restoreData.id;
    Axios({
      method: "PUT",
      url,
      data: this.props.restoreData,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        username: getUsername(),
        Authorization: Cookies.get("token"),
      },
    })
      .then(() => {
        this.setState({ isRestoreDisabled: true });
        this.closeModal();
        this.props.restoreStatus(true);
      })
      .catch((error) => {
        this.setState({
          errorStatus: true,
          errorMessage: "Unable to restore this version.",
          isRestoreDisabled: false,
        });
        console.log(error);
      });
  }
  render() {
    let data = Object.entries(this.props.restoreData);
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.closeModal}
          // style={customStyles}
          style={{ border: "none" }}
          className="restore-review-modal"
          contentLabel="Restore Review Modal"
        >
          <div className="restore__headerSection">
            <h3>Restore Review</h3>
            <CloseIcon
              id="close_modal_icon"
              fontSize="large"
              onClick={this.closeModal}
            />
          </div>
          <div className="restore__couponSection">
            <Table>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  if (item[0] === "environmental") {
                    return this.getEnvironmentalValue(item);
                  } else if (item[0] === "category") {
                    return this.getCategoryValue(item);
                  } else if (item[0] === "programs") {
                    return this.getProgramsValue(item);
                  } else if (item[0] === "language") {
                    return this.getlangaugeDisplay(item);
                  } else if (item[0] === "country") {
                    return this.getCountryDisplay(item);
                  } else {
                    return this.tableRowisplay(item[0], item[1]);
                  }
                })}
              </tbody>
            </Table>
          </div>
          <div className="restore_operationSection">
            {this.state.errorStatus && (
              <p className="errorMessage">{this.state.errorMessage}</p>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRestoreButton}
              endIcon={<SendIcon />}
              disabled={this.state.isRestoreDisabled}
            >
              Restore
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.closeModal}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
