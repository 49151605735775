import React, { Component } from "react";
import "./css/scheduler.css";

export default class NextExecution extends Component {
  getNextScheduleDisplay = (schedulerName, schedulerType) => {
    if (
      schedulerName !== "ALL" &&
      schedulerType !== "ALL" &&
      this.props.schedulerDetails[schedulerName + "-" + schedulerType].type ===
        "SCHEDULER"
    ) {
      let scheduledTime =
        this.props.schedulerDetails[schedulerName + "-" + schedulerType]
          .executionTime;
      return (
        <div className="row col-12 mb-3">
          <span className="badge badge-secondary shadow-lg py-3">
            Next Scheduled @ {scheduledTime}
          </span>
        </div>
      );
    } else {
      return "";
    }
  };
  render() {
    return this.getNextScheduleDisplay(
      this.props.schedulerName,
      this.props.schedulerType
    );
  }
}
