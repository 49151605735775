import Chart from "react-google-charts";
import Axios from "../../service";
import React, { Component } from "react";
import Spinner from "../common/spinner";
import { connect } from "react-redux";
import Cookies from "js-cookie";

export class CategoryChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }
  componentDidMount() {
    this.fetchApi();
  }
  fetchApi() {
    Axios.get("/metric/api/coupon/category-metric", {
      headers: {
        Authorization: Cookies.get("token"),
      },
    })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  processRowData(data) {
    let resultData = data.reduce((acc, item) => {
      acc.push([item.category_name, item.coupon_count]);
      return acc;
    }, []);
    return resultData;
  }

  render() {
    return (
      <div>
        {this.state.rowData.length === 0 ? (
          <div
            style={{
              height: "400px",
              width: "1000px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Spinner value="Loading" />{" "}
          </div>
        ) : (
          <Chart
            width={"1000px"}
            height={"400px"}
            chartType="ColumnChart"
            loader={
              <div
                style={{
                  height: "400px",
                  width: "1000px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Spinner value="Loading" />{" "}
              </div>
            }
            data={[
              ["Coupon", "Count"],
              ...this.processRowData(this.state.rowData),
            ]}
            options={{
              title: "Categories",
              legend: "none",
              hAxis: {
                showTextEvery: 1,
                slantedText: true,
              },
            }}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;
                    this.props.applyDashboardFilter({
                      type: "Category",
                      value: this.processRowData(this.state.rowData)[row][0],
                    });
                  }
                },
              },
            ]}
            //   rootProps={{ "data-testid": "1" }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyDashboardFilter: (val) =>
      dispatch({
        type: "APPLY_DASHBOARD_FILTER",
        data: val,
      }),
  };
};
export default connect(null, mapDispatchToProps)(CategoryChart);
