import reducer from "./reducers";
import filterReducer from "./filterReducer";
import resetFilterReducer from "./resetFilterReducer";
import previewCouponTabReducer from "./previewCouponTabReducer";
import schedulerReducer from "./schedulerReducer";
import ChangeLogReducer from "./changeLogReducer";
import createCouponModalReducer from "./createCouponModalReducer";
import dashboardReducer from "./dashboardReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  reducer,
  filterReducer,
  resetFilterReducer,
  previewCouponTabReducer,
  schedulerReducer,
  ChangeLogReducer,
  createCouponModalReducer,
  dashboardReducer
});

export default rootReducer;
