import React, { useEffect, useState } from 'react';
import "./userDetail.css";
import Cookies from "js-cookie";
import Axios from "../../service";


const SessionLog = () => {
    const [userSession, setUserSession] = useState([]);
    const getUserSession = async() => {
        const allSessionInfo = await Axios.get("/manageCoupons/api/profile/sessions", {headers: {
            Authorization: Cookies.get("token"),
          },});
          setUserSession(allSessionInfo.data);
    }
    useEffect(() => {
        getUserSession();
    },[]) 

    const sessionData = userSession.map((item, index) => {
        const ct = new Date(item.expiryTime*1000).toString();
        const humanDateFormat = ct.replace('GMT+0530', '');
        return (
            <>
                <tr key={index}>
                    <td>{item.uuId}</td>
                    <td>{humanDateFormat}</td>
                </tr>
            </>
        )
    });
    
    return (
        <>
            <div className="row">

                <div className="col-12">
                    <div className="session-log-box table-responsive text-center">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Session Name</th>
                                    <th scope="col">Time & Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessionData}  
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>   
        </>
    )
}

export default SessionLog
