import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "../../../service";
import DealerCouponTemplateCore from "./../../previewCoupon/dealerCouponTemplateCore";
import NationalCouponTemplateCore from "./../../previewCoupon/nationalCouponTemplateCore";
import DealerCouponPURLTemplate from "../../previewCoupon/dealerCouponPURLTemplate";
import Cookies from "js-cookie";

export class TemplateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markup: "",
      couponData: { category: { name: "" } },
      editCouponPreviewType: "",
    };
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.selectedCoupon.id) {
      this.setState({ editCouponPreviewType: nextprops.editCouponPreviewType });
      let id = nextprops.selectedCoupon.id;
      Axios.get(`/manageCoupons/api/coupons/${id}`, {
        headers: {
          Authorization: Cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            // this.state.couponData = response.data;
            this.setState({
              couponData: response.data,
            });
            let headerStr = this.state.couponData.header.replace("<br>", "");
            headerStr = headerStr.replace("</br>", "");
            // this.state.couponData.header = headerStr;
            this.setState({
              couponData: {
                ...this.state.couponData,
                header: headerStr,
              },
            });

            let includeStr = this.state.couponData.includes.replace("<b>", "");
            includeStr = includeStr.replace("</b>", "");
            this.state.couponData.includes = includeStr;

            this.setState({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {this.state.editCouponPreviewType.localeCompare("HTML6") ? (
          this.state.couponData.category.name
            .toUpperCase()
            .localeCompare("Featured Service".toUpperCase()) ? (
            <DealerCouponTemplateCore
              header={this.state.couponData.header}
              priceHeader1={this.state.couponData.priceHeader1}
              priceHeader2={this.state.couponData.priceHeader2}
              priceHeader3={this.state.couponData.priceHeader3}
              priceHeader4={this.state.couponData.priceHeader4}
              includes={this.state.couponData.includes}
              id={this.state.couponData.id}
              expires={this.state.couponData.expires}
              postPrice1={this.state.couponData.postPrice1}
              postPrice2={this.state.couponData.postPrice2}
              postPrice3={this.state.couponData.postPrice3}
              postPrice4={this.state.couponData.postPrice4}
              priceOverride1={this.state.couponData.priceOverride1}
              priceOverride2={this.state.couponData.priceOverride2}
              priceOverride3={this.state.couponData.priceOverride3}
              priceOverride4={this.state.couponData.priceOverride4}
            ></DealerCouponTemplateCore>
          ) : (
            <NationalCouponTemplateCore
              header={this.state.couponData.header}
              includes={this.state.couponData.includes}
              id={this.state.couponData.id}
              expires={this.state.couponData.expires}
              imageUrl={this.state.couponData.imageUrl}
              imageAltText={this.state.couponData.imageAltText}
            ></NationalCouponTemplateCore>
          )
        ) : this.state.couponData.category.name
            .toUpperCase()
            .localeCompare("Featured Service".toUpperCase()) ? (
          <DealerCouponPURLTemplate
            header={this.state.couponData.header}
            // includes={this.state.couponData.includes}
            priceHeader1={this.state.couponData.priceHeader1}
            priceHeader2={this.state.couponData.priceHeader2}
            priceHeader3={this.state.couponData.priceHeader3}
            priceHeader4={this.state.couponData.priceHeader4}
            includes={this.state.couponData.includes}
            id={this.state.couponData.id}
            expires={this.state.couponData.expires}
            postPrice1={this.state.couponData.postPrice1}
            postPrice2={this.state.couponData.postPrice2}
            postPrice3={this.state.couponData.postPrice3}
            postPrice4={this.state.couponData.postPrice4}
            priceOverride1={this.state.couponData.priceOverride1}
            priceOverride2={this.state.couponData.priceOverride2}
            priceOverride3={this.state.couponData.priceOverride3}
            priceOverride4={this.state.couponData.priceOverride4}
          ></DealerCouponPURLTemplate>
        ) : (
          <NationalCouponTemplateCore
            header={this.state.couponData.header}
            includes={this.state.couponData.includes}
            id={this.state.couponData.id}
            expires={this.state.couponData.expires}
            imageUrl={this.state.couponData.imageUrl}
            imageAltText={this.state.couponData.imageAltText}
          ></NationalCouponTemplateCore>
        )}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  const state = {
    ...states.reducer,
    ...states.filterReducer,
    ...states.resetFilterReducer,
  };
  return {
    selectedCoupon: state.selectedRow,
    editCouponPreviewType: state.editCouponPreviewType,
  };
};
export default connect(mapStateToProps)(TemplateCoupon);
