export const convertCouponArrayToCouponObjectArray = (coupons) => {
  let objectArrayCoupons = [];
  coupons.map((e) => {
    objectArrayCoupons.push({ value: e, label: e });
  });
  return objectArrayCoupons;
};

export const ValidateCouponIsPresent = (
  coupon,
  startDate,
  endDate,
  eventList
) => {
  let abc = eventList.filter(
    (event) =>
      event.coupon_id === coupon.value &&
      dateValidator(
        event.start_date.split("T")[0],
        event.end_date.split("T")[0],
        startDate
      )
  );
  return abc.length > 0 ? true : false;
};
export const ValidateCouponIsPresentForEdit = (
  coupon,
  startDate,
  endDate,
  eventList,
  eventId
) => {
  let abc = eventList.filter(
    (event) =>
      event.coupon_id === coupon.value &&
      dateValidator(
        event.start_date.split("T")[0],
        event.end_date.split("T")[0],
        startDate
      ) &&
      event.event_id !== eventId
  );
  return abc.length > 0 ? true : false;
};

export const getUsername = () => {
  let CryptoJS = require("crypto-js");
  let ciphertext_username = localStorage.getItem("username_mcl");
  let plaintext_username = "bthorp";
  if (ciphertext_username) {
    let bytes_username = CryptoJS.AES.decrypt(
      ciphertext_username.toString(),
      "@$rf15)=83GghvH$njk_%#"
    );
    plaintext_username = bytes_username.toString(CryptoJS.enc.Utf8);
  }
  return plaintext_username;
};
const dateValidator = (startDate, endDate, dateCheck) => {
  let D1 = new Date(startDate);
  let D2 = new Date(endDate);
  let D3 = new Date(dateCheck);

  if (D3.getTime() <= D2.getTime() && D3.getTime() >= D1.getTime()) {
    return true;
  } else {
    return false;
  }
};
export const getEventListByMonths = (eventList) => {
  if (eventList.length > 0) {
    let startdateArray = eventList.map((e) => {
      let start_date = e.start_date.split("T")[0];
      let month = new Date(start_date).toLocaleString("default", {
        month: "long",
      });
      let year = new Date(start_date).getFullYear().toString();
      return { label: month + " " + year, event: e };
    });

    let finalArray = startdateArray.reduce((acc, d) => {
      const found = acc.find((a) => a.label === d.label);
      if (!found) {
        acc.push({ label: d.label, events: [d.event] });
      } else {
        found.events.push(d.event);
      }
      return acc;
    }, []);

    return finalArray;
  } else {
    return eventList;
  }
};
export const getMaxDate= ()=>{
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 2, month, day);
    return c.toISOString().split('T')[0];
}

export const  findCouponEvent = (searchCouponEvent, eventlist) =>{
  let searchedArray=[];
  if(searchCouponEvent.trim()!==""){
    searchedArray= eventlist.filter(e=>e.coupon_id.toLowerCase()===searchCouponEvent.toLowerCase());
  }
  return searchedArray;
}
export const inputDateValidator =(inputDate)=>{
  let minStartDate= new Date( new Date().toISOString().split('T')[0]);
  let maxEndDate=  new Date(getMaxDate());
  let inputDateValue= new Date(inputDate);
  if(inputDateValue.getTime() <= maxEndDate.getTime() && inputDateValue.getTime() >= minStartDate.getTime()){
    return true;
  }
  else{
    return false;
  }
}
export const expireDateValidator =(inputDate)=>{
  let minStartDate= new Date( new Date().toISOString().split('T')[0]);
  let inputDateValue= new Date(inputDate);
  if(inputDateValue.getTime() >= minStartDate.getTime()){
    return true;
  }
  else{
    return false;
  }
}