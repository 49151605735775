import React,{useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ScheduleTable from "./ScheduleTable";
import "./ScheduleEvent.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    maxHeight: '100vh',
    minHeight: 250,
    border: "none",
    boxShadow: "1px 3px 3px 1px lightgrey",
    marginBottom:'20px'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [eventlist,setEventlist]=  React.useState(props.eventList);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(eventlist.length!==props.eventList.length && props.eventList.length > 0){
      setValue(0);
      setEventlist(props.eventList);
    }

  })
  return (
    <div className={"tabs-responsive "+classes.root} >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {props.eventList && props.eventList.map((e, index) => (
          <Tab label={e.label} key={e.label} {...a11yProps(index)} />
        ))}
      </Tabs>
      {props.eventList && props.eventList.map((e, index) => (
        <TabPanel key={e.label} value={value} index={index} style={{width:'80vw'}}>
          <ScheduleTable month={e.label} events={e.events} />
        </TabPanel>
      ))}
    </div>
  );
}
