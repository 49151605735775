const resetFilterState = {
  activeColumnData: [],
  publishColumnData: [],
  categoryColumnData: [],
  activeColumnTableEntries: [],
  publishColumnTableEntries: [],
  categoryColumnTableEntries: [],
  dateFilterTableData: [],
  countryFilterTableData: [],
  languageFilterTableData: [],
  resetActiveFilter: false,
  resetPublishFilter: false,
  resetCategoryFilter: false,
  resetAllFilter: false,
  resetAllData: [],
  resetDateFilter: false,
  resetCountryFilter: false,
  resetLanguageFilter: false,
  isResetFilterStatus: false
};

const resetFilterReducer = (state = resetFilterState, action) => {
  if (action.type === "SET_ACTIVE_COLUMN_FILTER_DATA") {
    return {
      ...state,
      activeColumnData: action.data,
    };
  }
  if (action.type === "SET_PUBLISH_COLUMN_FILTER_DATA") {
    return {
      ...state,
      publishColumnData: action.data,
    };
  }
  if (action.type === "SET_CATEGORY_COLUMN_FILTER_DATA") {
    return {
      ...state,
      categoryColumnData: action.data,
    };
  }
  if (action.type === "SET_ACTIVE_COLUMN_TABLE_DATA") {
    return {
      ...state,
      activeColumnTableEntries: action.data,
    };
  }
  if (action.type === "SET_PUBLISH_COLUMN_TABLE_DATA") {
    return {
      ...state,
      publishColumnTableEntries: action.data,
    };
  }
  if (action.type === "SET_CATEGORY_COLUMN_TABLE_DATA") {
    return {
      ...state,
      categoryColumnTableEntries: action.data,
    };
  }
  if (action.type === "RESET_ACTIVE_FILTER") {
    return {
      ...state,
      resetActiveFilter: action.data,
    };
  }
  if (action.type === "RESET_PUBLISH_FILTER") {
    return {
      ...state,
      resetPublishFilter: action.data,
    };
  }
  if (action.type === "RESET_CATEGORY_FILTER") {
    return {
      ...state,
      resetCategoryFilter: action.data,
    };
  }
  if (action.type === "RESET_ALL_FILTER") {
    return {
      ...state,
      resetAllFilter: action.data,
      resetAllData: action.dataArray,
    };
  }
  if (action.type === "RESET_FILTER_STATUS") {
    return {
      ...state,
      isResetFilterStatus: action.data
    };
  }
  if (action.type === "SET_DATE_FILTER_TABLE_DATA") {
    return {
      ...state,
      dateFilterTableData: action.data,
    };
  }
  if (action.type === "RESET_DATE_FILTER") {
    return {
      ...state,
      resetDateFilter: action.data,
    };
  }

  if (action.type === "SET_COUNTRY_FILTER_TABLE_DATA") {
    return {
      ...state,
      countryFilterTableData: action.data,
    };
  }
  if (action.type === "RESET_COUNTRY_FILTER") {
    return {
      ...state,
      resetCountryFilter: action.data,
    };
  }

  if (action.type === "SET_LANGUAGE_FILTER_TABLE_DATA") {
    return {
      ...state,
      languageFilterTableData: action.data,
    };
  }
  if (action.type === "RESET_LANGUAGE_FILTER") {
    return {
      ...state,
      resetLanguageFilter: action.data,
    };
  }

  if (action.type === "SET_FILTER_DATA_INITIAL") {
    return {
      ...state,
      activeColumnData: action.activeColumnData,
      publishColumnData: action.publishColumnData,
      categoryColumnData: action.categoryColumnData,
      activeColumnTableEntries: action.activeColumnTableData,
      publishColumnTableEntries: action.publishColumnTableData,
      categoryColumnTableEntries: action.categoryColumnTableData,
      dateFilterTableData: action.dateFilterTableData,
      countryFilterTableData: action.countryFilterTableData,
      languageFilterTableData: action.languageFilterTableData,
      resetActiveFilter: action.resetActiveFilter,
      resetPublishFilter: action.resetPublishFilter,
      resetCategoryFilter: action.resetCategoryFilter,
      resetDateFilter: action.resetDateFilter,
      resetCountryFilter: action.resetCountryFilter,
      resetLanguageFilter: action.resetLanguageFilter,
      resetAllFilter: action.resetAllFilter,
      resetAllData: action.resetAllData,
    };
  }
  return state;
};
export default resetFilterReducer;
